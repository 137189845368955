import React from 'react';
import Button from '@material-ui/core/Button';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogTitle from '@material-ui/core/DialogTitle';

import PropTypes from 'prop-types';
import { useMutation } from '@apollo/react-hooks';
import TextField from '@material-ui/core/TextField';
import FormControl from '@material-ui/core/FormControl';
import InputLabel from '@material-ui/core/InputLabel';
import Select from '@material-ui/core/Select';
import MenuItem from '@material-ui/core/MenuItem';
import FormHelperText from '@material-ui/core/FormHelperText';

import {
  GROUP_ADMINISTRATOR, GROUP_DEVELOPER, GROUP_SEO,
} from '../../constants';
import {
  Formik, ErrorMessage, Form, Field,
} from 'formik';
import * as Yup from 'yup';
import { ADD_USER_TO_GROUP_MUTATION } from '../../schema/mutations/User';
import { showSnackbar } from '../../components/Snackbar';

const FormSchema = Yup.object().shape({
    group: Yup.string()
      .required('The group is required.'),
  });


export default function AddUserGroup(props) {
  const { open, handleClose, username } = props;
  const [userGroupMutation] = useMutation(ADD_USER_TO_GROUP_MUTATION);
  const resetState = () => {
    handleClose();
  };
  const handleSubmit = (values) => {
    userGroupMutation({
      variables: {
        username: values.username,
        group: values.group
      },
      refetchQueries: ['userGroups'],
    })
    .then(({ data }) => {
      showSnackbar(
        `Group "${data.addUserToGroup.group}" has been added.`,
        'success',
        );
    })
    .catch((error) => {
      showSnackbar(
        error.message,
        'error',
      );
    })
    .finally(handleClose);
  }

  return (
    <div>
      <Dialog open={open} onClose={handleClose} aria-labelledby="form-dialog-title" disableBackdropClick>
        <DialogTitle id="form-dialog-title">Assign Group</DialogTitle>
        <Formik
          initialValues={{
            username,
            group: '',
          }}
          validationSchema={FormSchema}
          onSubmit={handleSubmit}
          render={(formProps) => {
            const {
              values,
              touched,
              errors,
              handleChange,
              handleBlur,
              submitForm,
            } = formProps;
            return (
              <div>
                <DialogContent>
                  <Form>
                    <Field
                      id="username"
                      name="username"
                      type="hidden"
                    />
                    <FormControl fullWidth error={touched.group && !!errors.group}>
                      <InputLabel htmlFor="group">Group</InputLabel>
                      <Select
                        value={values.group}
                        onChange={handleChange}
                        onBlur={handleBlur}
                        style={{
                          minWidth: 300,
                        }}
                        inputProps={{
                          name: 'group',
                          id: 'group',
                        }}
                      >
                        <MenuItem value="" disabled>Select Group</MenuItem>
                        <MenuItem value={GROUP_ADMINISTRATOR}>{GROUP_ADMINISTRATOR}</MenuItem>
                        <MenuItem value={GROUP_DEVELOPER}>{GROUP_DEVELOPER}</MenuItem>
                        <MenuItem value={GROUP_SEO}>{GROUP_SEO}</MenuItem>
                      </Select>
                      {(errors.group && touched.group) && <FormHelperText error>{errors.group}</FormHelperText>}
                    </FormControl>
                  </Form>
                </DialogContent>

                <DialogActions>
                  <Button onClick={resetState} color="primary">
                    Cancel
                  </Button>
                  <Button onClick={submitForm} color="primary">
                    Save
                  </Button>
                </DialogActions>
              </div>
            );
          }}
        />
      </Dialog>
    </div>
  );
}

AddUserGroup.propTypes = {
  open: PropTypes.bool.isRequired,
  handleClose: PropTypes.func.isRequired,
  username: PropTypes.string.isRequired,
};

AddUserGroup.defaultProps = {};
