import React from 'react';
import Button from '@material-ui/core/Button';
import TextField from '@material-ui/core/TextField';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogTitle from '@material-ui/core/DialogTitle';
import { Checkbox, FormControlLabel } from '@material-ui/core';
import InputAdornment from '@material-ui/core/InputAdornment'


import PropTypes from 'prop-types';
import { useMutation, useLazyQuery } from '@apollo/react-hooks';
import {
  Formik, Form,
} from 'formik';
import * as Yup from 'yup';

import { CREATE_PROJECT, UPDATE_PROJECT } from '../../schema/mutations/Project';
import { PROJECT_QUERY } from '../../schema/queries/projects';
import { showSnackbar } from '../Snackbar';

const numberTransform = value => (isNaN(value) ? undefined : value);

const FormSchema = Yup.object().shape({
  assetId: Yup.string()
    .required('Asset ID is required.')
    .matches(
      /^[a-zA-Z-]+-[0-9]{4}$/,
      'Only letters and dashes followed by 4 digits allowed. Ex. XXXX-0000',
    ),
  name: Yup.string()
    .required('Project name is required.'),
  domain: Yup.string()
    .required('Domain is required.'),
  countryCode: Yup.string()
    .min(2)
    .max(2)
    .required('Country Code is required.'),
  userAgent: Yup.string().nullable(),
  useProxy: Yup.bool(),
  instanceCount: Yup.number().transform(numberTransform).max(32),
  processingDelay: Yup.number().transform(numberTransform).max(60),
  revalidateAfterDays: Yup.number().transform(numberTransform).min(0).max(365),
});

export default function ProjectForm(props) {
  const { open, handleClose, action, assetId } = props;
  const [mutation] = useMutation((action === 'create') ? CREATE_PROJECT : UPDATE_PROJECT);
  const actionLabel = (action === 'create') ? 'Add' : 'Edit';
  const actionSuccess = (action === 'create') ? 'created' : 'updated';
  const collectionName = (action === 'create') ? 'createProject' : 'updateProject';
  let initialValues = {
    assetId: '',
    name: '',
    domain: '',
    countryCode: '',
    userAgent: '',
    useProxy: false,
    instanceCount: undefined,
    processingDelay: undefined,
    revalidateAfterDays: undefined,
  };

  const [getProject, { loading, data, called }] = useLazyQuery(PROJECT_QUERY);
  const getDataAssetId = () => {
    if (data && data.project && data.project.assetId) {
      return data.project.assetId;
    }

    return null;
  };

  const newAssetId = getDataAssetId();
  let calledProxy = called;

  if (calledProxy && newAssetId && assetId !== newAssetId) {
    calledProxy = false;
  }

  if ((action === 'update') && !calledProxy) {
    getProject({
      variables: {
        assetId,
      },
    });
  }

  if ((action === 'update') && data && data.project) {
    initialValues = data.project;
  }

  if (loading) {
    return '';
  }

  return (
    <Dialog open={open} onClose={handleClose} aria-labelledby="form-dialog-title" disableBackdropClick>
      <DialogTitle id="form-dialog-title">{`${actionLabel} Project`}</DialogTitle>
      <Formik
        initialValues={initialValues}
        validationSchema={FormSchema}
        onSubmit={(values) => {
          mutation({
            variables: { ...values },
            refetchQueries: ['project', 'projects'],
          })
            .then(({ data }) => {
              showSnackbar(
                `Project "${data[collectionName].project.name}" has been ${actionSuccess}.`,
                'success',
              );
            })
            .catch((error) => {
              showSnackbar(
                error.message,
                'error',
              );
            })
            .finally(handleClose);
        }}
        render={(formProps) => {
          const {
            values,
            touched,
            errors,
            handleChange,
            handleBlur,
            submitForm,
          } = formProps;
          return (
            <div>
              <DialogContent>
                <Form>
                  <TextField
                    id="assetId"
                    name="assetId"
                    margin="dense"
                    label="Asset ID"
                    type="text"
                    variant="filled"
                    value={values.assetId}
                    onChange={handleChange}
                    onBlur={handleBlur}
                    fullWidth
                    helperText={(errors.assetId && touched.assetId) && errors.assetId}
                    error={touched.assetId && !!errors.assetId}
                    disabled={action === 'update'}
                  />
                  <TextField
                    id="name"
                    name="name"
                    margin="dense"
                    label="Name"
                    type="text"
                    variant="filled"
                    value={values.name}
                    onChange={handleChange}
                    onBlur={handleBlur}
                    fullWidth
                    helperText={(errors.name && touched.name) && errors.name}
                    error={touched.name && !!errors.name}
                  />
                  <TextField
                    id="domain"
                    name="domain"
                    margin="dense"
                    label="Domain"
                    type="text"
                    variant="filled"
                    value={values.domain}
                    onChange={handleChange}
                    onBlur={handleBlur}
                    fullWidth
                    helperText={(errors.domain && touched.domain) && errors.domain}
                    error={touched.domain && !!errors.domain}
                  />
                  <TextField
                    id="countryCode"
                    name="countryCode"
                    margin="dense"
                    label="Country Code"
                    type="text"
                    variant="filled"
                    value={values.countryCode}
                    onChange={handleChange}
                    onBlur={handleBlur}
                    helperText={(errors.countryCode && touched.countryCode) && errors.countryCode}
                    error={touched.countryCode && !!errors.countryCode}
                  />
                  <TextField
                    id="userAgent"
                    name="userAgent"
                    margin="dense"
                    label="User Agent"
                    type="text"
                    variant="filled"
                    value={values.userAgent}
                    onChange={handleChange}
                    onBlur={handleBlur}
                    fullWidth
                    helperText={(errors.userAgent && touched.userAgent) && errors.userAgent}
                    error={touched.userAgent && !!errors.userAgent}
                  />
                  <div style={{ display: 'flex' }}>
                    <TextField
                      style={{ marginRight: '10px', flex: 1 }}
                      id="instanceCount"
                      name="instanceCount"
                      margin="dense"
                      label="Instance Count"
                      type="number"
                      variant="filled"
                      value={values.instanceCount}
                      onChange={handleChange}
                      onBlur={handleBlur}
                      helperText={(errors.instanceCount && touched.instanceCount) && errors.instanceCount}
                      error={touched.instanceCount && !!errors.instanceCount}
                    />
                    <TextField
                      style={{ flex: 1 }}
                      id="processingDelay"
                      name="processingDelay"
                      margin="dense"
                      label="Processing Delay"
                      type="number"
                      variant="filled"
                      value={values.processingDelay}
                      onChange={handleChange}
                      onBlur={handleBlur}
                      helperText={(errors.processingDelay && touched.processingDelay) && errors.processingDelay}
                      error={touched.processingDelay && !!errors.processingDelay}
                    />
                  </div>
                  <div style={{ display: 'flex' }}>
                    <TextField
                      style={{ marginRight: '10px', flex: 1 }}
                      id="revalidateAfterDays"
                      name="revalidateAfterDays"
                      margin="dense"
                      label="Revalidate After"
                      type="number"
                      variant="filled"
                      value={values.revalidateAfterDays}
                      onChange={handleChange}
                      onBlur={handleBlur}
                      helperText={(errors.revalidateAfterDays && touched.revalidateAfterDays) && errors.revalidateAfterDays}
                      error={touched.revalidateAfterDays && !!errors.revalidateAfterDays}
                      InputProps={{
                        endAdornment: (
                          <InputAdornment position='end'>Days</InputAdornment>
                        ),
                      }}
                    />
                    <FormControlLabel
                      style={{ flex: 1 }}
                      control={(
                        <Checkbox
                          id="useProxy"
                          name="useProxy"
                          checked={!!values.useProxy}
                          onChange={handleChange}
                        />
                      )}
                      label="Use Proxy"
                    />
                  </div>
                  <TextField
                    id="notes"
                    name="notes"
                    margin="dense"
                    label="Notes"
                    type="text"
                    variant="filled"
                    rows={2}
                    value={values.notes}
                    onChange={handleChange}
                    onBlur={handleBlur}
                    fullWidth
                    multiline
                  />
                </Form>
              </DialogContent>

              <DialogActions>
                <Button onClick={handleClose} color="primary">
                  Cancel
                </Button>
                <Button onClick={submitForm} color="primary">
                  Save
                </Button>
              </DialogActions>
            </div>
          );
        }}
      />
    </Dialog>
  );
}

ProjectForm.propTypes = {
  open: PropTypes.bool.isRequired,
  handleClose: PropTypes.func.isRequired,
  action: PropTypes.oneOf(['create', 'update']),
  assetId: PropTypes.string,
};

ProjectForm.defaultProps = {
  assetId: undefined,
  action: 'create',
};
