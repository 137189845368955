import changeCase from 'change-case';
import { execute, makePromise } from 'apollo-link';
import { HttpLink } from 'apollo-link-http';

import { VERSION_STEPS } from '../constants';
import config from '../config';
import { showSnackbar } from '../components/Snackbar';

function getWorkbookProgress(completedSteps, range) {
  const steps = getSteps(completedSteps, range);
  return {
    steps: steps.slice(range[0], range[1] + 1),
    currentStep: steps.findIndex(step => step.current),
  };
}

function getSteps(completedSteps) {
  const lastCompletedStep = completedSteps[completedSteps.length - 1];
  const currentStep = VERSION_STEPS.findIndex(p => p.step === lastCompletedStep) + 1;

  return VERSION_STEPS.map( step => {
    return {
      step: step.step,
      label: step.label,
      confirm: step.confirm || false,
      wait: step.wait || false,
      completed: completedSteps.includes(step.step),
      current: VERSION_STEPS[currentStep].step === step.step,
    }
  });
}


/**
 * @param {string} operator The filter operator returned by the MaterialTable API.
 * (Appears to only support "=")
 * @returns {string} The operator expected by the GraphQL API.
 */
function getOperator(operator) {
  const lookup = {
    '=': 'eq',
  };

  if (lookup[operator]) {
    return lookup[operator];
  }

  return operator;
}

/**
 * @param {object} input A single MaterialTable filter.
 * @returns {object} A single GraphQl filter.
 */
function getFilter(input) {
  // Map operators to sqlalchemy-filters syntax
  let op = getOperator(input.operator);
  let { value } = input;
  const { column: { field: fieldName } } = input;

  // Revert Enum values that are automatically upper-cased by Graphene
  if (['status', 'type', 'datatype'].includes(fieldName)) {
    value = value.map(field => field.toLowerCase());
  }

  if (input.column.filterType === 'sql') {
    return value;
  }

  if (input.column.operator) {
    op = input.column.operator;
  } else if (Array.isArray(value)) {
    // Graphene expects string type for filter value
    op = 'in';
    value = value.join(',');
  }

  // Graphene gives camel cased field names but filter input
  // requires the same casing as the database. In our case it
  // is snake case by convention.
  return {
    field: changeCase.snakeCase(fieldName),
    op,
    value,
  };
}

function materialTableDataQuery(request, query, variables, resolver, searchFields, models = {}) {
  const link = new HttpLink({
    uri: config.graphqlHostname,
    credentials: 'include',
  });
  return new Promise((resolve, reject) => {
    const sort = {};
    const newFilters = [];
    const operation = {
      query,
      variables: Object.assign({
        pageNumber: request.page + 1,
        pageSize: request.pageSize,
        sort: [],
        filters: [],
      }, variables),
    };

    // Sort
    if (request.orderBy) {
      sort.field = changeCase.snakeCase(request.orderBy.field);
      sort.direction = request.orderDirection;
      if (request.orderBy.field in models) {
        sort.model = models[request.orderBy.field];
      }
      operation.variables.sort.push(sort);
    }

    // Filter
    if (request.filters) {
      // Split 'sql' filters
      const filters = request.filters.map((filter) => {
        if (filter.column.filterType && filter.column.filterType === 'sql') {
          const sqlFilters = filter.value.map(value => ({ ...filter, value }));
          return sqlFilters.length > 1 ? { or: sqlFilters } : sqlFilters;
        }
        return filter;
      }).flat();

      filters.forEach((filter) => {
        if (filter.or) {
          newFilters.push(
            // Use "or_" instead of "or" due to python keyword restrictions.
            // See parse_filter definition in ils/api/ils_api/lib/db.py for details.
            { or_: filter.or.map(subfilter => getFilter(subfilter)) },
          );
        } else if (filter.value.length || typeof filter.value === 'object') {
          newFilters.push(getFilter(filter));
        }
      });

      // Remove empty values
      operation.variables.filters = operation.variables.filters.concat(newFilters.filter((filter) => {
        if (filter.value instanceof Array && filter.value.length === 0) {
          return false;
        }
        if (typeof filter.value === 'string' && filter.value === '') {
          return false;
        }
        return true;
      }));
    }

    // Search
    if (request.search) {
      searchFields.forEach((field) => {
        const filter = { field, op: 'ilike', value: `%${request.search}%` };
        if (field in models) {
          filter.model = models[field];
        }
        operation.variables.filters.push(filter);
      });
    }

    const resolveResult = (result) => {
      if (result.errors && result.errors.length) {
        result.errors.forEach((error) => {
          showSnackbar(error.message.substring(0, 100), 'error');
          // eslint-disable-next-line no-console
          console.error(error.message);
        });
        resolve({ data: [], page: 0, totalCount: 0 });
      } else {
        resolve(resolver(result));
      }
    };

    makePromise(execute(link, operation))
      .then(resolveResult)
      .catch(error => showSnackbar(error));
  });
}


function urlDataQuery(request, gql, variables, collection = 'version') {
  return materialTableDataQuery(
    request,
    gql,
    variables,
    result => ({
      data: result.data[collection].urls.data,
      page: result.data[collection].urls.pagination.pageNumber - 1,
      totalCount: result.data[collection].urls.pagination.totalResults,
    }),
    ['url'],
    { url: 'Url' },
  );
}

function userUrlKeywordDataQuery(request, gql, variables, collection = 'version') {
  return materialTableDataQuery(
    request,
    gql,
    variables,
    result => ({
      data: result.data[collection].userUrlKeyword.data,
      page: result.data[collection].userUrlKeyword.pagination.pageNumber - 1,
      totalCount: result.data[collection].userUrlKeyword.pagination.totalResults,
    }),
    ['url'],
    { userUrlKeyword: 'Url' },
  );
}


export { materialTableDataQuery, urlDataQuery, getWorkbookProgress, getSteps, userUrlKeywordDataQuery };
