import React from 'react';
import PropTypes from 'prop-types';
import { Grid } from '@material-ui/core';
import { useQuery } from '@apollo/react-hooks';
import Typography from '@material-ui/core/Typography';
import Card from '@material-ui/core/Card';
import CardContent from '@material-ui/core/CardContent';
import LinearProgress from '@material-ui/core/LinearProgress';
import Box from '@material-ui/core/Box';

import { VERSION_URL_COUNT_QUERY } from '../../schema/queries/versions';
import { formatNumber } from '../../lib/formatters';


export default function TotalUrlsSummary(props) {
  const { versionUuid } = props;

  const { loading, error, data, networkStatus } = useQuery(VERSION_URL_COUNT_QUERY, {
    variables: {
      uuid: versionUuid,
    },
    fetchPolicy: 'network-only',
    notifyOnNetworkStatusChange: true,
  });


  if (loading) {
    // return <span>Loading...</span>;
  }

  if (error) {
    console.log(error);
    return <span>Error...</span>;
  }

  return (
    <Grid container spacing={2}>
      <Grid item xs={6}>
        <Card>
          <CardContent>
            <Typography variant="subtitle1" color="textSecondary">
              Total URLs Added
            </Typography>
            {loading || networkStatus === 4
              ? (
                <Box>
                  <Typography variant="body1">Calculating...</Typography>
                  <LinearProgress />
                </Box>
              )
              : <Typography variant="h4">{formatNumber(data.version.urlCount.total)}</Typography>
            }
          </CardContent>
        </Card>
      </Grid>
      <Grid item xs={6}>
        <Card>
          <CardContent>
            <Typography variant="subtitle1" color="textSecondary">
              Total Unique URLs
            </Typography>
            {loading || networkStatus === 4
              ? (
                <Box>
                  <Typography variant="body1">Calculating...</Typography>
                  <LinearProgress />
                </Box>
              )
              : <Typography variant="h4">{formatNumber(data.version.urlCount.unique)}</Typography>
            }
          </CardContent>
        </Card>
      </Grid>
    </Grid>
  );
}

TotalUrlsSummary.propTypes = {
  versionUuid: PropTypes.string.isRequired,
};
