import React from 'react';
import PropTypes from 'prop-types';

import VersionTable from '../components/tables/VersionTable';


const Versions = (props) => {
  const { assetId } = props;

  if (!assetId) {
    return "Select a project.";
  }

  return (
    <div className="versions">
      <VersionTable assetId={assetId} />
    </div>
  );
};

export default Versions;

Versions.propTypes = {
  assetId: PropTypes.string,
};

Versions.defaultProps = {
  assetId: '',
};
