import React, { useState, useEffect } from 'react';
import queryString from 'query-string';
import { Redirect } from 'react-router-dom';

import config from '../config';
import { setSession } from '../session';

export default (props) => {
  const [tokenError, setTokenError] = useState(null);
  const [loading, setLoading] = useState(true);
  const [data, setData] = useState(null);

  const { location } = props;
  const { code, error } = queryString.parse(location.search);

  // Note: the empty deps array [] means
  // this useEffect will run once
  // similar to componentDidMount()
  useEffect(() => {
    if (!code) {
      return;
    }
    fetch(`${config.host}/oauth2/token?code=${code}`, {credentials: 'include'})
      .then(res => res.json())
      .then(
        (result) => {
          setData(result);
          setLoading(false);
        },
        // Note: it's important to handle errors here        
        // instead of a catch() block so that we don't swallow
        // exceptions from actual bugs in components.
        (error) => {
          setTokenError(error);
          setLoading(false);
        }
      )
  }, []);

  if (loading) {
    return <div>Loading...</div>
  }

  if (error) {
    return (
      <div>Authentication Error: {JSON.stringify(error)}</div>
    );
  }

  if (tokenError) {
    return <div>Token Error: {JSON.stringify(tokenError)}</div>
  }

  if (data) {
    const { user } = data;
    
    setSession({
      user,
    })
    
    return (
      <Redirect
        to={{
          pathname: "/ils",
          state: { from: location }
        }}
      />
    );
  }

}
