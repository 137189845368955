import React from 'react';
import PropTypes from 'prop-types';
import { useTheme } from '@material-ui/core/styles';
import { useQuery } from '@apollo/react-hooks';
import MaterialTable from 'material-table';

import { VERSION_KEYWORD_ASSOCIATION_QA_QUERY } from '../../schema/queries/versions';
import FormattedCell from '../material-table/FormattedCell';
import MTableActionRendered from '../material-table/m-table-action-rendered';
import KeywordAssociationTopUrls from '../tables/KeywordAssociationTopUrls';
import Note from '../tables/datatype/Note';


export default function KeywordAssociationQa(props) {
  const { versionUuid, assetId } = props;

  const { loading, error, data } = useQuery(VERSION_KEYWORD_ASSOCIATION_QA_QUERY, {
    variables: {
      uuid: versionUuid,
    },
    fetchPolicy: 'network-only',
  });

  if (loading) {
    return <span>Loading...</span>;
  }

  if (error) {
    console.log(error);
    return <span>Error...</span>;
  }
  
  const theme = useTheme();
  const styles = {
    success: {
      backgroundColor: '#AFFFB0',
      color: theme.palette.getContrastText('#AFFFB0'),
    },
    error: {
      backgroundColor: theme.palette.error.light,
      color: theme.palette.getContrastText(theme.palette.error.light),
    },
  };
  
  const stylers = {
    percentError: (value) => {
      const intValue = parseInt(value, 10);
      if (intValue > 0) {
        return styles.error;
      }
      return styles.success;
    },
  };


  return (
    <MaterialTable
      title="Association QA"
      columns={[
        {
          title: 'URL Type',
          field: 'groupName',
        },
        {
          title: 'Count Valid URLs',
          field: 'urlCount',
        },
        {
          title: 'Count of URLs w Assocs',
          field: 'urlCountAssocs',
        },
        {
          title: 'Count URLs w/o Assocs',
          field: 'urlCountNoAssocs',
        },
        {
          title: 'Pct URLs w/o Assocs',
          field: 'percentUrlCountNoAssocs',
          type: 'numeric',
          cellStyle: stylers.percentError
        },
        {
          title: 'URLs w GA Visits w/o Assocs',
          field: 'urlCountGaNoAssocs',
        },
      ]}
      data={data.version.keywordAssociationQa}
      components={{
        Cell: properties => (
          <FormattedCell {...properties} />
        ),
        Action: actionProps => (
          <MTableActionRendered {...actionProps} />
        ),
      }}
      options={{
        padding: 'dense',
        emptyRowsWhenPaging: false,
        actionsColumnIndex: -1,
        pageSize: 10,
        pageSizeOptions: [10, 50, 500],
        exportButton: true,
        headerStyle: {
          lineHeight: 1.5,
        },
      }}
      detailPanel={[
        {
          tooltip: 'Toggle Data View',
          icon: 'list',
          render: rowData =>
            <KeywordAssociationTopUrls
              versionUuid={versionUuid}
              groupId={rowData.groupId}
            />
        },
        {
          tooltip: 'Toggle Notes View',
          icon: 'note',
          render: rowData =>
            <Note
              contextUuid={versionUuid}
              assetId={assetId}
              context={'url_group'}
            />
        },
      ]}
    />
  );
}

KeywordAssociationQa.propTypes = {
  versionUuid: PropTypes.string.isRequired,
  assetId: PropTypes.string.isRequired,
};
