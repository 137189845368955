import React from 'react';
import PropTypes from 'prop-types';
import { useQuery } from '@apollo/react-hooks';
import MaterialTable from 'material-table';
import { makeStyles } from '@material-ui/core/styles';
import ErrorIcon from '@material-ui/icons/Error';
import Tooltip from '@material-ui/core/Tooltip';

import { PROJECT_VERSIONS_QUERY } from '../../schema/queries/projects';
import VersionCreate from '../forms/VersionCreate';
import SitemapWorkbook from '../../containers/SitemapWorkbook';
import KeywordWorkbook from '../../containers/KeywordWorkbook';
import FullScreenDialog from '../FullScreenDialog';
import FormattedCell from '../material-table/FormattedCell';
import { SITEMAP_WORKBOOK_TITLE, KEYWORD_WORKBOOK_TITLE, VERSION_STEPS } from '../../constants';
import NotesDataTable from './datatype/Note';

const useStyles = makeStyles(theme => ({
  error: {
    color: theme.palette.error.dark,
    marginLeft: 5,
    verticalAlign: 'middle',
  },
}));

const VersionTable = (props) => {
  const { assetId } = props;
  const classes = useStyles();
  const {
    data, loading, refetch, error,
  } = useQuery(PROJECT_VERSIONS_QUERY, {
    variables: {
      assetId,
    },
  });

  const [open, setOpen] = React.useState(false);
  const [sitemapWorkbook, setSitemapWorkbook] = React.useState({
    open: false,
    versionUuid: '',
    title: SITEMAP_WORKBOOK_TITLE,
  });

  const [keywordWorkbook, setKeywordWorkbook] = React.useState({
    open: false,
    versionUuid: '',
    title: KEYWORD_WORKBOOK_TITLE,
  });

  function handleClickOpen() {
    setOpen(true);
  }

  function handleClose() {
    setOpen(false);
    refetch();
  }

  function handleClickSitemapWorkbook(event, rowData) {
    setSitemapWorkbook({
      ...sitemapWorkbook,
      open: true,
      versionUuid: rowData.uuid,
      title: `${SITEMAP_WORKBOOK_TITLE} (${rowData.name} - ${assetId})`,
    });
  }

  function handleCloseSitemapWorkbook() {
    setSitemapWorkbook({
      ...sitemapWorkbook,
      open: false,
    });
  }

  function handleClickKeywordWorkbook(event, rowData) {
    setKeywordWorkbook({
      ...keywordWorkbook,
      open: true,
      versionUuid: rowData.uuid,
      title: `${KEYWORD_WORKBOOK_TITLE} (${rowData.name} - ${assetId})`,
    });
  }

  function handleCloseKeywordWorkbook() {
    setKeywordWorkbook({
      ...keywordWorkbook,
      open: false,
    });
  }

  if (loading) {
    return <span>Loading...</span>;
  }

  if (error) {
    return <span>Unable to load data.</span>;
  }

  const getStatus = (rowData) => {
    const currentStep = [...rowData.completedStages].pop();
    const currentStepIndex = VERSION_STEPS.findIndex(item => item.step === currentStep);
    const { label } = VERSION_STEPS[currentStepIndex + 1];

    if (rowData.errors.length) {
      return (
        <React.Fragment>
          <span style={{ verticalAlign: 'middle' }}>{label}</span>
          <Tooltip title={<span style={{ whiteSpace: 'pre-wrap' }}>{rowData.errors.join('\n')}</span>} interactive>
            <ErrorIcon className={classes.error} />
          </Tooltip>
        </React.Fragment>
      );
    }
    return label;
  };


  return (
    <div className="project-versions">
      <MaterialTable
        title="Versions"
        columns={[
          { title: 'Name', field: 'name' },
          {
            title: 'Status',
            field: 'completedStages',
            render: getStatus,
          },
          {
            title: 'Created',
            field: 'createdAt',
            defaultSort: 'desc',
            type: 'datetime',
          },
          { title: 'Updated', field: 'lastUpdated', type: 'datetime' },
        ]}
        data={data.project.versions.edges.map(edge => edge.node)}
        options={{
          sorting: true,
          search: false,
          emptyRowsWhenPaging: false,
          actionsColumnIndex: -1,
        }}
        actions={[
          {
            icon: 'add',
            tooltip: 'Create a new Version',
            position: 'toolbar',
            onClick: handleClickOpen,
          },
          {
            tooltip: 'Sitemap Workbook',
            icon: 'filter_1',
            onClick: handleClickSitemapWorkbook,
          },
          rowData => ({
            tooltip: 'Keyword Workbook',
            icon: 'filter_2',
            onClick: handleClickKeywordWorkbook,
            disabled: !rowData.completedStages.includes('ApproveSitemapWorkbook'),
          }),
          rowData => ({
            tooltip: 'Priority Workbook',
            icon: 'filter_3',
            onClick: handleClickOpen,
            disabled: !rowData.completedStages.includes('ApproveKeywordWorkbook'),
          }),
        ]}
        detailPanel={[
          {
            tooltip: 'Version Details',
            icon: 'info',
            render: rowData => (
              <div
                style={{
                  margin: '20px',
                }}
              >
                <pre>{JSON.stringify(rowData, null, 2)}</pre>
              </div>
            ),
          },
		  {
          render: rowData => <NotesDataTable assetId={assetId} context='version' contextUuid={rowData.uuid} />,
          icon: 'note',
          tooltip: 'Notes',
        },
        ]}
        localization={{
          header: {
            actions: 'Workbooks',
          },
        }}
        components={{
          Cell: properties => <FormattedCell {...properties} />,
        }}
      />

      <VersionCreate
        open={open}
        handleClose={handleClose}
        values={{
          assetId,
          name: '',
          note: '',
        }}
      />

      <FullScreenDialog
        open={sitemapWorkbook.open}
        handleClose={handleCloseSitemapWorkbook}
        title={sitemapWorkbook.title}
      >
        <SitemapWorkbook versionUuid={sitemapWorkbook.versionUuid} assetId={assetId} m={1} />
      </FullScreenDialog>

      <FullScreenDialog
        open={keywordWorkbook.open}
        handleClose={handleCloseKeywordWorkbook}
        title={keywordWorkbook.title}
      >
        <KeywordWorkbook versionUuid={keywordWorkbook.versionUuid} assetId={assetId} m={1} />
      </FullScreenDialog>
    </div>
  );
};

export default VersionTable;

VersionTable.propTypes = {
  assetId: PropTypes.string.isRequired,
};
