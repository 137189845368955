import React, { useState, useEffect } from 'react';
import CheckCircleIcon from '@material-ui/icons/CheckCircle';
import ErrorIcon from '@material-ui/icons/Error';
import InfoIcon from '@material-ui/icons/Info';
import CloseIcon from '@material-ui/icons/Close';
import { amber, green } from '@material-ui/core/colors';
import IconButton from '@material-ui/core/IconButton';
import Snackbar from '@material-ui/core/Snackbar';
import SnackbarContent from '@material-ui/core/SnackbarContent';
import WarningIcon from '@material-ui/icons/Warning';
import { makeStyles } from '@material-ui/core/styles';

const variantIcon = {
  success: CheckCircleIcon,
  warning: WarningIcon,
  error: ErrorIcon,
  info: InfoIcon,
};

const useStyles = makeStyles(theme => ({
  success: {
    backgroundColor: green[600],
  },
  error: {
    backgroundColor: theme.palette.error.dark,
  },
  info: {
    backgroundColor: theme.palette.primary.main,
  },
  warning: {
    backgroundColor: amber[700],
  },
  icon: {
    fontSize: 20,
  },
  iconVariant: {
    opacity: 0.9,
    marginRight: theme.spacing(1),
  },
  message: {
    display: 'flex',
    alignItems: 'center',
  },
}));
const defaultState = { open: false, message: '', variant: 'info' };

// Hoist show to expose the openSnackbar function for export
let show;


export default function Snack(props) {
  const classes = useStyles();
  const { ...other } = props;
  const [snackbar, setSnackbar] = useState(defaultState);
  const Icon = variantIcon[snackbar.variant];

  const openSnackbar = (message, variant) => setSnackbar({ open: true, message, variant });
  const handleClose = () => setSnackbar({ ...snackbar, open: false });

  useEffect(() => {
    show = openSnackbar;
  }, [openSnackbar]);

  return (
    <Snackbar
      open={snackbar.open}
      onClose={handleClose}
      anchorOrigin={{
        vertical: 'bottom',
        horizontal: 'left',
      }}
      autoHideDuration={4000}
      {...other}
    >
      <SnackbarContent
        className={classes[snackbar.variant]}
        aria-describedby="client-snackbar"
        message={(
          <span id="client-snackbar" className={classes.message}>
            <Icon className={classes.iconVariant} />
            {snackbar.message}
          </span>
        )}
        action={[
          <IconButton key="close" aria-label="close" color="inherit" onClick={handleClose}>
            <CloseIcon className={classes.icon} />
          </IconButton>,
        ]}
      />
    </Snackbar>
  );
}

export function showSnackbar(message, variant = defaultState.variant) {
  show(message, variant);
}
