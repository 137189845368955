import React from 'react';
import PropTypes from 'prop-types';
import { useQuery } from '@apollo/react-hooks';

import { VERSION_FILES_QUERY } from '../../schema/queries/versions';
import DataManagementTable from '../../components/tables/DataManagementTable';


export default function AddFiles(props) {
  const { assetId, versionUuid, handleSelectionChange, readOnly } = props;

  const { loading, error, data } = useQuery(VERSION_FILES_QUERY, {
    variables: {
      uuid: versionUuid,
    },
  });

  const getTitle = ({ selected }) => (
    selected.length
      ? `${selected.length} URL file(s) selected for Validation`
      : 'Select URL Files for Validation'
  );

  if (loading) {
    return <span>Loading...</span>;
  }

  if (error) {
    console.log(error);
  }

  return (
    <DataManagementTable
      title={getTitle}
      assetId={assetId}
      options={{
        filtering: true,
        pageSize: 5,
        pageSizeOptions: [5, 50, 500],
        search: false,
        selection: !readOnly,
        emptyRowsWhenPaging: false,
      }}
      onSelectionChange={handleSelectionChange}
      initialSelected={data.version.urlFiles.edges.map(({ node: file }) => file.id)}
      // Bug somewhere that predefined filters are not merged with new ones (helpers.js and db.py)
      // The helpers will then create invalid queries because of the invalid multiple statements
      // filters={[{ field: 'status', op: 'in', value: 'processed' }, { field: 'datatype', op: 'in', value: 'url' }]}
    />
  );
}

AddFiles.propTypes = {
  assetId: PropTypes.string.isRequired,
  versionUuid: PropTypes.string.isRequired,
  handleSelectionChange: PropTypes.func.isRequired,
  readOnly: PropTypes.bool,
};

AddFiles.defaultProps = {
  readOnly: false,
};
