/* eslint-disable react/no-danger */
import React, { forwardRef } from 'react';
import PropTypes from 'prop-types';
import MaterialTable, { MTableFilterRow, MTableEditField } from 'material-table';
import { useQuery, useMutation } from '@apollo/react-hooks';
import Save from '@material-ui/icons/Save';
import Add from '@material-ui/icons/Add';
import TextField from '@material-ui/core/TextField';
import Autocomplete from '@material-ui/lab/Autocomplete';
import { makeStyles } from '@material-ui/core/styles';
import parse from 'autosuggest-highlight/parse';
import match from 'autosuggest-highlight/match';


import { URL_GROUP_KEYWORD_TEMPLATES_QUERY } from '../../schema/queries/keyword_templates';
import { ASSIGN_KEYWORD_TEMPLATE_MUTATION, UN_ASSIGN_KEYWORD_TEMPLATE_MUTATION } from '../../schema/mutations/keyword_templates';
import { showSnackbar } from '../../components/Snackbar';

const useStyles = makeStyles(() => ({
  root: {
    width: '100%',
    fontSize: '16px !important',
  },
}));

export default function AssignKeywordTemplates(props) {
  const classes = useStyles();
  const { versionUuid, assetId } = props;
  const { loading, error, data } = useQuery(URL_GROUP_KEYWORD_TEMPLATES_QUERY, {
    variables: {
      versionUuid,
      assetId,
    },
  });


  const [assignTemplate] = useMutation(ASSIGN_KEYWORD_TEMPLATE_MUTATION);
  const [unAssignTemplate] = useMutation(UN_ASSIGN_KEYWORD_TEMPLATE_MUTATION);

  if (loading) {
    return <span>Loading...</span>;
  }

  if (error) {
    console.log(error);
  }

  // Use lookup for group field

  const groupLookup = {};
  data.version.urlGroups.sort((a, b) => (a.name > b.name ? 1 : -1));
  data.version.urlGroups.forEach((group) => { groupLookup[group.id] = group.name; });

  const groupTemplates = data.version.urlGroups.map(
    group => group.keywordTemplates.map(
      template => ({
        groupId: group.id,
        template: template.template,
        templateId: template.id,
        generationPriority: template.generationPriority,
        factor: template.factor
      }),
    ),
  ).flat();

  const columns = [
    {
      title: 'Url Group',
      field: 'groupId',
      lookup: groupLookup,
      editComponent: editComponentProps => (
        <MTableEditField
          {...editComponentProps}
          className={classes.root}
        />
      ),
      defaultSort: 'asc',
    },
    {
      title: 'Keyword Template',
      field: 'template',
      editComponent: editComponentProps => (
        <Autocomplete
          id="template"
          freeSolo
          disableClearable
          options={data.project.keywordTemplates.map(template => template.template).sort()}
          renderInput={params => (
            <TextField {...params} style={{ width: 400 }} placeholder="Template" />
          )}
          onChange={(event, newVal) => {
            editComponentProps.onChange(newVal);
          }}
          onInputChange={(event, newVal) => {
            editComponentProps.onChange(newVal);
          }}
          renderOption={(option, { inputValue }) => {
            const matches = match(option, inputValue);
            const parts = parse(option, matches);

            return (
              <div>
                {parts.map((part, index) => (
                  <span key={index} style={{ fontWeight: part.highlight ? 700 : 400 }}>
                    {part.text}
                  </span>
                ))}
              </div>
            );
          }}
        />
      ),
    },
    {
      title: 'Generation Priority',
      field: 'generationPriority',
      filtering: false,
      type: 'numeric',
      editComponent: editComponentProps => (
        <MTableEditField
          {...editComponentProps}
          inputProps={{
            className: classes.root,
          }}
        />
      ),
    },
    {
      title: 'Factor',
      field: 'factor',
      filtering: false,
      type: 'numeric',
      editComponent: editComponentProps => (
        <MTableEditField
          {...editComponentProps}
          inputProps={{
            className: classes.root,
          }}
        />
      ),
    },
  ];

  const handleCreate = newData => new Promise((resolve, reject) => {
    assignTemplate({
      variables: {
        assetId,
        template: newData.template,
        urlGroupId: newData.groupId,
        generationPriority: newData.generationPriority,
        factor: newData.factor
      },
      refetchQueries: ['getUrlGroupKeywordTemplates'],
      awaitRefetchQueries: true,
    }).then(() => resolve())
      .catch(() => {
        showSnackbar('Group, Template and Priority are all required fields.', 'error');
        return reject();
      });
  });

  const handleDelete = oldData => new Promise((resolve, reject) => {
    unAssignTemplate({
      variables: {
        templateId: oldData.templateId,
        urlGroupId: oldData.groupId,
      },
      refetchQueries: ['getUrlGroupKeywordTemplates'],
      awaitRefetchQueries: true,
    }).then(() => resolve())
      .catch(() => {
        showSnackbar('Unable to delete the Group Template.', 'error');
        return reject();
      });
  });

  return (
    <React.Fragment>
      <style dangerouslySetInnerHTML={{
        __html: `
        tbody tr:first-of-type{ background-color: #EFEFEF }
        tbody tr:first-of-type .MuiTextField-root{ width: 100% }`,
      }}
      />
      <MaterialTable
        title="Assign Keyword Templates"
        columns={columns}
        data={groupTemplates}
        options={{
          search: false,
          paging: false,
          filtering: true,
          actionsColumnIndex: -1,
          addRowPosition: 'first',
        }}
        icons={{
          Check: forwardRef((iconProps, ref) => <Save {...iconProps} ref={ref} />),
          Add: forwardRef((iconProps, ref) => <Add {...iconProps} ref={ref} />),
        }}
        editable={{
          isEditable: () => false,
          onRowAdd: handleCreate,
          onRowDelete: handleDelete,
        }}
        components={{
          FilterRow: filterProps => (
            <MTableFilterRow style={{ width: '100%' }} {...filterProps} />
          ),
        }}
      />
    </React.Fragment>
  );
}


AssignKeywordTemplates.propTypes = {
  assetId: PropTypes.string.isRequired,
  versionUuid: PropTypes.string.isRequired,
};
