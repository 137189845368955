import React, { useState } from 'react';
import PropTypes from 'prop-types';
import Typography from '@material-ui/core/Typography';
import Tab from '@material-ui/core/Tab';
import Tabs from '@material-ui/core/Tabs';
import Paper from '@material-ui/core/Paper';
import Box from '@material-ui/core/Box';

import TotalUrlsSummary from '../../components/SitemapWorkbook/TotalUrlsSummary';
import UrlCountBySource from '../../components/SitemapWorkbook/UrlCountBySource';
import UrlCountByRoot from '../../components/SitemapWorkbook/UrlCountByRoot';
import TabPanel from '../../components/TabPanel';
import AllUrls from '../../components/SitemapWorkbook/AllUrls';


export default function ValidateUrls(props) {
  const { versionUuid, disableActions } = props;
  const [tabValue, setTabValue] = useState(0);

  function handleTabChange(event, newValue) {
    setTabValue(newValue);
  }

  return (
    <div>
      <Typography variant="h5" gutterBottom>
        URL Summary
      </Typography>
      <TotalUrlsSummary versionUuid={versionUuid} />

      <Box my={3}>
        <Paper square>
          <Tabs
            value={tabValue}
            indicatorColor="primary"
            textColor="primary"
            onChange={handleTabChange}
          >
            <Tab label="URLs by Source" />
            <Tab label="URLs by Root" />
            <Tab label="All URLs" />
          </Tabs>
        </Paper>
      </Box>
      <TabPanel value={tabValue} index={0}>
        <UrlCountBySource versionUuid={versionUuid} disableActions={disableActions} />
      </TabPanel>
      <TabPanel value={tabValue} index={1}>
        <UrlCountByRoot versionUuid={versionUuid} disableActions={disableActions} />
      </TabPanel>
      <TabPanel value={tabValue} index={2}>
        <AllUrls versionUuid={versionUuid} />
      </TabPanel>
    </div>
  );
}

ValidateUrls.propTypes = {
  versionUuid: PropTypes.string.isRequired,
  disableActions: PropTypes.bool,
};
