import React, { useState } from 'react';
import PropTypes from 'prop-types';
import FormControl from '@material-ui/core/FormControl';
import Select from '@material-ui/core/Select';
import Input from '@material-ui/core/Input';
import InputLabel from '@material-ui/core/InputLabel';
import MenuItem from '@material-ui/core/MenuItem';
import Checkbox from '@material-ui/core/Checkbox';
import ListItemText from '@material-ui/core/ListItemText';
import { makeStyles } from '@material-ui/core/styles';

const useStyles = makeStyles(theme => ({
  spacer: {
    borderTop: `1px solid ${theme.palette.divider}`,
  },
}));


const ITEM_HEIGHT = 48;
const ITEM_PADDING_TOP = 8;
const MenuProps = {
  PaperProps: {
    style: {
      maxHeight: ITEM_HEIGHT * 4.5 + ITEM_PADDING_TOP,
      width: 250,
    },
  },
};

export default function MTableGroupFilter(props) {
  const { values, columnDef, onFilterChanged, spacers } = props;
  const [selected, setSelected] = useState([]);
  const classes = useStyles();

  return (
    <FormControl style={{ width: '100%' }}>
      <InputLabel htmlFor="select-multiple-checkbox">{columnDef.filterPlaceholder}</InputLabel>
      <Select
        multiple
        value={selected}
        onChange={(event) => {
          setSelected(event.target.value);
          return onFilterChanged(
            columnDef.tableData.id,
            event.target.value.map(key => values[key]),
          );
        }}
        input={<Input id="select-multiple-checkbox" />}
        renderValue={() => selected.join(', ')}
        MenuProps={MenuProps}
        style={{ marginTop: 0 }}
      >
        {Object.keys(values).map(key => (
          <MenuItem key={key} value={key} className={(spacers.includes(key) ? classes.spacer : '')}>
            <Checkbox checked={selected.includes(key)} />
            <ListItemText primary={key} />
          </MenuItem>
        ))}
      </Select>
    </FormControl>
  );
}

MTableGroupFilter.propTypes = {
  columnDef: PropTypes.object.isRequired,
  values: PropTypes.objectOf(PropTypes.object).isRequired,
  onFilterChanged: PropTypes.func.isRequired,
  spacers: PropTypes.arrayOf(PropTypes.string),
};

MTableGroupFilter.defaultProps = {
  spacers: [],
};
