import gql from 'graphql-tag';

const NOTES_QUERY = gql`
query notes($context: String!, $id: Int, $contextUuid: String){
  notes(context: $context, id: $id, contextUuid: $contextUuid){
    text
    context
    createdAt
  }
}
`;

export { NOTES_QUERY };
