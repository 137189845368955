import gql from 'graphql-tag';

const USER_QUERY = gql`
query user{
  user{
    username
    status
    createdAt
  }
}
`;

const USER_GROUPS_QUERY = gql`
query userGroups($username: String!){
  userGroups(username: $username){
    group
    description
    createdAt
  }
}
`;

export {
  USER_QUERY,
  USER_GROUPS_QUERY,
};
