import gql from 'graphql-tag';

const URL_GROUP_MUTATION = gql`
mutation addUrlGroup(
  $name: String!,
  $versionUuid: String!,
  $pattern: String!,
  $patternType: String!,
  $priority: Int!,
  $generationPriority: Float!,
  $field: String!,
  $index: Int,
  $isGroupDataFeed: Boolean!,
  $dataFeedField: String,
  $dataFeedPattern: String,
  $dataFeedIndex: Int
  ){
  addUrlGroup(args: {
    name: $name,
    versionUuid: $versionUuid,
    pattern: $pattern,
    patternType: $patternType,
    priority: $priority,
    generationPriority: $generationPriority,
    field: $field,
    index: $index,
    isGroupDataFeed: $isGroupDataFeed,
    dataFeedField: $dataFeedField,
    dataFeedPattern: $dataFeedPattern,
    dataFeedIndex: $dataFeedIndex
  }){
    urlGroup{
      id
      name
      versionUuid
      priority
      generationPriority
      type
      rules{
      	id
        field
        index
        pattern
        patternType
      }
    }
  }
}
`;


const URL_GROUP_LABELS_MUTATION = gql`
mutation setUrlGroupDataFeedLabel($groupId: String!, $index: Int!, $label: String!){
  setUrlGroupDataFeedLabel(args: {
    urlGroupId: $groupId,
    index: $index,
    label: $label
  }){
    urlGroup{
      name
      dataFeedLabels
    }
  }
}
`;

const URL_GROUP_NAME_MUTATION = gql`
mutation setUrlGroupName($groupId: String!, $name: String!){
  setUrlGroupName(args: {
    urlGroupId: $groupId,
    name: $name
  }){
    urlGroup{
      name
      id
    }
  }
}
`;

const DELETE_URL_GROUP_MUTATION = gql`
mutation deleteUrlGroup ($id: String!){
  deleteUrlGroup(args: {
		id: $id
  }){
    urlGroup{
      name
      id
    }
  }
}
`;

const URL_GROUP_GENERATION_PRIORITY_MUTATION = gql`
mutation setUrlGroupGenerationPriority($groupId: String!, $generationPriority: Float!){
  setUrlGroupGenerationPriority(args: {
    urlGroupId: $groupId,
    generationPriority: $generationPriority
  }){
    urlGroup{
      name
      generationPriority
    }
  }
}
`;

export {
  URL_GROUP_LABELS_MUTATION,
  URL_GROUP_NAME_MUTATION,
  URL_GROUP_MUTATION,
  DELETE_URL_GROUP_MUTATION,
  URL_GROUP_GENERATION_PRIORITY_MUTATION
};
