import React from 'react';
import PropTypes from 'prop-types';
import MaterialTable from 'material-table';

import { VERSION_URL_GROUPS_QUERY } from '../../schema/queries/versions';
import { materialTableDataQuery } from '../../lib/helpers';
import Keywords from './Keywords';

const KeywordGroups = (props) => {
  const { versionUuid } = props;

  return (
    <MaterialTable
      title="Keywords by Group"
      columns={[
        {
          title: 'Group Name',
          field: 'name',
          type: 'string',
        },
      ]}
      options={{
        paging: false,
        search: false,
      }}
      data={request => materialTableDataQuery(
        request,
        VERSION_URL_GROUPS_QUERY,
        { versionUuid },
        result => ({
          data: result.data.version.urlGroups,
        }),
      )}
      detailPanel={[
        {
          tooltip: 'Show Name',
          render: rowData => <Keywords groupId={rowData.id} />,
        },
      ]}
    />
  );
};


export default KeywordGroups;

KeywordGroups.propTypes = {
  versionUuid: PropTypes.string.isRequired,
};
