import React, { useState } from 'react';
import Button from '@material-ui/core/Button';
import TextField from '@material-ui/core/TextField';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogTitle from '@material-ui/core/DialogTitle';

import PropTypes from 'prop-types';
import {
  Formik, ErrorMessage, Form, Field,
} from 'formik';
import * as Yup from 'yup';

import { useMutation } from '@apollo/react-hooks';
import ADD_NOTE from '../../schema/mutations/Note';
import { showSnackbar } from '../Snackbar';
import { PROJECT_NOTES_QUERY } from '../../schema/queries/projects';

export default function AddNote(props) {
  const [addNoteMutation] = useMutation(ADD_NOTE);
  const { open, handleClose, values: defaultValues } = props;
  const { assetId, context, contextId, contextUuid } = defaultValues;

  const FormSchema = Yup.object().shape({
    note: Yup.string()
      .required('The note is required.'),
  });
  const resetState = () => {
    handleClose();
  };
  const handleSubmit = (values) => {
    addNoteMutation({
        variables: {
          assetId: values.assetId,
          context: values.context,
          text: values.note,
          contextId: values.contextId,
          contextUuid: values.contextUuid
        },
		refetchQueries:[{
          query: PROJECT_NOTES_QUERY,
          variables: { assetId }
        }]
    })
	.then(({ data }) => {
        showSnackbar(`Note has been created.`);
    })
	.catch((error) => {
      showSnackbar(
        error.message,
        'error',
      );
    })
	.finally(resetState);
  }

  return (
    <div>
      <Dialog open={open} onClose={handleClose} aria-labelledby="form-dialog-title" disableBackdropClick>
        <DialogTitle id="form-dialog-title">Add Note</DialogTitle>
        <Formik
          initialValues={{
            assetId,
            context,
            contextId,
            contextUuid,
            note: '',
          }}
          validationSchema={FormSchema}
          onSubmit={handleSubmit}
          render={(formProps) => {
            const {
              values,
              touched,
              errors,
              handleChange,
              handleBlur,
              submitForm,
            } = formProps;
            return (
              <div>
                <DialogContent>
                  <Form>
                    <Field
                      id="assetId"
                      name="assetId"
                      type="hidden"
                    />
                    <TextField
                      id="note"
                      name="note"
                      margin="dense"
                      label="Note"
                      type="text"
                      variant="filled"
                      rows={5}
                      value={values.note}
                      onChange={handleChange}
                      onBlur={handleBlur}
                      style={{ width: 400 }}
                      fullWidth
                      multiline
                      helperText={(errors.note && touched.note) && errors.note}
                      error={touched.note && !!errors.note}
                    />
                  </Form>
                </DialogContent>

                <DialogActions>
                  <Button onClick={resetState} color="primary">
                    Cancel
                  </Button>
                  <Button onClick={submitForm} color="primary">
                    Save
                  </Button>
                </DialogActions>
              </div>
            );
          }}
        />
      </Dialog>
    </div>
  );
}

AddNote.propTypes = {
  open: PropTypes.bool.isRequired,
  handleClose: PropTypes.func.isRequired,
  values: PropTypes.shape({
    note: PropTypes.string,
  }).isRequired,
};

AddNote.defaultProps = {};
