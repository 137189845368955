import React from 'react';
import PropTypes from 'prop-types';
import MaterialTable from 'material-table';
import { makeStyles } from '@material-ui/core/styles';
import Paper from '@material-ui/core/Paper';

import { KEYWORD_GROUP_QUERY } from '../../schema/queries/keywords';
import { materialTableDataQuery } from '../../lib/helpers';

const useStyles = makeStyles(theme => ({
  panel: {
    padding: '10px',
    backgroundColor: theme.palette.grey[200],
  },
}));

const Keywords = (props) => {
  const { groupId } = props;
  const classes = useStyles();

  return (
    <div className={classes.panel}>
      <MaterialTable
        title="Keywords"
        columns={[
          {
            title: 'Url',
            field: 'url',
            type: 'string',
          },
          {
            title: 'Keyword',
            field: 'keyword',
            type: 'string',
          },
        ]}
        options={{
          padding: 'dense',
          emptyRowsWhenPaging: false,
        }}
        data={request => materialTableDataQuery(
          request,
          KEYWORD_GROUP_QUERY,
          { groupId },
          result => ({
            data: result.data.urlGroup.keywords.data,
            page: result.data.urlGroup.keywords.pagination.pageNumber - 1,
            totalCount: result.data.urlGroup.keywords.pagination.totalResults,
          }),
        )}
        components={{
          Container: containerProps => (
            <Paper elevation={0} {...containerProps} />
          ),
        }}
      />
    </div>
  );
};


export default Keywords;

Keywords.propTypes = {
  groupId: PropTypes.string.isRequired,
};
