import gql from 'graphql-tag';

const SIDELOAD_FILE = gql`
  mutation addSideloadFile(
      $name: String!,
      $assetId: String!, 
      $format: String!, 
      $type: String!, 
      $source: String!, 
      $note: String!
    ) {
    sideloadFile(
      fileInput: {
        name: $name, 
        assetId: $assetId, 
        format: $format, 
        type: $type, 
        source: $source, 
        note: $note
      }
    ) 
    {
      status
      assetId
      format
      type
      source
      name
      url
      md5
    }
  }
`;

export default SIDELOAD_FILE;
