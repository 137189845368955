import React from 'react';
import PropTypes from 'prop-types';
import { useMutation } from '@apollo/react-hooks';

import RemoveCircle from '@material-ui/icons/RemoveCircle';
import RemoveCircleOutline from '@material-ui/icons/RemoveCircleOutline';
import Error from '@material-ui/icons/Error';
import CircularProgress from '@material-ui/core/CircularProgress';
import Tooltip from '@material-ui/core/Tooltip';
import IconButton from '@material-ui/core/IconButton';
import { makeStyles } from '@material-ui/core/styles';

import { TOGGLE_VERSION_EXCLUDE_ROOT, TOGGLE_VERSION_EXCLUDE_FILE } from '../../schema/mutations/Version';
import { showSnackbar } from '../Snackbar';

const useStyles = makeStyles(theme => ({
  error: {
    color: theme.palette.error.dark,
  },
}));

export default function ExcludeUrls(props) {
  const { tooltip, versionUuid, root, selected, fileId } = props;
  const classes = useStyles();

  const mutation = root
    ? TOGGLE_VERSION_EXCLUDE_ROOT
    : TOGGLE_VERSION_EXCLUDE_FILE;

  const [
    toggleExcludeMutation,
    { data, loading, error },
  ] = useMutation(mutation);

  function handleClick() {
    const variables = { uuid: versionUuid };

    if (root) {
      variables.root = root;
    }

    if (fileId) {
      variables.fileId = fileId;
    }

    toggleExcludeMutation({
      variables,
      refetchQueries: ['getVersion'],
    });
  }

  if (loading) {
    return <IconButton disabled><CircularProgress size={22} /></IconButton>;
  }

  if (error) {
    showSnackbar('Unable to exclude the selected URLs.', 'error');
    return <IconButton disabled><Error className={classes.error} /></IconButton>;
  }

  let isExcluded = selected;
  if (data) {
    isExcluded = root
      ? data.toggleVersionExcludeRoot.version.excludeRoot.includes(root)
      : data.toggleVersionExcludeFile.version.excludeFiles.includes(fileId);
  }

  return (
    <Tooltip title={tooltip}>
      <IconButton
        onClick={handleClick}
      >
        {isExcluded
          ? <RemoveCircle className={classes.error} />
          : <RemoveCircleOutline />
        }
      </IconButton>
    </Tooltip>
  );
}

ExcludeUrls.propTypes = {
  versionUuid: PropTypes.string.isRequired,
  root: PropTypes.string,
  fileId: PropTypes.number,
  tooltip: PropTypes.string,
  selected: PropTypes.bool,
};

ExcludeUrls.defaultProps = {
  tooltip: 'Exclude Urls',
  selected: false,
  root: '',
  fileId: undefined,
};
