import React from 'react';
import PropTypes from 'prop-types';
import { useMutation } from '@apollo/react-hooks';
import TextField from '@material-ui/core/TextField';
import { IconButton } from '@material-ui/core';
import SaveIcon from '@material-ui/icons/Save';
import {
  Formik, ErrorMessage, Form, Field,
} from 'formik';
import * as Yup from 'yup';


import { URL_GROUP_LABELS_MUTATION } from '../../schema/mutations/url_groups';


const FormSchema = Yup.object().shape({
  label: Yup.string()
    .required('The Data Feed label is required.')
    .matches(
      /^[a-zA-Z0-9_]+$/,
      'Allowed characters are letters, numbers and underscore',
    )
    .matches(
      /[a-zA-Z_]+/,
      'Must contain at least one letter or underscore',
    )
});

const DataFeedLabel = (props) => {
  const { groupId, index, initialValue, readOnly } = props;
  const [updateLabel, { data }] = useMutation(URL_GROUP_LABELS_MUTATION);
  let value = initialValue;

  if (data && data.setUrlGroupDataFeedLabel) {
    value = data.setUrlGroupDataFeedLabel.urlGroup.dataFeedLabels[index];
  }

  function save(values) {
    updateLabel({
      variables: { groupId, index, label: values.label },
    });
  }


  return (
    readOnly ? (
      <span>{value}</span>
    ) : (
      <Formik
        initialValues={{
          label: value,
        }}
        validationSchema={FormSchema}
        onSubmit={save}
        render={(formProps) => {
          const {
            values,
            touched,
            errors,
            handleChange,
            handleBlur,
            submitForm,
          } = formProps;
          return (
            <Form>
              <TextField
                type="text"
                name="label"
                value={values.label}
                placeholder={`Data Feed (${index})`}
                InputProps={{
                  endAdornment: (
                    <IconButton disabled={values.label === value} onClick={submitForm}>
                      <SaveIcon />
                    </IconButton>
                  ),
                }}
                style={{
                  minWidth: 200,
                }}
                onChange={handleChange}
                onBlur={handleBlur}
                helperText={(errors.label && touched.label) && errors.label}
                error={!!errors.label}
              />
            </Form>
          );
        }}
      />
    )
  );
};

export default DataFeedLabel;


DataFeedLabel.propTypes = {
  groupId: PropTypes.string.isRequired,
  index: PropTypes.number.isRequired,
  initialValue: PropTypes.string,
  readOnly: PropTypes.bool,
};

DataFeedLabel.defaultProps = {
  initialValue: '',
  readOnly: false,
};
