import React from 'react';
import PropTypes from 'prop-types';
import { useMutation } from '@apollo/react-hooks';

import TextField from '@material-ui/core/TextField';
import { IconButton } from '@material-ui/core';
import SaveIcon from '@material-ui/icons/Save';
import {
  Formik, ErrorMessage, Form, Field,
} from 'formik';
import * as Yup from 'yup';


import { URL_GROUP_NAME_MUTATION } from '../../schema/mutations/url_groups';

const FormSchema = Yup.object().shape({
  name: Yup.string()
    .required('The Group Name is required.'),
});

const GroupName = (props) => {
  const { groupId, name, readOnly } = props;
  const [updateGroupName] = useMutation(URL_GROUP_NAME_MUTATION);

  function save(values) {
    updateGroupName({
      variables: { groupId, name: values.name },
    });
  }

  return (
    readOnly ? (
      <span>{name}</span>
    ) : (
      <Formik
        enableReinitialize
        initialValues={{
          name,
        }}
        validationSchema={FormSchema}
        onSubmit={save}
        render={(formProps) => {
          const {
            values,
            touched,
            errors,
            handleChange,
            handleBlur,
            submitForm,
            handleSubmit,
          } = formProps;
          return (
            <Form>
              <TextField
                type="text"
                name="name"
                value={values.name}
                placeholder="Group Name"
                InputProps={{
                  endAdornment: <IconButton disabled={values.name === name} onClick={submitForm}><SaveIcon /></IconButton>,
                }}
                style={{
                  minWidth: 200,
                }}
                onChange={handleChange}
                onBlur={handleBlur}
                helperText={(errors.name && touched.name) && errors.name}
                error={!!errors.name}
              />
            </Form>
          );
        }}
      />
    )
  );
};

export default GroupName;


GroupName.propTypes = {
  groupId: PropTypes.string.isRequired,
  name: PropTypes.string.isRequired,
  readOnly: PropTypes.bool,
};

GroupName.defaultProps = {
  readOnly: false,
};
