import React from 'react';
import PropTypes from 'prop-types';
import { useQuery } from '@apollo/react-hooks';
import MaterialTable from 'material-table';

import { URL_GROUPS_QUERY } from '../../schema/queries/url_groups';
import { VERSION_URL_METADATA_QUERY } from '../../schema/queries/versions';
import UrlDataTable from '../tables/datatype/Url';
import { materialTableDataQuery } from '../../lib/helpers';
import RemoveUrlGroupPattern from './RemoveUrlGroupPattern';
import MTableActionRendered from '../material-table/m-table-action-rendered';


export default function UrlGroupPatterns(props) {
  const { versionUuid, disableActions } = props;

  const { loading, error, data } = useQuery(URL_GROUPS_QUERY, {
    variables: {
      versionUuid,
    },
  });


  if (loading) {
    return <span>Loading...</span>;
  }

  if (error) {
    console.log(error);
  }

  const renderRule = rule => (
    <pre>{rule.field} {rule.index} | {rule.patternType} | {rule.pattern} | {rule.context.join(', ')}</pre>
  );

  return (
    <MaterialTable
      title="Url Groups"
      columns={[
        { field: 'name', title: 'Group Name' },
        { field: 'priority', title: 'Priority' },
        { field: 'generationPriority', title: 'Generation Priority' },
        
        {
          field: 'rules',
          title: 'Rules',
          render: rowData => rowData.rules.map(renderRule),
        },
      ]}
      data={data.urlGroups}
      options={{
        header: true,
        filtering: false,
        paging: true,
        search: false,
        showTitle: true,
        actionsColumnIndex: -1,
      }}
      components={{
        Action: actionProps => (
          <MTableActionRendered {...actionProps} />
        ),
      }}
      detailPanel={[
        {
          render: rowData => (
            <UrlDataTable
              exportFileName={`url-group_${rowData.name}`}
              localization={{
                pagination: {
                  labelDisplayedRows: '{from}-{to} of SAMPLE({count}) URLs',
                },
              }}
              query={request => materialTableDataQuery(
                { ...request, page: -1 },
                VERSION_URL_METADATA_QUERY,
                {
                  uuid: versionUuid,
                  groupId: rowData.id,
                  excludeGroupedUrls: 'prior',
                },
                result => ({
                  data: result.data.version.urlMetadata.data,
                  page: result.data.version.urlMetadata.pagination.pageNumber - 1,
                  totalCount: result.data.version.urlMetadata.pagination.totalResults,
                }),
                ['url'],
                { url: 'Url' },
              )}
              options={{
                pageSizeOptions: [10, 50, 100],
                pageSize: 10,
              }}
            />
          ),
          icon: 'list',
          tooltip: 'Toggle Data View',
        },
      ]}
      actions={
        disableActions ? [] : [
        rowData => ({
          render: () => (
            <RemoveUrlGroupPattern
              groupId={rowData.id}
              groupName={rowData.name}
            />
          ),
        }),
      ]}
    />
  );
}

UrlGroupPatterns.propTypes = {
  versionUuid: PropTypes.string.isRequired,
  disableActions: PropTypes.bool.isRequired,
};
