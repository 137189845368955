import React, { useState } from 'react';
import PropTypes from 'prop-types';
import Typography from '@material-ui/core/Typography';
import Tab from '@material-ui/core/Tab';
import Tabs from '@material-ui/core/Tabs';
import Paper from '@material-ui/core/Paper';
import Box from '@material-ui/core/Box';

import TabPanel from '../../components/TabPanel';
import KeywordAssociationSummary from '../../components/KeywordWorkbook/KeywordAssociationSummary';
import KeywordAssociationQa from '../../components/KeywordWorkbook/KeywordAssociationQa';
import KeywordAssociationNoKeyword from '../../components/KeywordWorkbook/KeywordAssociationNoKeyword';


export default function KeywordAssociation(props) {
  const { versionUuid, assetId } = props;
  const [tabValue, setTabValue] = useState(0);

  function handleTabChange(event, newValue) {
    setTabValue(newValue);
  }

  return (
    <div>
      <Box my={3}>
        <Paper square>
          <Tabs
            value={tabValue}
            indicatorColor="primary"
            textColor="primary"
            onChange={handleTabChange}
          >
            <Tab label="Summary" />
            <Tab label="QA" />
            <Tab label="No Association" />
          </Tabs>
        </Paper>
      </Box>
      <TabPanel value={tabValue} index={0}>
        <KeywordAssociationSummary versionUuid={versionUuid} />
      </TabPanel>
      <TabPanel value={tabValue} index={1}>
        <KeywordAssociationQa versionUuid={versionUuid} assetId={assetId} />
      </TabPanel>
      <TabPanel value={tabValue} index={2}>
        <KeywordAssociationNoKeyword versionUuid={versionUuid} />
      </TabPanel>
    </div>
  );
}

KeywordAssociation.propTypes = {
  versionUuid: PropTypes.string.isRequired,
  assetId: PropTypes.string.isRequired,
};
