import gql from 'graphql-tag';

const PROJECT_PRESIGN_QUERY = gql`
query projectPresign(
  $assetId: String!,
  $objectName: String!,
  $contentType: String!
) {
  project(
    assetId: $assetId
  ){
    presign(
      objectName: $objectName,
      contentType: $contentType
    ){
      signedUrl
      sideLoad
    }
  }
}
`;

const PROJECTS_QUERY = gql`
query projects { 
  allProjects{
    edges{
      node{
        name 
        id
        assetId
      }
    }
  }
}
`;

const PROJECT_QUERY = gql`
query project(
  $assetId: String!
) {
  project(
    assetId: $assetId
  ){
    assetId
    name
    domain
    countryCode
    userAgent
    useProxy
    instanceCount
    processingDelay
    createdAt
    lastUpdated
    revalidateAfterDays
  }
}
`;

const PROJECT_VERSIONS_QUERY = gql`
query projectVersions(
  $assetId: String!
) {
  project(
    assetId: $assetId
  ){
    versions{
      edges{
        node{
          name
          id
          uuid
          completedStages
          createdAt
          lastUpdated
          errors
        }
      }
    }
  }
}
`;

const PROJECT_NOTES_QUERY = gql`
query projectNotes(
  $assetId: String!
) {
  project(
    assetId: $assetId
  ){
    notes {
      fileName
      versionName
      text
      createdAt
      lastUpdated
      context
    }
  }
}
`;

export { PROJECTS_QUERY, PROJECT_QUERY, PROJECT_VERSIONS_QUERY, PROJECT_NOTES_QUERY, PROJECT_PRESIGN_QUERY };
