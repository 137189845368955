import React from 'react';
import PropTypes from 'prop-types';
import { useQuery } from '@apollo/react-hooks';
import { makeStyles } from '@material-ui/core/styles';
import MaterialTable from 'material-table';

import AddNote from '../../forms/AddNote';
import { NOTES_QUERY } from '../../../schema/queries/notes';
import FormattedCell from '../../material-table/FormattedCell';

const useStyles = makeStyles(theme => ({
  panel: {
    padding: '10px',
    backgroundColor: theme.palette.grey[200],
  },
}));


const NotesDataTable = (props) => {
  const classes = useStyles();
  const [open, setOpen] = React.useState(false);
  function handleClickOpen() {
    setOpen(true);
  }
  function handleClose() {
    setOpen(false);
    refetch();
  }
  const { assetId, context, contextId, contextUuid, hasPanel } = props;
  const paperProps = hasPanel ? { elevation: 0 } : {};
  const { data, loading, refetch, error } = useQuery(NOTES_QUERY, {
    variables: {
      context: context,
      id: contextId,
      contextUuid: contextUuid
    },
  });


  if (loading) {
    return <span>Loading...</span>;
  }

  if (error) {
    return <span>Unable to load data.</span>;
  }

  return (
  <div className={hasPanel ? classes.panel : ''}>
    <MaterialTable
      title="Notes"
      columns={[
        {
          title: 'Note',
          field: 'text',
        },
        {
          title: 'Date',
          field: 'createdAt',
          defaultSort: 'desc',
          type: 'datetime',
        },
      ]}
      data={data.notes}
      options={{
        emptyRowsWhenPaging: false,
      }}
	  actions={[
        {
          icon: 'add',
          tooltip: 'Add Note',
          position: 'toolbar',
          onClick: handleClickOpen,
        },
      ]}
      components={{
        Cell: properties => (
          <FormattedCell {...properties} />
        ),
      }}
    />
	<AddNote
      open={open}
      handleClose={handleClose}
      values={{
        assetId,
        context,
        contextId,
        contextUuid,
        note: '',
      }}
    />
    </div>
  );
};

export default NotesDataTable;

NotesDataTable.propTypes = {
  assetId: PropTypes.string,
  context: PropTypes.string,
  contextId: PropTypes.number,
  contextUuid: PropTypes.string,
  hasPanel: PropTypes.bool,
};

NotesDataTable.defaultProps = {
  assetId: '',
  context: '',
  contextId: undefined,
  contextUuid: undefined,
  hasPanel: true,
};
