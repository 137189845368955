import React from 'react';
import PropTypes from 'prop-types';
import { useLazyQuery, useMutation } from '@apollo/react-hooks';
import MaterialTable from 'material-table';

import AddUser from '../components/forms/AddUser';
import { USER_QUERY } from '../schema/queries/user';
import { showSnackbar } from '../components/Snackbar';
import FormattedCell from '../components/material-table/FormattedCell';
import DialogTitle from '@material-ui/core/DialogTitle';
import Dialog from '@material-ui/core/Dialog';
import AppBar from '@material-ui/core/AppBar';
import Toolbar from '@material-ui/core/Toolbar';
import IconButton from '@material-ui/core/IconButton';
import Typography from '@material-ui/core/Typography';
import CloseIcon from '@material-ui/icons/Close';
import { makeStyles } from '@material-ui/core/styles';
import Button from '@material-ui/core/Button';
import Slide from '@material-ui/core/Slide';
import UserGroups from '../components/tables/UserGroups'
import { DELETE_USER_MUTATION } from '../schema/mutations/User';

const useStyles = makeStyles((theme) => ({
  appBar: {
    position: 'relative',
  },
  title: {
    marginLeft: theme.spacing(2),
    flex: 1,
  },
}));

const Transition = React.forwardRef(function Transition(props, ref) {
  return <Slide direction="up" ref={ref} {...props} />;
});


const User = (props) => {
  const { open, handleClose, action } = props;
  const classes = useStyles();
  const [openForm, setOpen] = React.useState(false);
  const [ getUser, { data, loading, refetch, error, called }] = useLazyQuery(USER_QUERY);
  const getUserQuery = () => {
    if (data && data.user) {
      return data.user;
    }
  };
  let calledProxy = called;

  if (action && !calledProxy) {
    getUser();
  }

  function handleFormClose() {
    setOpen(false);
    refetch();
  }
  function handleClickOpen() {
    setOpen(true);
  }
  
  const [unAssignUser] = useMutation(DELETE_USER_MUTATION);
  
  const handleDelete = oldData => new Promise((resolve, reject) => {
    unAssignUser({
      variables: {
        username: oldData.username,
      },
      refetchQueries: ['user'],
      awaitRefetchQueries: true,
    }).then(() => resolve())
      .catch(() => {
        showSnackbar('Unable to delete the User.', 'error');
        return reject();
      });
  });


  if (loading) {
    return <span>Loading...</span>;
  }

  if (error) {
    showSnackbar('Unable to access without permissions', 'error');
    return null;
  }

  return (
  <div className="users">
    <Dialog fullScreen open={open} onClose={handleClose} TransitionComponent={Transition}>
      <AppBar className={classes.appBar}>
        <Toolbar>
          <IconButton edge="start" color="inherit" onClick={handleClose} aria-label="close">
            <CloseIcon />
          </IconButton>
          <Typography variant="h6" className={classes.title}>
            Users and Groups
          </Typography>
        </Toolbar>
      </AppBar>
      <MaterialTable
        title="Users"
        columns={[
          {
            title: 'Username',
            field: 'username',
            defaultSort: 'asc',
          },
          {
            title: 'Status',
            field: 'status',
          },
          {
            title: 'Created',
            field: 'createdAt',
            type: 'datetime',
          },
        ]}
        data={getUserQuery()}
        options={{
          emptyRowsWhenPaging: false,
          actionsColumnIndex: -1,
        }}
        actions={[
          {
            icon: 'add',
            tooltip: 'Add User',
            position: 'toolbar',
            onClick: handleClickOpen,
          },
        ]}
        editable={{
          isEditable: () => false,
          onRowDelete: handleDelete,
        }}
        components={{
          Cell: properties => (
            <FormattedCell {...properties} />
          ),
        }}
        detailPanel={[
          {
            render: rowData => <UserGroups username={rowData.username} />,
            icon: 'list',
            tooltip: 'Toggle Group View',
          },
        ]}
      />
      <AddUser
        open={openForm}
        handleClose={handleFormClose}
      />
    </Dialog>
  </div>
  );
};

export default User;

User.propTypes = {
  open: PropTypes.bool.isRequired,
  handleClose: PropTypes.func.isRequired,
  action: PropTypes.bool,
};

User.defaultProps = {
};
