import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { makeStyles } from '@material-ui/core/styles';
import Paper from '@material-ui/core/Paper';
import Divider from '@material-ui/core/Divider';
import Button from '@material-ui/core/Button';
import SearchIcon from '@material-ui/icons/Search';
import SaveIcon from '@material-ui/icons/Save';
import MenuItem from '@material-ui/core/MenuItem';
import TextField from '@material-ui/core/TextField';
import Checkbox from '@material-ui/core/Checkbox';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import Typography from '@material-ui/core/Typography';


import UrlGroupCreate from '../forms/UrlGroupCreate';

const useStyles = makeStyles(theme => ({
  root: {
    padding: theme.spacing(1),
    display: 'flex',
    alignItems: 'center',
    flexWrap: 'wrap',
    justifyContent: 'flex-end',
    backgroundColor: theme.palette.grey[100],
  },
  input: {
    marginRight: theme.spacing(1),
    marginLeft: theme.spacing(1),
    backgroundColor: 'white',
    flex: 1,
  },
  index: {
    marginRight: theme.spacing(1),
    marginLeft: theme.spacing(1),
    backgroundColor: 'white',
    width: 85,
  },
  iconButton: {
    padding: 10,
  },
  divider: {
    height: 28,
    margin: 4,
  },
  select: {
    width: 150,
    borderBottom: 'none',
    marginRight: theme.spacing(1),
    marginLeft: theme.spacing(1),
    backgroundColor: 'white',
  },
  hr: {
    width: '100%',
    marginTop: theme.spacing(1),
    marginBottom: theme.spacing(1),
  },
  br: {
    width: '100%',
    visibility: 'hidden',
  },
  heading: {
    width: '100%',
    marginLeft: theme.spacing(1),
  },
  checkbox: {
    marginLeft: theme.spacing(1),
  },
  button: {
    margin: theme.spacing(1),
  },
}));


export default function CreateUrlGroup(props) {
  const classes = useStyles();
  const { defaultField, onFilterChanged, versionUuid, fields } = props;
  const [pattern, setPattern] = useState('');
  const [index, setIndex] = useState('');
  const [field, setField] = useState(defaultField);
  const [type, setType] = useState('regex');
  const [saving, setSaving] = useState(false);
  const [isGroupDataFeed, setIsGroupDataFeed]= useState(false);
  const [dataFeedPattern, setDataFeedPattern] = useState('');
  const [dataFeedIndex, setDataFeedIndex] = useState('');
  const [dataFeedField, setDataFeedField] = useState(defaultField);

  const handlePatternChange = (event) => {
    setPattern(event.target.value);
  };

  const handleFieldChange = (event) => {
    setField(event.target.value);
    setIndex('');
  };

  const handleTypeChange = (event) => {
    setType(event.target.value);
    if (event.target.value !== 'regex') {
      setIsGroupDataFeed(false);
    }
  };
  
  const handleIndexChange = (event) => {
    setIndex(event.target.value);
  };

  const handleDataFeedChange = (event) => {
    setIsGroupDataFeed(event.target.checked);
    if (event.target.checked) {
      setDataFeedPattern('');
      setDataFeedIndex('');
    }
  };

  const handleSubmit = (event) => {
    event.preventDefault();
    let filters = [];
    let op = type;
    if (isGroupDataFeed) {
      op = 'regex-capture';
    }
    if (pattern) {
      filters = [{ field, op, value: pattern, index: index || null }];
    }
    if (dataFeedPattern) {
      filters.push({ field: dataFeedField, op: 'regex-capture', value: dataFeedPattern, index: dataFeedIndex || null });
    }
    onFilterChanged(filters);
  };

  const handleSave = () => {
    if (pattern) {
      setSaving(true);
    }
  };

  const handleClose = () => {
    setSaving(false);
  };

  const handleDataFeedPatternChange = (event) => {
    setDataFeedPattern(event.target.value);
  };

  const handleDataFeedFieldChange = (event) => {
    setDataFeedField(event.target.value);
    setDataFeedIndex('');
  };

  const handleDataFeedIndexChange = (event) => {
    setDataFeedIndex(Number(event.target.value));
  };

  const arrayFields = ['allH1s', 'allH2s', 'allH3s', 'allH4s', 'allH5s', 'allH6s'];

  const indexProps = {};
  if (index) {
    indexProps.index = index;
  }
  if (dataFeedIndex) {
    indexProps.dataFeedIndex = dataFeedIndex;
  }

  return (
    <React.Fragment>
      <Paper component="form" className={classes.root} onSubmit={handleSubmit}>
        <Typography variant="h6" className={classes.heading}>
          Group
        </Typography>
        <TextField
          className={classes.select}
          select
          value={field}
          onChange={handleFieldChange}
          variant="outlined"
          size="small"
        >
          {fields.map(item => <MenuItem value={item} key={item}>{item}</MenuItem>)}
        </TextField>
        {arrayFields.includes(field)
        && (
          <TextField
            type="number"
            className={classes.index}
            placeholder="Index"
            onChange={handleIndexChange}
            value={index}
            required
            variant="outlined"
            size="small"
          />
        )}
        <TextField
          className={classes.select}
          select
          value={type}
          onChange={handleTypeChange}
          variant="outlined"
          size="small"
        >
          <MenuItem value="regex" key="regex">regex</MenuItem>
          <MenuItem value="like" key="like">like</MenuItem>
        </TextField>
        <TextField
          className={classes.input}
          placeholder="Pattern"
          onChange={handlePatternChange}
          value={pattern}
          variant="outlined"
          size="small"
        />
        <FormControlLabel
          className={classes.checkbox}
          control={
            <Checkbox
              checked={isGroupDataFeed}
              onChange={handleDataFeedChange}
              disabled={type === 'like'}
              name="is-group-data-feed"
              color="primary"
            />
          }
          label="Data Feed"
        />
        {!isGroupDataFeed
        && (
          <React.Fragment>
            <Divider className={classes.hr} />
            <Typography variant="h6" className={classes.heading}>
              Data Feed
            </Typography>
            <TextField
              className={classes.select}
              select
              value={dataFeedField}
              onChange={handleDataFeedFieldChange}
              variant="outlined"
              size="small"
            >
              {fields.map(item => <MenuItem value={item} key={item}>{item}</MenuItem>)}
            </TextField>
            {arrayFields.includes(dataFeedField)
            && (
              <TextField
                type="number"
                className={classes.index}
                placeholder="Index"
                onChange={handleDataFeedIndexChange}
                value={dataFeedIndex}
                required
                variant="outlined"
                size="small"
              />
            )}
            <TextField 
              variant="outlined"
              size="small"
              className={classes.input}
              placeholder="Pattern"
              inputProps={{ 'aria-label': 'search pattern' }}
              onChange={handleDataFeedPatternChange}
              value={dataFeedPattern}
            />
          </React.Fragment>
        )}
        <Divider className={classes.br} />
        <Button
          type="submit"
          color="primary"
          variant="outlined"
          size="small"
          className={classes.button}
          startIcon={<SearchIcon />}
        >
          Preview
        </Button>
        <Button
          variant="contained"
          color="primary"
          size="small"
          className={classes.button}
          startIcon={<SaveIcon />}
          onClick={handleSave}
          disabled={!pattern || (arrayFields.includes(field) && !index)}
        >
          Save
        </Button>
      </Paper>
      <UrlGroupCreate
        open={saving}
        handleClose={handleClose}
        versionUuid={versionUuid}
        pattern={pattern}
        field={field}
        type={type}
        dataFeedPattern={dataFeedPattern}
        dataFeedField={dataFeedField}
        isGroupDataFeed={isGroupDataFeed}
        indexProps
      />
    </React.Fragment>
  );
}

CreateUrlGroup.propTypes = {
  onFilterChanged: PropTypes.func.isRequired,
  versionUuid: PropTypes.string.isRequired,
  defaultField: PropTypes.string.isRequired,
  fields: PropTypes.arrayOf(PropTypes.string).isRequired,
};
