import React from 'react';
import PropTypes from 'prop-types';


import AddFile from '../components/forms/AddFile';
import ErrorBoundary from '../components/ErrorBoundary';
import DataManagementTable from '../components/tables/DataManagementTable';
import DownloadFile from '../components/DownloadFile';
import MTableActionRendered from '../components/material-table/m-table-action-rendered';

const DataManagement = (props) => {
  const { assetId } = props;

  if (!assetId) {
    return null;
  }

  const [open, setOpen] = React.useState(false);

  function handleClickOpen() {
    setOpen(true);
  }

  function handleClose() {
    setOpen(false);
  }

  return (
    <div className="data-management">
      <ErrorBoundary fallback={<p>Something went wrong, please refreash the page.</p>}>
        <DataManagementTable
          assetId={assetId}
          title="Project Files"
          actions={[
            {
              icon: 'add',
              tooltip: 'Add Files',
              position: 'toolbar',
              onClick: handleClickOpen,
            },
            file => ({
              render: () => <DownloadFile file={file} tooltip="Download Source File" />,
            }),
          ]}
          options={{
            filtering: true,
            pageSize: 5,
            pageSizeOptions: [5, 50, 500],
            search: false,
            selection: false,
            columnsButton: true,
            emptyRowsWhenPaging: false,
            actionsColumnIndex: -1,
          }}
          components={{
            Action: actionProps => (
              <MTableActionRendered {...actionProps} />
            ),
          }}
        />
      </ErrorBoundary>
        <AddFile
          open={open}
          handleClose={handleClose}
          values={{
            assetId,
            name: '',
            note: '',
            format: '',
            type: '',
            source: '',
          }}
        />
    </div>
  );
};

export default DataManagement;

DataManagement.propTypes = {
  assetId: PropTypes.string,
};

DataManagement.defaultProps = {
  assetId: '',
};
