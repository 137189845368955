const sessionName = "session";

export const setSession = (session) => {
  sessionStorage.setItem(sessionName, JSON.stringify(session));
};

export const getSession = () => {
  const session = sessionStorage.getItem(sessionName);
  return JSON.parse(session) || {};
};

export const terminateSession = (redirectUrl=null) => {
  setSession(null);
  if (redirectUrl) {
    window.location = redirectUrl;
  }
}

