import React from 'react';
import PropTypes from 'prop-types';
import MaterialTable from 'material-table';
import { useTheme } from '@material-ui/core/styles';
import { useQuery } from '@apollo/react-hooks';
import Box from '@material-ui/core/Box';
import { CsvBuilder } from 'filefy';

import FormattedCell from '../../components/material-table/FormattedCell';
import { GROUP_AGGREGATES_QUERY } from '../../schema/queries/versions';
import UrlGroupTable from '../../components/tables/UrlGroupTable';
import GroupName from '../../components/forms/GroupName';
import GenerationPriority from '../../components/forms/GenerationPriority';

const successColor = '#AFFFB0';
const warningColor = '#DCB84D';


export default function UrlGroups(props) {
  const tableRef = React.useRef();
  // TODO: Implement read-only UI
  const { versionUuid, readOnly } = props;
  const { loading, error, data, refetch } = useQuery(GROUP_AGGREGATES_QUERY, {
    variables: {
      uuid: versionUuid,
    },
  });

  const theme = useTheme();
  const styles = {
    success: {
      backgroundColor: successColor,
      color: theme.palette.getContrastText(successColor),
    },
    warning: {
      backgroundColor: warningColor,
      color: theme.palette.getContrastText(warningColor),
    },
    error: {
      backgroundColor: theme.palette.error.light,
      color: theme.palette.getContrastText(theme.palette.error.light),
    },
  };

  const stylers = {
    percentError: (value) => {
      const intValue = parseInt(value, 10);
      if (intValue < 1) {
        return styles.success;
      }
      if (intValue < 10) {
        return styles.warning;
      }
      return styles.error;
    },
    percentValid: (value) => {
      const intValue = parseInt(value, 10);
      if (intValue < 90) {
        return styles.error;
      }
      if (intValue < 100) {
        return styles.warning;
      }
      return styles.success;
    },
  };

  const columns = [
    {
      field: 'group',
      title: 'Group Name',
      type: 'string',
      sorting: false,
      render: rowData => (
        <GroupName groupId={rowData.group.id} name={rowData.group.name} readOnly={readOnly} />
      ),
    },
    {
      field: 'group',
      title: 'Generation Priority',
      type: 'numeric',
      sorting: false,
      render: rowData => (
        <GenerationPriority groupId={rowData.group.id} priority={rowData.group.generationPriority} readOnly={readOnly} />
      ),
    },
    {
      field: 'group',
      title: 'Group Type',
      type: 'string',
      sorting: false,
      render: rowData => rowData.group.type,
    },
    {
      field: 'group',
      title: 'Data Feed Labels',
      type: 'string',
      sorting: false,
      render: rowData => rowData.group.dataFeedLabels.join("\n"),
    },
    {
      field: 'totalUrls',
      title: 'Total URL Count',
      type: 'numeric',
      cellStyle: {
        backgroundColor: theme.palette.grey[100],
        fontWeight: 'bold',
      },
    },
    { field: 'count300s', title: 'Count 300s', type: 'numeric' },
    { field: 'count403s', title: 'Count 403s', type: 'numeric' },
    { field: 'count404s', title: 'Count 404s', type: 'numeric' },
    { field: 'countErrors', title: 'Count Errors', type: 'numeric' },
    {
      field: 'percentError',
      title: 'Percent Error',
      type: 'numeric',
      cellStyle: stylers.percentError,
    },
    { field: 'count200s', title: 'Count 200s', type: 'numeric' },
    { field: 'countNoindex', title: 'Count Noindex', type: 'numeric' },
    { field: 'countDiffCanonical', title: 'Count Diff Canonical', type: 'numeric' },
    {
      field: 'percentDiffCanonical',
      title: 'Percent Diff Canonical',
      type: 'numeric',
      cellStyle: stylers.percentError,
    },
    {
      field: 'percentValid',
      title: 'Percent Valid',
      type: 'numeric',
      cellStyle: stylers.percentValid,
    },
    { field: 'countWithRank', title: 'Count With Rank', type: 'numeric' },
    {
      field: 'percentWithRank',
      title: 'Percent With Rank',
      type: 'numeric',
      cellStyle: stylers.percentValid,
    },
    { field: 'countUniqueKeyword', title: 'Count Unique Keyword', type: 'numeric' },
    { field: 'countKeyword', title: 'Count Keyword', type: 'numeric' },
  ];

  if (loading) {
    return <span>Loading...</span>;
  }

  if (error) {
    console.log(error);
    return <span>Error: See console.</span>;
  }

  const columnValues = {};
  const summaryColumns = [];
  const summary = {};
  columns.forEach((column) => {
    columnValues[column.field] = data.version.urlGroupAggregates.map(row => row[column.field]);
  });

  // Calculate sum
  columns.forEach((column) => {
    if (column.field !== 'group') {
      summary[column.field] = columnValues[column.field].reduce((a, b) => a + b, 0);
      summaryColumns.push(column);
    }
  });

  const totalUrls = summary.totalUrls / 100;
  summary.percentDiffCanonical = summary.countDiffCanonical / totalUrls;
  summary.percentError = (summary.countErrors + summary.countDiffCanonical) / totalUrls;
  summary.percentValid = summary.count200s / totalUrls;
  summary.percentWithRank = summary.countWithRank / totalUrls;




  return (
    <React.Fragment>
      <Box my={2}>
        <MaterialTable
          title="Totals"
          columns={summaryColumns}
          data={[summary]}
          options={{
            headerStyle: {
              lineHeight: 1.5,
            },
            columnsButton: false,
            padding: 'dense',
            paging: false,
            search: false,
            sorting: false,
            exportButton: true,
          }}
          components={{
            Cell: cellProps => (
              <FormattedCell {...cellProps} style={{ whiteSpace: 'pre' }} />
            ),
          }}
        />
      </Box>
      <Box my={2}>
        <MaterialTable
          tableRef={tableRef}
          title="Url Groups"
          columns={columns}
          data={data.version.urlGroupAggregates}
          options={{
            headerStyle: {
              lineHeight: 1.5,
            },
            columnsButton: true,
            padding: 'dense',
            paging: false,
            search: false,
            exportButton: true,
            exportCsv: (csvColumns, csvData) => {
              const renderData = csvData.map(rowData => (
                csvColumns.map((columnDef) => {
                  if (columnDef.title === 'Group Name') {
                    return rowData[columnDef.field].name;
                  }
                  if (columnDef.title === 'Group Type') {
                    return rowData[columnDef.field].type;
                  }
                  if (columnDef.title === 'Data Feed Labels') {
                    return rowData[columnDef.field].dataFeedLabels.join(', ');
                  }
                  return rowData[columnDef.field];
                })
              ));

              const builder = new CsvBuilder('sitemap.csv');
              builder
                .setColumns(columns.map(columnDef => columnDef.title))
                .addRows(renderData)
                .exportFile();
            },
          }}
          components={{
            Cell: cellProps => (
              <FormattedCell {...cellProps} style={{ whiteSpace: 'pre' }} />
            ),
          }}
          detailPanel={[
            {
              tooltip: 'Toggle Data View',
              icon: 'list',
              render: rowData => (
                <UrlGroupTable
                  versionUuid={versionUuid}
                  groupName={rowData.group.name}
                  groupId={rowData.group.id}
                  onClose={refetch}
                  dataFeedLabels={rowData.group.dataFeedLabels}
                  readOnly={readOnly}
                />
              ),
            },
          ]}
        />
      </Box>
    </React.Fragment>
  );
}


UrlGroups.propTypes = {
  versionUuid: PropTypes.string.isRequired,
  readOnly: PropTypes.bool,
};

UrlGroups.defaultProps = {
  readOnly: false,
};
