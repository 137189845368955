import React, { useState } from 'react';
import Button from '@material-ui/core/Button';
import TextField from '@material-ui/core/TextField';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogTitle from '@material-ui/core/DialogTitle';
import MenuItem from '@material-ui/core/MenuItem';
import FormControl from '@material-ui/core/FormControl';
import InputLabel from '@material-ui/core/InputLabel';
import Select from '@material-ui/core/Select';
import FormHelperText from '@material-ui/core/FormHelperText';

import PropTypes from 'prop-types';
import { useMutation, useLazyQuery } from '@apollo/react-hooks';
import { PROJECT_PRESIGN_QUERY } from '../../schema/queries/projects';
import {
  Formik, ErrorMessage, Form, Field,
} from 'formik';
import * as Yup from 'yup';

import SIDELOAD_FILE from '../../schema/mutations/SideloadFile';
import { showSnackbar } from '../Snackbar';

import {
  OPTION_XML, OPTION_CSV, OPTION_TSV, OPTION_SITEMAP, OPTION_URL, OPTION_URL_KEYWORD,
} from '../../constants';

const ADD_FILES_TITLE = 'Add File';
const ReactS3Uploader = require('react-s3-uploader');

export default function AddFile(props) {
  const [sideloadMutation] = useMutation(SIDELOAD_FILE);
  const { open, handleClose, values: defaultValues } = props;
  const { assetId } = defaultValues;
  const [upload, setUpload] = useState(null);
  const [uploader, setUploader] = useState(null);
  const [action, setAction] = useState(null);
  const [getUrl, { loading, data, called }] = useLazyQuery(PROJECT_PRESIGN_QUERY);
  const getPresignUrl = (file, callback) => {
    if (data && data.project) {
      return callback(data.project.presign);
    }
  };
  if (action && upload) {
	  getUrl({
      variables: { 
        assetId: assetId,
        objectName: upload.name,
        contentType: upload.type
      } 
    });
    setAction(null)
  };

  const FormSchema = Yup.object().shape({
    name: Yup.string()
      .required('The name is required.'),
    source: Yup.string()
      .test('testSource', 'The source or file is required.', value => (value != null || upload != null)),
    type: Yup.string()
      .required('The type is required.'),
    format: Yup.string()
      .required('The format is required.'),
  });
  const resetState = () => {
    handleClose();
    setUpload(null)
  };
  const handleSubmit = (values) => {
    if (upload) {
      uploader.uploadFile();
    }
    else {
      sideloadMutation({
        variables: {
          name: values.name,
          assetId: values.assetId,
          note: values.note,
          source: values.source,
          type: values.type,
          format: values.format,
        },
      })
        .then(({ data }) => {
          let status = 'success';
          if (data.sideloadFile.status !== 'ready') {
            status = data.sideloadFile.status.toLowerCase().includes('error') 
              ? 'error' : 'warning';
          }
          showSnackbar(
            `${data.sideloadFile.status} Data File has been added to the project.`,
            status,
          );
        })
        .catch((error) => {
          showSnackbar(
            error.message,
            'error',
          );
        })
        .finally(resetState);
    }
  };

  return (
    <div>
      <Dialog open={open} onClose={handleClose} aria-labelledby="form-dialog-title" disableBackdropClick>
        <DialogTitle id="form-dialog-title">{ADD_FILES_TITLE}</DialogTitle>
        <Formik
          initialValues={{
            assetId,
            name: '',
            note: '',
            source: '',
            type: '',
            format: '',
            upload,
          }}
          validationSchema={FormSchema}
          onSubmit={handleSubmit}
          render={(formProps) => {
            const {
              values,
              touched,
              errors,
              handleChange,
              handleBlur,
              submitForm,
            } = formProps;
            return (
              <div>
                <DialogContent>
                  <Form>
                    <Field
                      id="assetId"
                      name="assetId"
                      type="hidden"
                    />
                    <TextField
                      id="name"
                      name="name"
                      margin="dense"
                      label="Name"
                      type="text"
                      variant="filled"
                      value={values.name}
                      onChange={handleChange}
                      onBlur={handleBlur}
                      fullWidth
                      helperText={(errors.name && touched.name) && errors.name}
                      error={touched.name && !!errors.name}
                    />

                    <TextField
                      id="source"
                      name="source"
                      margin="dense"
                      label="source"
                      type="text"
                      variant="filled"
                      value={values.source}
                      onChange={handleChange}
                      onBlur={handleBlur}
                      fullWidth
                      helperText={(errors.source && touched.source) && errors.source}
                      error={touched.source && !!errors.source}
                    />

                    <ReactS3Uploader
                      getSignedUrl={getPresignUrl}
                      signingUrlMethod="GET"
                      uploadRequestHeaders={{}}
                      contentDisposition="auto"
                      autoUpload={false}
                      onChange={(e) => {
                        setUpload(e.target.files[0]);
                        setAction("queue");
                      }}
                      onClick={(e) => {
                        e.target.value = null;
                        setUpload(null);
                      }}
                      ref={(e) => {setUploader(e)}}
                      onFinish={(e) => {
                        console.log('onFinish', e);
                        sideloadMutation({
                        variables: {
                          name: values.name,
                          assetId: values.assetId,
                          note: values.note,
                          source: e.sideLoad,
                          type: values.type,
                          format: values.format,
                        },
                      })
                        .then(({ data }) => {
                          let status = 'success';
                          if (data.sideloadFile.status !== 'ready') {
                            status = data.sideloadFile.status.toLowerCase().includes('error') 
                              ? 'error' : 'warning';
                          }
                          showSnackbar(
                            `${data.sideloadFile.status} Data File has been added to the project.`,
                            status,
                          );
                        })
                        .catch((error) => {
                          showSnackbar(
                            error.message,
                            'error',
                          );
                        })
                        .finally(resetState);
                      }
                      }
                    />

                    <FormControl fullWidth error={touched.type && !!errors.type}>
                      <InputLabel htmlFor="type">Type</InputLabel>
                      <Select
                        value={values.type}
                        onChange={handleChange}
                        onBlur={handleBlur}
                        inputProps={{
                          name: 'type',
                          id: 'type',
                        }}
                      >
                        <MenuItem value="" disabled>Select Data Type</MenuItem>
                        <MenuItem value={OPTION_SITEMAP}>{OPTION_SITEMAP}</MenuItem>
                        <MenuItem value={OPTION_URL}>{OPTION_URL}</MenuItem>
                        <MenuItem value={OPTION_URL_KEYWORD}>{OPTION_URL_KEYWORD}</MenuItem>
                      </Select>
                      {(errors.type && touched.type) && <FormHelperText error>{errors.type}</FormHelperText>}
                    </FormControl>

                    {values.type == OPTION_SITEMAP ?
                    <FormControl fullWidth error={touched.format && !!errors.format}>
                      <InputLabel htmlFor="format">Format</InputLabel>
                      <Select
                        value={values.format}
                        onChange={handleChange}
                        onBlur={handleBlur}
                        inputProps={{
                          name: 'format',
                          id: 'format',
                        }}
                      >
                        <MenuItem value="">Select File Format</MenuItem>
                        <MenuItem value={OPTION_XML}>{OPTION_XML}</MenuItem>
                      </Select>
                      {(errors.format && touched.format) && <FormHelperText error>{errors.format}</FormHelperText>}
                    </FormControl>
                    :
                    <FormControl fullWidth error={touched.format && !!errors.format}>
                      <InputLabel htmlFor="format">Format</InputLabel>
                      <Select
                        value={values.format}
                        onChange={handleChange}
                        onBlur={handleBlur}
                        inputProps={{
                          name: 'format',
                          id: 'format',
                        }}
                      >
                        <MenuItem value="">Select File Format</MenuItem>
                        <MenuItem value={OPTION_CSV}>{OPTION_CSV}</MenuItem>
                        <MenuItem value={OPTION_TSV}>{OPTION_TSV}</MenuItem>
                      </Select>
                      {(errors.format && touched.format) && <FormHelperText error>{errors.format}</FormHelperText>}
                    </FormControl>
                  }

                    <TextField
                      id="note"
                      name=""
                      margin="dense"
                      label="Note"
                      type="text"
                      variant="filled"
                      rows={2}
                      value={values.note}
                      onChange={handleChange}
                      onBlur={handleBlur}
                      fullWidth
                      multiline
                    />
                  </Form>
                </DialogContent>

                <DialogActions>
                  <Button onClick={resetState} color="primary">
                    Cancel
                  </Button>
                  <Button onClick={submitForm} color="primary">
                    Save
                  </Button>
                </DialogActions>
              </div>
            );
          }}
        />
      </Dialog>
    </div>
  );
}

AddFile.propTypes = {
  open: PropTypes.bool.isRequired,
  handleClose: PropTypes.func.isRequired,
  values: PropTypes.shape({
    assetId: PropTypes.string,
    name: PropTypes.string,
    note: PropTypes.string,
    source: PropTypes.string,
    type: PropTypes.string,
    format: PropTypes.string,
  }).isRequired,
};

AddFile.defaultProps = {};
