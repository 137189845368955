const CONFIRM_SELECT_FILES = `Have you selected ALL of the URL files for this Version?\n
If you need to add more files click 'cancel' and use the table pagination to select additional files.
`;

const CONFIRM_URL_VALIDATION = `Are you ready to continue to URL Validation?\n
If you press 'OK' the site crawl will begin. You will not be able to add URLs beyond this point.\n
If you are unsure click 'cancel'.
`;

const CONFIRM_GROUP_URLS = `Are you ready to continue to URL Grouping and Data Feed Extraction?\n
If you press 'OK' any remaining un-grouped URLs will be grouped using machine learning algorithms.\n
If you need to create additional URL Groups click 'cancel'.
`;

const CONFIRM_KEYWORD_TEMPLATES = `Are you ready to continue Keyword Generation?\n
If you press 'OK' Keyword Generation will commence.\n
If you need to create additional Keyword Templates click 'cancel'.
`;

const CONFIRM_KEYWORD_WORKBOOK = `Are you ready to continue to Keyword Association?\n
If you press 'OK' Keyword Association will commence.\n
If you are unsure click 'cancel'.
`;

module.exports = {
  // Pages
  ILS_PAGE_HEADING: 'ILS Dashboard',

  // ILS Tab labels
  DATA_MANAGEMENT: 'Data Management',
  DISTRIBUTION: 'Distribution',
  OVERVIEW: 'Overview',
  PRIORITY: 'Priority',
  REPORTS: 'Reports',
  SITE_SEGMENTATION: 'Site Segmentation',
  SITE_SUMMARY: 'Site Summary',

  // Data Management Constants
  LOAD_FILE: 'Load File',
  XML_SITEMAP: 'XML Sitemap',
  COLUMN_DATA_TYPE: 'Data Type',
  COLUMN_NAME: 'Name',
  COLUMN_STATUS: 'Status',
  COLUMN_DATE_ADDED: 'Date Added',
  COLUMN_FILESIZE: 'File Size',
  COLUMN_INCLUDE: 'Include',

  // SitemapUpload form
  OPTION_XML: 'xml',
  OPTION_CSV: 'csv',
  OPTION_TSV: 'tsv',
  OPTION_SITEMAP: 'sitemap',
  OPTION_URL: 'url',
  OPTION_URL_KEYWORD: 'url_keyword',

  // Error messages
  INVALID_FIELD: 'This field is required',
  INVALID_URL: 'The url format is invalid. Ex: http://example.com',

  // Titles
  LOAD_FILE_TITLE: 'Load File',
  XML_SITEMAP_TITLE: 'Upload XML Sitemap',

  // file.type values
  STATUS_NEW: 'new',
  STATUS_QUEUED: 'queued',
  STATUS_READY: 'ready',
  STATUS_PROCESSING: 'processing',
  STATUS_PROCESSING_ERROR: 'processing_error',
  STATUS_DOWNLOAD_ERROR: 'download_error',
  STATUS_FATAL: 'fatal',
  STATUS_INVALID: 'invalid',
  STATUS_DUPLICATE: 'duplicate',
  STATUS_PROCESSED: 'processed',

  // file.format values
  FORMAT_XML: 'xml',
  FORMAT_CSV: 'csv',
  FORMAT_TSV: 'tsv',

  // file.type values
  TYPE_SITEMAPINDEX: 'sitemapindex',
  TYPE_SITEMAP: 'sitemap',
  TYPE_URL: 'url',
  TYPE_URL_KEYWORD: 'url_keyword',

  // File.datatype values
  DATATYPE_URL: 'url',
  DATATYPE_KEYWORD: 'keyword',
  DATATYPE_SITEMAPINDEX: 'sitemapindex',
  
  // Cognito Groups
  GROUP_ADMINISTRATOR: 'Administrator',
  GROUP_DEVELOPER: 'Developer',
  GROUP_SEO: 'SEO',

  SITEMAP_WORKBOOK_TITLE: 'Sitemap Workbook',
  KEYWORD_WORKBOOK_TITLE: 'Keyword Workbook',

  // Lifecycle
  /*
  step: The Unique ID for the step
  label: Label shown in stepper UI
  confirm: Confirmation displayed when clicking next button
  wait: (Boolean) If true, the step must wait for external action to complete
  */
  VERSION_STEPS: [
    {
      step: 'CreateVersion',
      label: '',
    },
    {
      step: 'SelectUrlFiles',
      label: 'Add Files',
      confirm: CONFIRM_SELECT_FILES,
    },
    {
      step: 'AddUrls',
      label: 'Approve Urls',
      confirm: CONFIRM_URL_VALIDATION,
    },
    {
      step: 'ValidateUrls',
      label: 'Validate Urls',
      wait: true,
    },
    {
      step: 'ApproveUrls',
      label: 'Group Urls',
      confirm: CONFIRM_GROUP_URLS,
    },
    {
      step: 'GroupUrls',
      label: 'Approve Page Groups',
      wait: true,
    },
    {
      step: 'ApproveSitemapWorkbook',
      label: 'Sitemap Workbook',
    },
    {
      step: 'AddKeywordTemplates',
      label: 'Add Keyword Templates',
    },
    {
      step: 'AddUrlKeywordFiles',
      label: 'Add Url Keyword Files',
      confirm: CONFIRM_KEYWORD_TEMPLATES,
    },
    {
      step: 'GenerateKeywords',
      label: 'Generate Keywords',
      wait: true,
    },
    {
      step: 'ApproveKeywordWorkbook',
      label: 'Keyword Workbook',
      confirm: CONFIRM_KEYWORD_WORKBOOK,
    },
    {
      step: 'GenerateKeywordAssociation',
      label: 'Generate Keyword Association',
      wait: true,
    },
    {
      step: 'ApproveKeywordAssociationWorkbook',
      label: 'Keyword Association Workbook',
    },
    {
      step: 'AddPriority',
      label: 'Add Priority',
    },
    {
      step: 'ApprovePriority',
      label: 'Approve Priority',
    },
    {
      step: 'ApprovePriorityWorkbook',
      label: 'Priority Workbook',
    },
  ],
};
