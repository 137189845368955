import React, { useState, useRef } from 'react';
import PropTypes from 'prop-types';
import MaterialTable from 'material-table';
import Box from '@material-ui/core/Box';
import Container from '@material-ui/core/Container';
import Tab from '@material-ui/core/Tab';
import Tabs from '@material-ui/core/Tabs';
import Paper from '@material-ui/core/Paper';
import changeCase from 'change-case';


import TabPanel from '../../components/TabPanel';
import FormattedCell from '../../components/material-table/FormattedCell';
import MTableFilterRow from '../../components/material-table/MTableFilterRow';
import MTableGroupFilter from '../../components/material-table/filters/group';
import { VERSION_URL_METADATA_QUERY } from '../../schema/queries/versions';
import { materialTableDataQuery } from '../../lib/helpers';
import CreateUrlGroup from '../../components/SitemapWorkbook/CreateUrlGroup';
import UrlGroupPatterns from '../../components/SitemapWorkbook/UrlGroupPatterns';


const STATUS_CODE_2XX = '2xx';
const STATUS_CODE_3XX = '3xx';
const STATUS_CODE_4XX = '4xx';
const STATUS_CODE_5XX = '5xx';

const CANONICAL_IS_URL = 'Same as URL';
const CANONICAL_NOT_URL = 'Not Same as URL';
const CANONICAL_EMPTY = 'Empty';
const CANONICAL_NOT_EMPTY = 'Not Empty';

const ROBOT_NO_INDEX = 'noindex';
const ROBOT_NO_FOLLOW = 'nofollow';

const TITLE_IS_H1 = 'Same as H1';
const TITLE_EMPTY = 'Empty';

const HEADING_IS_EMPTY = 'Empty';
const HEADING_MULTIPLE = 'Multiple';

const allFields = [
  'url',
  'redirectUrl',
  'finalUrl',
  'acquisitionDate',
  'responseTime',
  'statusCode',
  'responseStatusCode',
  'historyStatusCode',
  'finalStatusCode',
  'contentType',
  'lang',
  'date',
  'pageSize',
  'finalPageSize',
  'domain',
  'metaRobot',
  'metaKw',
  'metaDesc',
  'metaRefreshed',
  'linkCanonicalUrl',
  'relNext',
  'relPrevious',
  'relAlternateMobile',
  'title',
  'allH1s',
  'numH1s',
  'allH2s',
  'numH2s',
  'allH3s',
  'numH3s',
  'allH4s',
  'numH4s',
  'allH5s',
  'numH5s',
  'allH6s',
  'numH6s',
  'relAlternateHrefLang',
  'ilsLinks',
  'bodyClass',
  'breadcrumbs',
  'dataFeed',
];

const visibleFields = [
  'url',
  'title',
  'allH1s',
  'dataFeed',
];

const filters = {};

const lookups = {};

const statusCodeFilters = {};
statusCodeFilters[STATUS_CODE_2XX] = [200];
statusCodeFilters[STATUS_CODE_3XX] = [301, 302];
statusCodeFilters[STATUS_CODE_4XX] = [400, 401, 402, 404];
statusCodeFilters[STATUS_CODE_5XX] = [500, 501, 502];

const canonicalFilters = {};
canonicalFilters[CANONICAL_IS_URL] = { field: 'url', value: 'link_canonical_url', op: 'same' };
canonicalFilters[CANONICAL_NOT_URL] = { field: 'url', value: 'link_canonical_url', op: 'not_same' };
canonicalFilters[CANONICAL_EMPTY] = { field: 'link_canonical_url', value: '', op: '==' };
canonicalFilters[CANONICAL_NOT_EMPTY] = { field: 'link_canonical_url', value: '', op: '!=' };

const metaRobotFilters = {};
metaRobotFilters[ROBOT_NO_INDEX] = { field: 'meta_robot', value: '%noindex%', op: 'like' };
metaRobotFilters[ROBOT_NO_FOLLOW] = { field: 'meta_robot', value: '%nofollow%', op: 'like' };

const titleFilters = {};
titleFilters[TITLE_IS_H1] = { field: 'title', value: 'all_h1s[1]', op: 'same' };
titleFilters[TITLE_EMPTY] = { field: 'title', value: '', op: '==' };

const h1Filters = {};
h1Filters[HEADING_IS_EMPTY] = { field: 'num_h1s', value: 1, op: 'lt' };
h1Filters[HEADING_MULTIPLE] = { field: 'num_h1s', value: 1, op: 'gt' };

const h2Filters = {};
h2Filters[HEADING_IS_EMPTY] = { field: 'num_h2s', value: 1, op: 'lt' };
h2Filters[HEADING_MULTIPLE] = { field: 'num_h2s', value: 1, op: 'gt' };

const h3Filters = {};
h3Filters[HEADING_IS_EMPTY] = { field: 'num_h3s', value: 1, op: 'lt' };
h3Filters[HEADING_MULTIPLE] = { field: 'num_h3s', value: 1, op: 'gt' };

const h4Filters = {};
h4Filters[HEADING_IS_EMPTY] = { field: 'num_h4s', value: 1, op: 'lt' };
h4Filters[HEADING_MULTIPLE] = { field: 'num_h4s', value: 1, op: 'gt' };

const h5Filters = {};
h5Filters[HEADING_IS_EMPTY] = { field: 'num_h5s', value: 1, op: 'lt' };
h5Filters[HEADING_MULTIPLE] = { field: 'num_h5s', value: 1, op: 'gt' };

const h6Filters = {};
h6Filters[HEADING_IS_EMPTY] = { field: 'num_h6s', value: 1, op: 'lt' };
h6Filters[HEADING_MULTIPLE] = { field: 'num_h6s', value: 1, op: 'gt' };

const groupFilters = {
  statusCode: statusCodeFilters,
};
const sqlFilters = {
  linkCanonicalUrl: canonicalFilters,
  metaRobot: metaRobotFilters,
  title: titleFilters,
  allH1s: h1Filters,
  allH2s: h2Filters,
  allH3s: h3Filters,
  allH4s: h4Filters,
  allH5s: h5Filters,
  allH6s: h6Filters,
};
const filterSpacers = {
  linkCanonicalUrl: [CANONICAL_EMPTY],
};


const columns = allFields.map((field) => {
  const hidden = !visibleFields.includes(field);
  const fieldProps = { title: field, field, hidden };

  if (field in filters) {
    fieldProps.customFilterAndSearch = filters[field];
  }

  if (field in lookups) {
    fieldProps.lookup = lookups[field];
  }

  if (field in groupFilters) {
    fieldProps.customFilter = (columnDef, onFilterChanged) => (
      <MTableGroupFilter
        values={groupFilters[field]}
        columnDef={columnDef}
        onFilterChanged={onFilterChanged}
      />
    );
  }

  if (field in sqlFilters) {
    fieldProps.filterType = 'sql';
    fieldProps.customFilter = (columnDef, onFilterChanged) => (
      <MTableGroupFilter
        values={sqlFilters[field]}
        columnDef={columnDef}
        onFilterChanged={onFilterChanged}
        spacers={filterSpacers[field]}
      />
    );
  }

  return fieldProps;
});


export default function ApproveUrls(props) {
  const { versionUuid, disableActions } = props;
  const pageSizeOptions = [10, 50, 100];
  const [pageSize, setPageSize] = useState(pageSizeOptions[0]);
  const [queryFilters, setQueryFilters] = useState([]);

  // TODO: Debug filter persistence. Filters do not clear when state is toggled in the UI.
  // console.log('queryFilters', queryFilters)

  const query = request => materialTableDataQuery(
    { ...request, page: -1 },
    VERSION_URL_METADATA_QUERY,
    { uuid: versionUuid, filters: queryFilters, excludeGroupedUrls: 'all' },
    result => ({
      data: result.data.version.urlMetadata.data,
      page: result.data.version.urlMetadata.pagination.pageNumber - 1,
      totalCount: result.data.version.urlMetadata.pagination.totalResults,
    }),
  );

  const tableRef = useRef(null);

  const handleFilterChange = (newFilters) => {
    setQueryFilters(newFilters.map(filter => (
      { ...filter, field: changeCase.snakeCase(filter.field) }
    )));
    tableRef.current.state.query.page = 0;
    tableRef.current.onQueryChange();
  };

  const [tabValue, setTabValue] = useState(0);

  function handleTabChange(event, newValue) {
    setTabValue(newValue);
  }

  return (
    <Container>
      <Box my={3}>
        <Paper square>
          <Tabs
            value={tabValue}
            indicatorColor="primary"
            textColor="primary"
            onChange={handleTabChange}
          >
            <Tab label="Urls" />
            <Tab label="Groups" />
          </Tabs>
        </Paper>
      </Box>
      <TabPanel value={tabValue} index={0}>
        {disableActions || 
          <CreateUrlGroup
            defaultField="url"
            onFilterChanged={handleFilterChange}
            versionUuid={versionUuid}
            fields={allFields}
          />
        }
        
        <Box my={2}>
          <MaterialTable
            tableRef={tableRef}
            title="Ungrouped Urls"
            columns={columns}
            data={query}
            options={{
              search: false,
              emptyRowsWhenPaging: false,
              pageSizeOptions,
              pageSize,
              debounceInterval: 400,
              headerStyle: {
                lineHeight: 1.5,
              },
              columnsButton: true,
              filtering: true,
              padding: 'dense',
            }}
            onChangeRowsPerPage={setPageSize}
            components={{
              Cell: cellProps => (
                <FormattedCell {...cellProps} style={{ whiteSpace: 'pre' }} />
              ),
              FilterRow: filterProps => (
                <MTableFilterRow {...filterProps} />
              ),
            }}
            localization={{
              pagination: {
                labelDisplayedRows: `{from}-{count} of SAMPLE(${pageSize}) URLs`,
              },
            }}
          />
        </Box>
      </TabPanel>
      <TabPanel value={tabValue} index={1}>
        <UrlGroupPatterns versionUuid={versionUuid} disableActions={disableActions} />
      </TabPanel>
    </Container>
  );
}

ApproveUrls.propTypes = {
  versionUuid: PropTypes.string.isRequired,
  disableActions: PropTypes.bool.isRequired,
};
