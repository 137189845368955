import React from 'react';
import PropTypes from 'prop-types';
import { useQuery } from '@apollo/react-hooks';
import { makeStyles } from '@material-ui/core/styles';
import MaterialTable from 'material-table';

import { VERSION_KEYWORD_ASSOCIATION_QUERY } from '../../schema/queries/versions';
import FormattedCell from '../material-table/FormattedCell';

const useStyles = makeStyles(theme => ({
  panel: {
    padding: '10px',
    backgroundColor: theme.palette.grey[200],
  },
}));


export default function KeywordAssociationTopUrls(props) {
  const classes = useStyles();
  const { versionUuid, groupId } = props;

  const { loading, error, data } = useQuery(VERSION_KEYWORD_ASSOCIATION_QUERY, {
    variables: {
      uuid: versionUuid,
      filters: [{'field': 'url_group_id', 'op': '==', 'value': groupId}],
    },
  });

  if (loading) {
    return <span>Loading...</span>;
  }

  if (error) {
    console.log(error);
    return <span>Error...</span>;
  }


  return (
  <div className={classes.panel}>
    <MaterialTable
      title="Top URL Assoc by Page Type"
      columns={[
        {
          title: 'URL Type',
          field: 'groupName',
        },
        {
          title: 'GA Visits',
          field: 'urlTotalSession',
          defaultSort: 'desc',
        },
        {
          title: 'URL',
          field: 'url',
        },
        {
          title: 'Keyword',
          field: 'keyword',
        },
        {
          title: 'Associator',
          field: 'associator',
        },
      ]}
      data={data.version.keywordAssociation.data}
      components={{
        Cell: properties => (
          <FormattedCell {...properties} />
        ),
      }}
      options={{
        padding: 'dense',
        emptyRowsWhenPaging: false,
        actionsColumnIndex: -1,
        pageSize: 10,
        pageSizeOptions: [10, 50, 500],
        exportButton: true,
        headerStyle: {
          lineHeight: 1.5,
        },
      }}
    />
	</div>
  );
}

KeywordAssociationTopUrls.propTypes = {
  versionUuid: PropTypes.string.isRequired,
  groupId: PropTypes.string.isRequired,
};
