import React from 'react';
import PropTypes from 'prop-types';

import { VERSION_URLS_QUERY } from '../../schema/queries/versions';
import UrlDataTable from '../tables/datatype/Url';
import { urlDataQuery } from '../../lib/helpers';


export default function AllUrls(props) {
  const { versionUuid } = props;

  return (
    <UrlDataTable
      exportFileName={versionUuid}
      query={request => urlDataQuery(
        request,
        VERSION_URLS_QUERY,
        { uuid: versionUuid },
        'version',
      )}
      hasPanel={false}
    />
  );
}

AllUrls.propTypes = {
  versionUuid: PropTypes.string.isRequired,
};
