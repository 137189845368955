import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { makeStyles } from '@material-ui/core/styles';
import Stepper from '@material-ui/core/Stepper';
import Step from '@material-ui/core/Step';
import StepLabel from '@material-ui/core/StepLabel';
import StepButton from '@material-ui/core/StepButton';

import Button from '@material-ui/core/Button';
import Typography from '@material-ui/core/Typography';
import Box from '@material-ui/core/Box';
import Alert from '@material-ui/lab/Alert';


const useStyles = makeStyles(theme => ({
  root: {
    width: '90%',
  },
  button: {
    marginRight: theme.spacing(1),
  },
  instructions: {
    marginTop: theme.spacing(1),
    marginBottom: theme.spacing(1),
  },
}));

export default function AbstractStepper(props) {
  const {
    steps,
    range,
    currentStep,
    getStepContent,
    handleSubmit,
    disableBack,
    disableNext,
    finalView,
    errors,
  } = props;
  const classes = useStyles();
  const [skipped, setSkipped] = useState(new Set());
  const [activeStep, setActiveStep] = useState(currentStep() > range[1] ? steps.length : currentStep() - range[0]);

  
  function isStepOptional(step) {
    return step === -1;
  }

  function isStepSkipped(step) {
    return skipped.has(step);
  }

  function handleNext() {

    // eslint-disable-next-line no-alert
    if (steps[activeStep].confirm && !window.confirm(steps[activeStep].confirm)) {
      return false;
    }

    let newSkipped = skipped;
    if (isStepSkipped(activeStep)) {
      newSkipped = new Set(newSkipped.values());
      newSkipped.delete(activeStep);
    }

    handleSubmit(activeStep);

    setActiveStep(prevActiveStep => prevActiveStep + 1);
    setSkipped(newSkipped);
  }

  function handleBack() {
    setActiveStep(prevActiveStep => prevActiveStep - 1);
  }

  function handleSkip() {
    if (!isStepOptional(activeStep)) {
      // You probably want to guard against something like this,
      // it should never occur unless someone's actively trying to break something.
      throw new Error("You can't skip a step that isn't optional.");
    }

    setActiveStep(prevActiveStep => prevActiveStep + 1);
    setSkipped((prevSkipped) => {
      const newSkipped = new Set(prevSkipped.values());
      newSkipped.add(activeStep);
      return newSkipped;
    });
  }

  function handleReset() {
    setActiveStep(0);
  }

  function handleStepChange(index) {
    setActiveStep(index);
  }

  return (
    <Box>
      <Stepper activeStep={activeStep} alternativeLabel>
        {steps.map((stepProps, index) => {
          const labelProps = {};
          if (isStepOptional(index)) {
            labelProps.optional = (
              <Typography variant="caption" align="center" display="block">
                Optional
              </Typography>
            );
          }
          if (isStepSkipped(index)) {
            stepProps.completed = false;
          }
          return (
            <Step key={stepProps.step}>
              <StepButton 
                onClick={() => handleStepChange(index)} 
                completed={stepProps.completed || stepProps.current}
                disabled={stepProps.wait || !(stepProps.completed || stepProps.current)}
              >
                {stepProps.label}
              </StepButton>
            </Step>
          );
        })}
      </Stepper>
      <div>
        {activeStep === steps.length ? (
          <div>
            {finalView}
          </div>
        ) : (
          <div>
            {errors.length
              ? errors.map(error => (
                <Box my={2}><Alert severity="error" key={error}>{error}</Alert></Box>
              ))
              : getStepContent(activeStep, props)
            }
            {(!errors.length && !steps[activeStep].wait && !steps[activeStep].completed)
            && (
              <Box m={3}>
                <Button
                  disabled={disableBack(activeStep)}
                  onClick={handleBack}
                  className={classes.button}
                >
                  Back
                </Button>
                {isStepOptional(activeStep) && (
                  <Button
                    variant="contained"
                    color="primary"
                    onClick={handleSkip}
                    className={classes.button}
                  >
                    Skip
                  </Button>
                )}

                <Button
                  variant="contained"
                  color="primary"
                  onClick={handleNext}
                  className={classes.button}
                  disabled={disableNext(activeStep)}
                >
                  {activeStep === steps.length - 1 ? 'Finish' : 'Next'}
                </Button>
              </Box>
            )}
          </div>
        )}
      </div>
    </Box>
  );
}

AbstractStepper.propTypes = {
  steps: PropTypes.arrayOf(PropTypes.object).isRequired,
  range: PropTypes.arrayOf(PropTypes.number),
  currentStep: PropTypes.func.isRequired,
  getStepContent: PropTypes.func.isRequired,
  handleSubmit: PropTypes.func.isRequired,
  disableBack: PropTypes.func,
  disableNext: PropTypes.func,
  finalView: PropTypes.element,
  errors: PropTypes.arrayOf(PropTypes.string),
};

AbstractStepper.defaultProps = {
  disableBack: () => true,
  disableNext: () => false,
  finalView: <span />,
  errors: [],
  range: [],
};
