import gql from 'graphql-tag';

const ADD_USER_MUTATION = gql`
mutation addUser(
  $username: String!) {
    addUser(args: {
      username: $username
    }){
      username
  }
}
`;

const ADD_USER_TO_GROUP_MUTATION = gql`
mutation addUserToGroup(
  $username: String!,
  $group: String!) {
    addUserToGroup(args: {
      username: $username,
      group: $group
    }){
      username
      group
  }
}
`;

const DELETE_USER_MUTATION = gql`
mutation deleteUser(
  $username: String!) {
    deleteUser(args: {
      username: $username
    }){
      username
  }
}
`;

const REMOVE_USER_FROM_GROUP_MUTATION = gql`
mutation removeUserFromGroup(
  $username: String!,
  $group: String!) {
    removeUserFromGroup(args: {
      username: $username,
      group: $group
    }){
      username
      group
  }
}
`;

export { 
  ADD_USER_MUTATION,
  ADD_USER_TO_GROUP_MUTATION,
  DELETE_USER_MUTATION,
  REMOVE_USER_FROM_GROUP_MUTATION 
};
