import React from 'react';
import { useQuery } from '@apollo/react-hooks';
import PropTypes from 'prop-types';
import { makeStyles, withStyles } from '@material-ui/core/styles';
import FormControl from '@material-ui/core/FormControl';
import Select from '@material-ui/core/Select';
import MenuItem from '@material-ui/core/MenuItem';
import InputBase from '@material-ui/core/InputBase';
import Box from '@material-ui/core/Box';

import { PROJECTS_QUERY } from '../../schema/queries/projects';

const useStyles = makeStyles(() => ({
  root: {
    borderBottom: '1px solid white',
  },
  icon: {
    fill: 'white',
  },
}));

const StyledInput = withStyles(theme => ({
  root: {
    'label + &': {
      marginTop: theme.spacing(3),
    },
  },
  input: {
    backgroundColor: 'rgba(255, 255, 255, 0.15)',
    border: 'none',
    color: 'white',
    padding: '10px 14px 10px 4px',
    borderBottom: '1px solid white',
    width: '150px',

    '&:focus': {
      color: 'white',
      backgroundColor: 'rgba(255, 255, 255, 0.15)',
    },
  },
}))(InputBase);

const ProjectSelect = (props) => {
  const { assetId, onChange } = props;
  const classes = useStyles();
  const { data, loading } = useQuery(PROJECTS_QUERY, {
    variables: {},
  });


  if (loading) {
    return <span>Loading...</span>;
  }

  return (
    <Box mx={2} style={{ float: 'left', padding: '4px 0' }}>
      <form>
        <FormControl>
          <Select
            onChange={onChange}
            value={assetId}
            inputProps={{
              name: 'selectedProject',
              id: 'selected-project',
              classes: {
                root: classes.border,
                icon: classes.icon,
              },
            }}
            displayEmpty
            input={<StyledInput name="project" id="project-customized-select" />}
          >
            <MenuItem value="" disabled>Select a Project</MenuItem>
            {data.allProjects.edges.map(
              ({ node }) => (
                <MenuItem key={node.assetId} value={node.assetId}>
                  {node.name}
                </MenuItem>
              ),
            )}
          </Select>
        </FormControl>
      </form>
    </Box>
  );
};

export default ProjectSelect;


ProjectSelect.propTypes = {
  onChange: PropTypes.func,
  assetId: PropTypes.string,
};

ProjectSelect.defaultProps = {
  onChange: () => {},
  assetId: '',
};
