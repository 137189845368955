import React, { useState } from 'react';

import AccountCircle from '@material-ui/icons/AccountCircle';
import Menu from '@material-ui/core/Menu';
import MenuItem from '@material-ui/core/MenuItem';
import IconButton from '@material-ui/core/IconButton';
import Tooltip from '@material-ui/core/Tooltip';

import { getSession, terminateSession } from '../session';
import config from '../config'
import User from '../containers/User'


export default () => {
  const { user } = getSession();
  const [open, setOpen] = useState(false);
  const [action, setAction] = useState(false);

  const [anchorEl, setAnchorEl] = useState(null);

  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };
  
  function handleUserOpen() {
    setOpen(true);
    setAction(true);
    setAnchorEl(null);
  }
  function handleUserClose() {
    setOpen(false);
  }

  function handleLogout() {
    fetch(`${config.host}/logout`, {credentials: 'include'})
      .then(res => res.json())
      .then(
        (result) => {
          terminateSession(result.logoutUrl);
        },
        (error) => {
          console.error(error);
          terminateSession();
        }
      )
  }

  return (
    <React.Fragment>
      <Tooltip title="My Account">    
        <IconButton color="inherit" aria-controls="simple-menu" aria-haspopup="true" onClick={handleClick}>
          <AccountCircle />
        </IconButton>
      </Tooltip>
      <Menu
        id="simple-menu"
        anchorEl={anchorEl}
        keepMounted
        open={Boolean(anchorEl)}
        onClose={handleClose}
      >
        <MenuItem disabled>{user}</MenuItem>
        <MenuItem onClick={handleUserOpen}>Manage Users</MenuItem>
        <MenuItem onClick={handleLogout}>Logout</MenuItem>
      </Menu>
    <User
      open={open}
      handleClose={handleUserClose}
      action={action}
    />
    </React.Fragment>
  );
}
