import { ApolloClient } from 'apollo-client';
import { InMemoryCache } from 'apollo-cache-inmemory';
import { onError } from 'apollo-link-error';
import { ApolloLink } from 'apollo-link';
import { createUploadLink } from 'apollo-upload-client';

import config from '../config';
import { terminateSession } from '../session'

const cache = new InMemoryCache();
const httpLink = ApolloLink.from([
  onError(({ graphQLErrors, networkError, response }) => {
    
    if (graphQLErrors) {
      graphQLErrors.forEach(({ message, locations, path }) =>
        console.log(
          `[GraphQL error]: Message: ${message}, Location: ${locations}, Path: ${path}`,
        ),
      );
    }
    
    if (networkError) {
      console.log('response', response)
      console.log(`[Network error]: ${networkError}`);
      console.log('networkError.result', networkError.result)
      if (networkError.statusCode === 401) {
        terminateSession(networkError.result.authUrl || null);
      }
    }

  }),
  createUploadLink({
    uri: config.graphqlHostname,
    credentials: 'include',
  }),
]);

const apolloClient = new ApolloClient({
  link: httpLink,
  cache,
  resolvers: {},
  fetchOptions: {
    mode: 'no-cors'
  },
});

export default apolloClient;
