import { MTableFilterRow } from 'material-table';

class TableFilterRow extends MTableFilterRow {
  /**
   * @param {object} columnDef
   * Extend original getComponentForColumn to add support for additional filter UIs
   */
  getComponentForColumn(columnDef) {
    if (columnDef.filtering === false) {
      return null;
    }

    if (columnDef.customFilter) {
      return columnDef.customFilter(columnDef, this.props.onFilterChanged);
    }

    return super.getComponentForColumn(columnDef);
  }
}

export default TableFilterRow;
