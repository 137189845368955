import React from 'react';
import PropTypes from 'prop-types';
import { useQuery } from '@apollo/react-hooks';
import MaterialTable from 'material-table';

import AddNote from '../components/forms/AddNote';
import { PROJECT_NOTES_QUERY } from '../schema/queries/projects';
import { showSnackbar } from '../components/Snackbar';
import FormattedCell from '../components/material-table/FormattedCell';


const Notes = (props) => {
  const { assetId } = props;
  const [open, setOpen] = React.useState(false);
  function handleClickOpen() {
    setOpen(true);
  }
  function handleClose() {
    setOpen(false);
    refetch();
  }
  const { data, loading, refetch, error } = useQuery(PROJECT_NOTES_QUERY, {
    variables: {
      assetId,
    },
  });


  if (loading) {
    return <span>Loading...</span>;
  }

  if (error) {
    return <span>Unable to load data.</span>;
  }

  return (
  <div className="notes">
    <MaterialTable
      title="Notes"
      columns={[
        {
          title: 'Context',
          render: (rowData) => {
            return `${rowData.context}: ${rowData.name}`;
          },
        },
        {
          title: 'Note',
          field: 'text',
        },
        {
          title: 'Date',
          field: 'createdAt',
          type: 'datetime',
          defaultSort: 'desc',
        },
      ]}
      data={data.project.notes.map(rowData => (Object.assign({name: rowData.fileName || rowData.versionName || assetId}, rowData)))}
      options={{
        emptyRowsWhenPaging: false,
      }}
      actions={[
        {
          icon: 'add',
          tooltip: 'Add Note',
          position: 'toolbar',
          onClick: handleClickOpen,
        },
      ]}
      components={{
        Cell: properties => (
          <FormattedCell {...properties} />
        ),
      }}
    />
    <AddNote
      open={open}
      handleClose={handleClose}
      values={{
        assetId,
        context: 'project',
        note: '',
      }}
    />
  </div>
  );
};

export default Notes;

Notes.propTypes = {
  assetId: PropTypes.string,
};

Notes.defaultProps = {
  assetId: '',
};
