import React from 'react';
import PropTypes from 'prop-types';
import { MTableAction } from 'material-table';

const MTableActionRendered = (props) => {
  let { action } = props;
  const { data } = props;

  if (typeof action === 'function') {
    action = action(data);
    if (!action) {
      return null;
    }
  }

  if (action.action) {
    return action.action(data);
  }
  
  // // Workaround based on feedback from the debugger, said this should be a class and
  // // extend React.Component, so instead of changing this to a class, added this.
  // MTableActionRendered.prototype = React.Component.prototype

  return <MTableAction {...props} />;
};

export default MTableActionRendered;

MTableActionRendered.propTypes = {
  action: PropTypes.oneOfType([PropTypes.object, PropTypes.func]),
  data: PropTypes.object,
};

MTableActionRendered.defaultProps = {
  action: {},
  data: {},
};
