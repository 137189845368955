import gql from 'graphql-tag';

const KEYWORD_GROUP_QUERY = gql`
query getUrlGroupKeywords(
    $groupId: String!,
    $filters: [FilterInput], 
    $sort: [SortInput]
    $pageNumber: Int
    $pageSize: Int
  ) {
  urlGroup(groupId: $groupId){
    keywords(
      filters: $filters,
      sort: $sort,
      pageNumber: $pageNumber,
      pageSize: $pageSize
    ){
      data{
        keyword
        url
      }
      pagination{
        totalResults
        numPages
        pageSize
        pageNumber
      }
    }
  }
}
`;


export {
  KEYWORD_GROUP_QUERY,
};
