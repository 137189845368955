import React, { useState, useRef } from 'react';
import PropTypes from 'prop-types';
import MaterialTable from 'material-table';
import { makeStyles } from '@material-ui/core/styles';
import Paper from '@material-ui/core/Paper';
import { CsvBuilder } from 'filefy';

import FormattedCell from '../../material-table/FormattedCell';
import MTableActionRendered from '../../material-table/m-table-action-rendered';
import { URL_DATA_QUERY, USER_URL_KEYWORD_DATA_QUERY } from '../../../schema/queries/files';
import ExportSwitch from '../../ExportSwitch';
import { urlDataQuery, userUrlKeywordDataQuery } from '../../../lib/helpers';

const useStyles = makeStyles(theme => ({
  panel: {
    padding: '10px',
    backgroundColor: theme.palette.grey[200],
  },
}));


const UrlDataTable = (props) => {
  const classes = useStyles();
  const {
    file,
    exportFileName,
    hasPanel,
    localization: localizationProps,
    options: optionProps,
  } = props;
  const paperProps = hasPanel ? { elevation: 0 } : {};

  const [state, setState] = useState({
    exportAllData: false,
    pageSize: 5,
  });

  const tableRef = useRef(null);

  const handleExportModeChange = (event) => {
    setState({
      ...state,
      exportAllData: event.target.checked,
    });
  };

  const handleChangeRowsPerPage = (pageSize) => {
    setState({
      ...state,
      pageSize,
    });
  };

  const fileQuery = request => 
  file.type === 'URL_KEYWORD' ? 
  userUrlKeywordDataQuery(
    request,
    USER_URL_KEYWORD_DATA_QUERY,
    { id: file.id },
    'file',
  ) : 
  urlDataQuery(
    request,
    URL_DATA_QUERY,
    { id: file.id },
    'file',
  );
  const query = props.query || fileQuery;
  const queryAll = request => query({
    ...request,
    page: 0,
    pageSize: request.totalCount,
  });

  const exportCsv = async () => {
    const {
      props: tableProps,
      props: { options },
      state: { query: request, renderData },
      dataManager,
    } = tableRef.current;

    const columns = tableProps.columns
      .filter((columnDef) => {
        return !columnDef.hidden && columnDef.field && columnDef.export !== false;
      })
      .sort((a, b) => ((a.tableData.columnOrder > b.tableData.columnOrder) ? 1 : -1));

    const pageSize = options.exportAllData
      ? request.totalCount
      : request.pageSize;
    const startIndex = request.page * pageSize;
    const endIndex = startIndex + pageSize;
    const clauses = [`${startIndex + 1}-${endIndex} of ${request.totalCount}`];
    let dataToExport;

    if (request.orderBy && 'field' in request.orderBy) {
      clauses.push(`orderBy=${request.orderBy.field},${request.orderDirection}`);
    }
    if (request.search) {
      clauses.push(`search=${request.search}`);
    }

    if (options.exportAllData) {
      dataToExport = await queryAll(request);
      dataToExport = dataToExport.data;
    } else {
      dataToExport = renderData;
    }

    const data = dataToExport.map(rowData => (
      columns.map(columnDef => dataManager.getFieldValue(rowData, columnDef))
    ));

    const builder = new CsvBuilder((options.exportFileName || tableProps.title || 'data') + `[${clauses.join(',')}].csv`);
    builder
      .setDelimeter(tableProps.exportDelimiter)
      .setColumns(columns.map(columnDef => columnDef.title))
      .addRows(data)
      .exportFile();
  };

  const localization = {
    pagination: {
      labelDisplayedRows: '{from}-{to} of {count} URLs',
    },
  };

  const options = {
    search: true,
    emptyRowsWhenPaging: false,
    exportButton: true,
    exportAllData: state.exportAllData,
    padding: 'dense',
    pageSizeOptions: [5, 50, 500],
    exportFileName: exportFileName || file.source || 'urls',
    pageSize: state.pageSize,
    debounceInterval: 400,
    exportCsv,
    headerStyle: {
      lineHeight: 1.5,
    },
  };

  return (
    <div className={hasPanel ? classes.panel : ''}>
      <MaterialTable
        title="Urls"
        tableRef={tableRef}
        columns={[
          { title: 'Url', field: 'url' },
          file && file.type === 'URL_KEYWORD' ?
          { title: 'Keyword', field: 'keyword'} :
          { title: 'Data Feed', field: 'dataFeed', sorting: false },
        ]}
        data={query}
        options={{ ...options, ...optionProps }}
        components={{
          Container: containerProps => (
            <Paper {...paperProps} {...containerProps} />
          ),
          Cell: cellProps => (
            <FormattedCell {...cellProps} />
          ),
          Action: actionProps => (
            <MTableActionRendered {...actionProps} />
          ),
        }}
        localization={{ ...localization, ...localizationProps }}
        actions={[
          {
            position: 'toolbar',
            action: () => (
              <ExportSwitch
                tooltip={['Export Mode: Visible', 'Export Mode: All']}
                onChange={handleExportModeChange}
                checked={state.exportAllData}
              />
            ),
          },
        ]}
        onChangeRowsPerPage={handleChangeRowsPerPage}
      />
    </div>
  );
};

export default UrlDataTable;

UrlDataTable.propTypes = {
  file: PropTypes.object,
  query: PropTypes.func,
  exportFileName: PropTypes.string,
  hasPanel: PropTypes.bool,
  localization: PropTypes.object,
  options: PropTypes.object,
};

UrlDataTable.defaultProps = {
  file: undefined,
  query: undefined,
  exportFileName: '',
  hasPanel: true,
  localization: {},
  options: {},
};
