import gql from 'graphql-tag';

import { File } from '../queries/files';

const VERSION_CREATE = gql`
  mutation VersionCreate($name: String!, $assetId: String!, $note: String) {
    createVersion(args: {name: $name, assetId: $assetId, note: $note}) {
      version{
        name
        id
        uuid
      }
    }
  }
`;

const SET_VERSION_FILES = gql`
mutation setVersionFiles($uuid: String!, $fileIds: [ID!]){
  setVersionFiles(args:{
    uuid: $uuid,
    fileIds:$fileIds
  }){
    version{
      urlFiles{
        edges{
          node{
            ...FileFields
          }
        }
      }
    }
  }
}
${File.fragments.fields}
`;

const SET_VERSION_URL_KEYWORD_FILES = gql`
mutation setVersionUrlKeywordFiles($uuid: String!, $fileIds: [ID!]){
  setVersionUrlKeywordFiles(args:{
    uuid: $uuid,
    fileIds:$fileIds
  }){
    version{
      urlKeywordFiles{
        edges{
          node{
            ...FileFields
          }
        }
      }
    }
  }
}
${File.fragments.fields}
`;

const DELETE_VERSION_FILES = gql`
mutation deleteVersionFiles($uuid: String!, $fileIds: [ID!]){
  deleteVersionFiles(args:{
    uuid: $uuid,
    fileIds:$fileIds
  }){
    version{
      urlFiles{
        edges{
          node{
            id
          }
        }
      }
    }
  }
}
`;

const DELETE_VERSION_URL_KEYWORD_FILES = gql`
mutation deleteVersionUrlKeywordFiles($uuid: String!, $fileIds: [ID!]){
  deleteVersionUrlKeywordFiles(args:{
    uuid: $uuid,
    fileIds:$fileIds
  }){
    version{
      urlKeywordFiles{
        edges{
          node{
            id
          }
        }
      }
    }
  }
}
`;

const TOGGLE_VERSION_EXCLUDE_ROOT = gql`
mutation toggleVersionExcludeRoot($uuid: String!, $root: String!){
  toggleVersionExcludeRoot(args:{
    uuid: $uuid,
    root: $root
  }){
    version{
      uuid
      excludeRoot
    }
  }
}
`;

const TOGGLE_VERSION_EXCLUDE_FILE = gql`
mutation toggleVersionExcludeFile($uuid: String!, $fileId: Int!){
  toggleVersionExcludeFile(args:{
    uuid: $uuid,
    fileId: $fileId
  }){
    version{
      uuid
      excludeFiles
    }
  }
}
`;

const COMPLETE_VERSION_STAGE = gql`
mutation completeVersionStage($uuid: String!, $stage: String!){
  completeVersionStage(args:{
    uuid: $uuid,
    stage: $stage
  }){
    version{
      uuid
      completedStages
    }
  }
}
`;

export {
  VERSION_CREATE,
  SET_VERSION_FILES,
  SET_VERSION_URL_KEYWORD_FILES,
  DELETE_VERSION_FILES,
  DELETE_VERSION_URL_KEYWORD_FILES,
  TOGGLE_VERSION_EXCLUDE_ROOT,
  TOGGLE_VERSION_EXCLUDE_FILE,
  COMPLETE_VERSION_STAGE,
};
