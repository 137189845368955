import React from 'react';
import PropTypes from 'prop-types';
import { Switch, Tooltip } from '@material-ui/core';

export default function ExportSwitch(props) {
  const { tooltip, checked, onChange } = props;

  return (
    <Tooltip title={tooltip[checked ? 1 : 0]}>
      <Switch
        size="small"
        color="default"
        checked={checked}
        onChange={onChange}
      />
    </Tooltip>
  );
}

ExportSwitch.propTypes = {
  tooltip: PropTypes.arrayOf(PropTypes.string),
  onChange: PropTypes.func.isRequired,
  checked: PropTypes.bool,
};

ExportSwitch.defaultProps = {
  tooltip: ['Visible', 'All'],
  checked: false,
};
