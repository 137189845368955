import gql from 'graphql-tag';

const URL_GROUP_QUERY = gql`
query getUrlGroup($groupId: String!){
  urlGroup(groupId: $groupId){
    id
    versionUuid
    name
    type
    dataFeedLabels
  }
}
`;

const URL_GROUPS_QUERY = gql`
query urlGroups($versionUuid: String!){
  urlGroups(versionUuid: $versionUuid){
    id
    name
    priority
    generationPriority
    type
    rules{
      field
      index
      pattern
      patternType
      context
    }
  }
}
`;


export {
  URL_GROUP_QUERY,
  URL_GROUPS_QUERY,
};
