import React, { useRef, useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import MaterialTable from 'material-table';
import Icon from '@material-ui/core/Icon';

import { COLUMN_DATE_ADDED, COLUMN_NAME } from '../../constants';

import FILES_QUERY from '../../schema/queries/files';
import NotesDataTable from './datatype/Note';
import { materialTableDataQuery } from '../../lib/helpers';
import FormattedCell from '../material-table/FormattedCell';
import UrlDataTable from './datatype/Url';


function renderDataTypeView(file) {
  switch (file.datatype) {
    case 'URL':
      return <UrlDataTable file={file} />;
    default:
      return <span>No rendered view available for this datatype.</span>;
  }
}

function renderNote(file) {
  return <NotesDataTable assetId={file.assetId} context='file' contextId={file.oid} />;
}

const UrlKeywordFilesTable = (props) => {
  const {
    assetId,
    options,
    actions,
    onSelectionChange,
    components,
    initialSelected,
    filters,
  } = props;

  let { title } = props;

  const [pageSize, setPageSize] = useState(5);
  const [selected, setSelected] = useState(initialSelected);
  const tableRef = useRef(null);

  const handleSelectionChange = (newSelection) => {
    // Start with current selection
    // subtract all visible (checked or not)
    // add new selected
    const selection = [...new Set(newSelection.map(file => file.id))];
    const shown = tableRef.current.state.originalData.map(file => file.id);
    const allSelected = selected.filter(id => !shown.includes(id)).concat(selection);

    setSelected(allSelected);
    onSelectionChange(allSelected);
  };

  // Force re-fetch when assetId changes
  useEffect(() => {
    tableRef.current.onQueryChange();
    handleSelectionChange([]);
  }, [assetId]);

  if (typeof title === 'function') {
    title = title({ selected });
  }

  return (
    <MaterialTable
      tableRef={tableRef}
      title={title}
      components={{
        ...components,
        Cell: cellProps => (
          <FormattedCell {...cellProps} />
        ),
      }}
      options={{ ...options, pageSize, showTextRowsSelected: false }}
      actions={actions}
      onSelectionChange={handleSelectionChange}
      onChangeRowsPerPage={setPageSize}
      columns={[
        {
          title: 'ID',
          field: 'id',
          type: 'string',
          hidden: true,
        },
        {
          title: COLUMN_NAME,
          field: 'name',
          filtering: false,
        },
        {
          title: 'Source',
          field: 'url',
          type: 'string',
        },
        {
          title: COLUMN_DATE_ADDED,
          field: 'createdAt',
          type: 'date',
          defaultSort: 'desc',
        },
      ]}
      parentChildData={(row, rows) => rows.find(a => a.oid === row.parentId)}
      data={request => materialTableDataQuery(
        request,
        FILES_QUERY,
        { assetId, filters },
        result => ({
          data: result.data.project.files.data.map(file => (
            selected.includes(file.id)
              ? Object.assign({ tableData: { checked: true } }, file)
              : file
          )),
          page: result.data.project.files.pagination.pageNumber - 1,
          totalCount: result.data.project.files.pagination.totalResults,
        }),
        ['name', 'source'],
      )}
      detailPanel={[
        {
          render: renderDataTypeView,
          icon: 'list',
          openIcon: () => (<Icon color="primary">list</Icon>),
          tooltip: 'Toggle Data View',
        },
        {
          render: renderNote,
          icon: 'note',
          tooltip: 'Notes',
        },
      ]}
      localization={{
        pagination: {
          labelDisplayedRows: '{from}-{to} of {count} Files',
        },
      }}
    />
  );
};


export default UrlKeywordFilesTable;

UrlKeywordFilesTable.propTypes = {
  title: PropTypes.oneOfType([PropTypes.string, PropTypes.func]),
  assetId: PropTypes.string,
  actions: PropTypes.array,
  options: PropTypes.object,
  onSelectionChange: PropTypes.func,
  components: PropTypes.object,
  initialSelected: PropTypes.arrayOf(PropTypes.string),
  filters: PropTypes.arrayOf(PropTypes.object),
};

UrlKeywordFilesTable.defaultProps = {
  title: 'Project Files',
  assetId: '',
  actions: [],
  options: {},
  onSelectionChange: () => {},
  components: {},
  initialSelected: [],
  filters: [],
};
