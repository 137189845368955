import React from 'react';
import PropTypes from 'prop-types';
import { useMutation } from '@apollo/react-hooks';

import TextField from '@material-ui/core/TextField';
import { IconButton } from '@material-ui/core';
import SaveIcon from '@material-ui/icons/Save';
import {
  Formik, ErrorMessage, Form, Field,
} from 'formik';
import * as Yup from 'yup';


import { URL_GROUP_GENERATION_PRIORITY_MUTATION } from '../../schema/mutations/url_groups';

const FormSchema = Yup.object().shape({
  priority: Yup.number('Generation priority must be a positive number.')
    .required('Generation Priority is required.')
    .positive('Generation priority must be a positive number.'),
});

const GenerationPriority = (props) => {
  const { groupId, priority, readOnly } = props;
  const [updatePriority] = useMutation(URL_GROUP_GENERATION_PRIORITY_MUTATION);

  function save(values) {
    updatePriority({
      variables: { groupId, generationPriority: values.priority },
    });
  }

  return (
    readOnly ? (
      <span>{priority}</span>
    ) : (
      <Formik
        enableReinitialize
        initialValues={{
          priority,
        }}
        validationSchema={FormSchema}
        onSubmit={save}
        render={(formProps) => {
          const {
            values,
            touched,
            errors,
            handleChange,
            handleBlur,
            submitForm,
            handleSubmit,
          } = formProps;
          return (
            <Form>
              <TextField
                type="text"
                name="priority"
                value={values.priority}
                placeholder="Generation Priority"
                InputProps={{
                  endAdornment: <IconButton disabled={values.priority === priority} onClick={submitForm}><SaveIcon /></IconButton>,
                }}
                style={{
                  minWidth: 200,
                }}
                onChange={handleChange}
                onBlur={handleBlur}
                helperText={(errors.priority && touched.priority) && errors.priority}
                error={!!errors.priority}
              />
            </Form>
          );
        }}
      />
    )
  );
};

export default GenerationPriority;


GenerationPriority.propTypes = {
  groupId: PropTypes.string.isRequired,
  priority: PropTypes.string.isRequired,
  readOnly: PropTypes.bool,
};

GenerationPriority.defaultProps = {
  readOnly: false,
};
