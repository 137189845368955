import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { useMutation, useQuery } from '@apollo/react-hooks';
import Container from '@material-ui/core/Container';


import AssignKeywordTemplates from './KeywordWorkbook/AssignKeywordTemplates';
import AddUrlKeywordFiles from './KeywordWorkbook/AddUrlKeywordFiles';
import KeywordAssociation from './KeywordWorkbook/KeywordAssociation';
import Stepper from '../components/Stepper';
import { COMPLETE_VERSION_STAGE, SET_VERSION_URL_KEYWORD_FILES } from '../schema/mutations/Version';
import VERSION_QUERY from '../schema/queries/versions';
import { getWorkbookProgress } from '../lib/helpers';
import RemoteProcessWaiter from '../components/RemoteProcessWaiter';
import KeywordGroups from '../components/KeywordWorkbook/KeywordGroups';


export default function KeywordWorkbook(props) {
  const { assetId, versionUuid } = props;
  const [versionStageMutation] = useMutation(COMPLETE_VERSION_STAGE);
  const [versionFilesMutation] = useMutation(SET_VERSION_URL_KEYWORD_FILES);
  const [files, setFiles] = useState([]);
  function handleSelectionChange(selectedFiles) {
    setFiles([...new Set(selectedFiles)]);
  }
  const { loading, error, data } = useQuery(VERSION_QUERY, {
    variables: {
      uuid: versionUuid,
    },
  });


  if (loading) {
    return <span>Loading...</span>;
  }

  if (error) {
    console.log(error);
  }

  const range = [7, 12];
  const progress = getWorkbookProgress(data.version.completedStages, range);
  const steps = progress.steps;
  const getCurrentStep = () => progress.currentStep;

  async function handleNext(activeStep) {
    switch (activeStep) {
      case 0:
        await versionStageMutation({
          variables: {
            uuid: versionUuid,
            stage: steps[activeStep].step,
          },
        });
      break;
	  
      case 1:
        if (files && files.length) {
          await versionFilesMutation({
            variables: {
              uuid: versionUuid,
              fileIds: files,
            },
          });
        }
        await versionStageMutation({
          variables: {
            uuid: versionUuid,
            stage: steps[activeStep].step,
          },
        });
      break;

      case 3:
        await versionStageMutation({
          variables: {
            uuid: versionUuid,
            stage: steps[activeStep].step,
          },
        });
      break;
      
      case 5:
        await versionStageMutation({
          variables: {
            uuid: versionUuid,
            stage: steps[activeStep].step,
          },
        });
      break;

      default:
        // do nothing
    }
  }


  function getStepContent(step) {
    switch (step) {
      case 0:
        return <AssignKeywordTemplates versionUuid={versionUuid} assetId={assetId} handleSelectionChange={handleSelectionChange} />;
      case 1:
        return <AddUrlKeywordFiles versionUuid={versionUuid} assetId={assetId} handleSelectionChange={handleSelectionChange} readOnly={progress.currentStep > 9} />;
      case 2:
        return (
          <RemoteProcessWaiter
            message="Waiting for Keyword Generation process to complete."
          />
        );
      case 3:
        return <KeywordGroups versionUuid={versionUuid} />;
      case 4:
        return (
          <RemoteProcessWaiter
            message="Waiting for Keyword Association process to complete."
          />
        );
      case 5:
        return <KeywordAssociation versionUuid={versionUuid} assetId={assetId}/>;

      default:
        return 'Unknown step';
    }
  }
  
  
  const disableBack = (step) => {
    switch (step) {
      case 1:
        return false;
      default:
        return true;
    }
  };

  return (
    <Container maxWidth="lg">
      <Stepper
        steps={steps}
        range={range}
        currentStep={getCurrentStep}
        getStepContent={getStepContent}
        disableBack={disableBack}
        handleSubmit={handleNext}
      />
    </Container>
  );
}

KeywordWorkbook.propTypes = {
  assetId: PropTypes.string.isRequired,
  versionUuid: PropTypes.string.isRequired,
};
