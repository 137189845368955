import gql from 'graphql-tag';

const URL_GROUP_KEYWORD_TEMPLATES_QUERY = gql`
query getUrlGroupKeywordTemplates($assetId: String!, $versionUuid:String!){
  project(assetId: $assetId){
    name
    createdAt
    lastUpdated
    keywordTemplates{
      id
      template
    }
  }
  version(uuid: $versionUuid){
    urlGroups{
      name
      id
      dataFeedLabels
      keywordTemplates{
        id
        template
        generationPriority
        factor
      }
    }
  }
}
`;

export {
  URL_GROUP_KEYWORD_TEMPLATES_QUERY,
};