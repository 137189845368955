import React from 'react';


const Home = () => (
  <React.Fragment>
      <h1>Home Page</h1>
      Some content goes here.
  </React.Fragment>
);

export default Home;
