import React from 'react';
import { BrowserRouter, Route, Switch } from 'react-router-dom';

import Home from './Home';
import Ils from './Ils';
import OauthCallback from './OauthCallback'

const supportsHistory = 'pushState' in window.history;

export default () => (
  <BrowserRouter forceRefresh={!supportsHistory}>
    <Switch>
      <Route path="/" exact render={props => <Home {...props} />} />
      <Route path="/ils" exact render={props => <Ils {...props} />} />
      <Route path="/oauth_callback" exact render={props => <OauthCallback {...props} />} />
    </Switch>
  </BrowserRouter>
);
