import React from 'react';
import PropTypes from 'prop-types';
import Typography from '@material-ui/core/Typography';
import Box from '@material-ui/core/Box';
import ScheduleIcon from '@material-ui/icons/Schedule';

const RemoteProcessWaiter = ({
  message,
}) => (
  <Box style={{ textAlign: 'center' }}>
    <Typography variant="h4">{message}</Typography>
    <ScheduleIcon style={{ fontSize: 210 }} />
  </Box>
);

RemoteProcessWaiter.propTypes = {
  message: PropTypes.string,
};

RemoteProcessWaiter.defaultProps = {
  message: 'Waiting for remote process.',
};

export default RemoteProcessWaiter;
