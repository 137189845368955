import React from 'react';
import PropTypes from 'prop-types';
import { useQuery } from '@apollo/react-hooks';
import MaterialTable from 'material-table';

import { VERSION_KEYWORD_ASSOCIATION_QUERY } from '../../schema/queries/versions';
import FormattedCell from '../material-table/FormattedCell';
import MTableActionRendered from '../material-table/m-table-action-rendered';


export default function KeywordAssociationNoKeyword(props) {
  const { versionUuid } = props;

  const { loading, error, data } = useQuery(VERSION_KEYWORD_ASSOCIATION_QUERY, {
    variables: {
      uuid: versionUuid,
      filters: [{'field': 'keyword', 'op': '==', 'value': ''},
        {'field': 'keyword', 'op': '==', 'value': ''}],
    },
    fetchPolicy: 'network-only',
  });

  if (loading) {
    return <span>Loading...</span>;
  }

  if (error) {
    console.log(error);
    return <span>Error...</span>;
  }


  return (
    <MaterialTable
      title="URLs w No Associations"
      columns={[
        {
          title: 'URL Type',
          field: 'groupName',
        },
        {
          title: 'Priority',
          field: 'groupPriority',
        },
        {
          title: 'URL',
          field: 'url',
        },
        {
          title: 'GA Visits',
          field: 'urlTotalSession',
        },
      ]}
      data={data.version.keywordAssociation.data}
      components={{
        Cell: properties => (
          <FormattedCell {...properties} />
        ),
        Action: actionProps => (
          <MTableActionRendered {...actionProps} />
        ),
      }}
      options={{
        padding: 'dense',
        emptyRowsWhenPaging: false,
        actionsColumnIndex: -1,
        pageSize: 10,
        pageSizeOptions: [10, 50, 500],
        exportButton: true,
        headerStyle: {
          lineHeight: 1.5,
        },
      }}
    />
  );
}

KeywordAssociationNoKeyword.propTypes = {
  versionUuid: PropTypes.string.isRequired,
};
