import dev from './dev';
import local from './local';
import prod from './prod';
import qa from './qa';

const config = ({ dev, local, prod, qa })[process.env.CLIENT_ENV];
config.graphqlHostname = `${config.host}/graphql`;


export default config;
