import React from 'react';
import PropTypes from 'prop-types';
import { useQuery } from '@apollo/react-hooks';
import MaterialTable from 'material-table';

import { VERSION_KEYWORD_ASSOCIATION_SUMMARY_QUERY } from '../../schema/queries/versions';
import FormattedCell from '../material-table/FormattedCell';
import MTableActionRendered from '../material-table/m-table-action-rendered';


export default function KeywordAssociationSummary(props) {
  const { versionUuid } = props;

  const { loading, error, data } = useQuery(VERSION_KEYWORD_ASSOCIATION_SUMMARY_QUERY, {
    variables: {
      uuid: versionUuid,
    },
    fetchPolicy: 'network-only',
  });

  if (loading) {
    return <span>Loading...</span>;
  }

  if (error) {
    console.log(error);
    return <span>Error...</span>;
  }


  return (
    <MaterialTable
      title="Associator Summary"
      columns={[
        {
          title: 'Associator',
          field: 'associator',
        },
        {
          title: 'Priority',
          field: 'priority',
        },
        {
          title: 'Count of URLs',
          field: 'urlCount',
        },
        {
          title: 'Count of KWs',
          field: 'kwCount',
        },
        {
          title: 'Percentage of total URLs',
          field: 'percentTotalUrl',
          type: 'numeric',
        },
        {
          title: 'Percentage of total KWs',
          field: 'percentTotalKw',
          type: 'numeric',
        },
      ]}
      data={data.version.keywordAssociationSummary}
      components={{
        Cell: properties => (
          <FormattedCell {...properties} />
        ),
        Action: actionProps => (
          <MTableActionRendered {...actionProps} />
        ),
      }}
      options={{
        padding: 'dense',
        emptyRowsWhenPaging: false,
        actionsColumnIndex: -1,
        pageSize: 10,
        pageSizeOptions: [10, 50, 500],
        exportButton: true,
        headerStyle: {
          lineHeight: 1.5,
        },
      }}
    />
  );
}

KeywordAssociationSummary.propTypes = {
  versionUuid: PropTypes.string.isRequired,
};
