import gql from 'graphql-tag';

const File = {
  fragments: {
    fields: gql`
    fragment FileFields on File {
      oid
      id
      name
      source
      url
      type
      status
      format
      datatype
      md5
      meta
      createdAt
      lastUpdated
      parentId
      assetId
    }
    `,
  },
};

const FILES_QUERY = gql`
query ProjectFiles(
    $assetId: String!, 
    $filters: [FilterInput], 
    $sort: [SortInput]
    $pageNumber: Int
    $pageSize: Int
  ) {
  project(
    assetId: $assetId
  ){
    files(
        filters: $filters,
        sort: $sort,
        pageNumber: $pageNumber,
        pageSize: $pageSize
      ){
      pagination{
        numPages
        totalResults
        pageSize
        pageNumber
      }
      data{
        ...FileFields
      }
    }
  }
}
${File.fragments.fields}
`;

const DOWNLOAD_FILE_QUERY = gql`
query DownloadFile(
    $id: ID!
  ) {
  file(id: $id){
    downloadLink
  }
}
`;

const URL_DATA_QUERY = gql`
query UrlData(
    $id: ID!
    $filters: [FilterInput]
    $sort: [SortInput]
    $pageNumber: Int
    $pageSize: Int
  ) {
  file(id: $id){
    urls(
      filters: $filters,
      sort: $sort,
      pageNumber: $pageNumber,
      pageSize: $pageSize
    ){
      data{
        url
        dataFeed
      }
      pagination{
        pageNumber
        numPages
        pageSize
        totalResults
      }
    }
  }
}
`;

const USER_URL_KEYWORD_DATA_QUERY = gql`
query userUrlKeywordData(
    $id: ID!
    $filters: [FilterInput]
    $sort: [SortInput]
    $pageNumber: Int
    $pageSize: Int
  ) {
  file(id: $id){
    userUrlKeyword(
      filters: $filters,
      sort: $sort,
      pageNumber: $pageNumber,
      pageSize: $pageSize
    ){
      data{
        url
        keyword
      }
      pagination{
        pageNumber
        numPages
        pageSize
        totalResults
      }
    }
  }
}
`;

export default FILES_QUERY;
export { File, DOWNLOAD_FILE_QUERY, URL_DATA_QUERY, USER_URL_KEYWORD_DATA_QUERY };
