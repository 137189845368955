import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { useMutation, useQuery } from '@apollo/react-hooks';
import Container from '@material-ui/core/Container';

import Stepper from '../components/Stepper';
import { SET_VERSION_FILES, COMPLETE_VERSION_STAGE } from '../schema/mutations/Version';
import VERSION_QUERY from '../schema/queries/versions';
import { VERSION_STEPS } from '../constants';
import { getWorkbookProgress } from '../lib/helpers';
import AddFiles from './SitemapWorkbook/AddFiles';
import ValidateUrls from './SitemapWorkbook/ValidateUrls';
import ApproveUrls from './SitemapWorkbook/ApproveUrls';
import RemoteProcessWaiter from '../components/RemoteProcessWaiter';
import UrlGroups from './SitemapWorkbook/UrlGroups';


export default function SitemapWorkbook(props) {
  const { assetId, versionUuid } = props;
  const [files, setFiles] = useState([]);
  const [versionFilesMutation] = useMutation(SET_VERSION_FILES);
  const [versionStageMutation] = useMutation(COMPLETE_VERSION_STAGE);

  const { loading, error, data } = useQuery(VERSION_QUERY, {
    variables: {
      uuid: versionUuid,
    },
  });


  function handleSelectionChange(selectedFiles) {
    setFiles([...new Set(selectedFiles)]);
  }


  if (loading) {
    return <span>Loading...</span>;
  }

  if (error) {
    console.log(error);
  }


  const range = [1,6];
  const progress = getWorkbookProgress(data.version.completedStages, range);
  const { steps } = progress;
  const getCurrentStep = () => progress.currentStep;

  async function handleNext(activeStep) {
    switch (activeStep) {
      case 0:
        await versionFilesMutation({
          variables: {
            uuid: versionUuid,
            fileIds: files,
          },
          refetchQueries: ['getVersionFiles', 'getVersionUrlCount'],
        });
        break;

      case 1:
        await versionStageMutation({
          variables: {
            uuid: versionUuid,
            stage: VERSION_STEPS[2].step,
          },
        });
        break;

      case 3:
        await versionStageMutation({
          variables: {
            uuid: versionUuid,
            stage: VERSION_STEPS[4].step,
          },
        });
        break;

      case 5:
        await versionStageMutation({
          variables: {
            uuid: versionUuid,
            stage: VERSION_STEPS[6].step,
          },
        });
        break;

      default:
        // do nothing
    }
  }


  function getStepContent(step) {
    switch (step) {
      case 0:
        return (
          <AddFiles
            assetId={assetId}
            versionUuid={versionUuid}
            handleSelectionChange={handleSelectionChange}
            readOnly={progress.currentStep > 2}
          />
        );
      case 1:
        return (
          <ValidateUrls
            versionUuid={versionUuid}
            disableActions={steps[step].completed}
          />
        );
      case 2:
        return (
          <RemoteProcessWaiter
            message="Waiting for URL Validation process to complete."
          />
        );
      case 3:
        return (
          <ApproveUrls
            versionUuid={versionUuid}
            disableActions={steps[step].completed}
          />
        );
      case 4:
        return (
          <RemoteProcessWaiter
            message="Waiting for URL Group and Data Feed aggregation to complete."
          />
        );
      case 5:
        return (
          <UrlGroups
            versionUuid={versionUuid} 
            disableActions={steps[step].completed}
          />
        );

      default:
        return 'Unknown step';
    }
  }

  const disableNext = (step) => {
    switch (step) {
      case 0:
        return !files.length;
      default:
        return false;
    }
  };

  const disableBack = (step) => {
    switch (step) {
      case 1:
        return false;
      default:
        return true;
    }
  };

  return (
    <Container maxWidth="lg">
      <Stepper
        steps={steps}
        range={range}
        currentStep={getCurrentStep}
        getStepContent={getStepContent}
        handleSubmit={handleNext}
        disableNext={disableNext}
        disableBack={disableBack}
        finalView={<UrlGroups versionUuid={versionUuid} readOnly />}
        errors={data.version.errors}
      />
    </Container>
  );
}

SitemapWorkbook.propTypes = {
  assetId: PropTypes.string.isRequired,
  versionUuid: PropTypes.string.isRequired,
};
