import React, { useState } from 'react';
import { makeStyles } from '@material-ui/core/styles';
import PropTypes from 'prop-types';

import Tab from '@material-ui/core/Tab';
import Tabs from '@material-ui/core/Tabs';
import Paper from '@material-ui/core/Paper';
import Box from '@material-ui/core/Box';
import AppBar from '@material-ui/core/AppBar';
import Toolbar from '@material-ui/core/Toolbar';
import Tooltip from '@material-ui/core/Tooltip';
import Typography from '@material-ui/core/Typography';
import IconButton from '@material-ui/core/IconButton';
import MenuIcon from '@material-ui/icons/Menu';
import AddIcon from '@material-ui/icons/Add';
import EditIcon from '@material-ui/icons/Edit';
import { Container } from '@material-ui/core';


import Versions from '../containers/Versions';
import Notes from '../containers/Notes';
import DataManagement from '../containers/DataManagement';
import ProjectSelect from '../components/forms/ProjectSelect';
import ProjectForm from '../components/forms/ProjectForm';
import Snackbar from '../components/Snackbar';
import TabPanel from '../components/TabPanel';
import UserMenu from '../components/UserMenu';

const useStyles = makeStyles(theme => ({
  root: {
    flexGrow: 1,
  },
  menuButton: {
    marginRight: theme.spacing(2),
  },
  title: {
    flexGrow: 1,
  },
}));


/**
 * Main ILS Page component
 * Handles the following...
 * 1) Hash-based sub navigation
 * 2) Rendering the tab navigation
 * 3) loading the appropriate component based on tab.
 */
const Ils = (props) => {
  const classes = useStyles();
  const labels = ['versions', 'data', 'notes'];
  const hash = window.location.hash.substring(1);
  const getTabIndex = value => (labels.indexOf(value) > -1 ? labels.indexOf(value) : 0);
  const [tabValue, setTabValue] = useState(getTabIndex(hash));
  const [assetId, setAssetId] = useState(window.localStorage.getItem('assetId') || '');
  const [action, setAction] = useState('create');
  const [open, setOpen] = useState(false);
  

  function handleTabChange(event, newValue) {
    props.history.push(`#${labels[newValue]}`);
    setTabValue(newValue);
  }

  function handleProjectSelect(event) {
    setAssetId(event.target.value);
    if (window && window.localStorage) {
      window.localStorage.setItem('assetId', event.target.value);
    }
  }

  function handleAddProject() {
    setAction('create');
    setOpen(true);
  }

  function handleEditProject() {
    setAction('update');
    setOpen(true);
  }

  function handleCloseProject() {
    setOpen(false);
  }

  const editButtonStyle = { color: 'white' };
  if (!assetId) {
    editButtonStyle.opacity = 0.5;
  }

  return (
    <Box>
      <AppBar position="static">
        <Toolbar>
          {/*
          <IconButton edge="start" className={classes.menuButton} color="inherit" aria-label="menu">
            <MenuIcon />
          </IconButton>
          */}
          <Typography variant="h6" className={classes.title}>
            ILS Dashboard
          </Typography>
          <Box>
            <ProjectSelect onChange={handleProjectSelect} assetId={assetId} />
            <Tooltip title="Edit Project">
              <span>
                <IconButton color="default" onClick={handleEditProject} disabled={!assetId}>
                  <EditIcon style={editButtonStyle} />
                </IconButton>
              </span>
            </Tooltip>
            <Tooltip title="Add Project">
              <IconButton onClick={handleAddProject}>
                <AddIcon style={{ color: 'white' }} />
              </IconButton>
            </Tooltip>
          </Box>
          <UserMenu></UserMenu>
        </Toolbar>
      </AppBar>
      <Container>
        <Box my={3}>
          <Paper square>
            <Tabs
              value={tabValue}
              indicatorColor="primary"
              textColor="primary"
              onChange={handleTabChange}
            >
              {labels.map(label => <Tab label={label} key={label} />)}
            </Tabs>
          </Paper>
        </Box>
        <TabPanel value={tabValue} index={0}>
          <Versions assetId={assetId} />
        </TabPanel>
        <TabPanel value={tabValue} index={1}>
          <DataManagement assetId={assetId} />
        </TabPanel>
        <TabPanel value={tabValue} index={2}>
          <Notes assetId={assetId} />
        </TabPanel>
      </Container>
      <Snackbar />
      <ProjectForm
        open={open}
        handleClose={handleCloseProject}
        assetId={assetId}
        action={action}
      />
    </Box>
  );
};

Ils.propTypes = {
  history: PropTypes.shape({
    push: PropTypes.func,
  }),
};

Ils.defaultProps = {
  history: {},
};

export default Ils;
