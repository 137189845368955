function formatNumber(number) {
  return new Intl.NumberFormat('en-US').format(number);
}

function formatPercent(number) {
  return new Intl.NumberFormat('en-US', {
    style: 'percent',
    minimumFractionDigits: 2,
    maximumFractionDigits: 2,
  }).format(number);
}

export { formatNumber, formatPercent };
