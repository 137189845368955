import React from 'react';
import PropTypes from 'prop-types';
import { useQuery } from '@apollo/react-hooks';
import MaterialTable from 'material-table';

import { VERSION_FILES_QUERY } from '../../schema/queries/versions';
import FormattedCell from '../material-table/FormattedCell';
import DownloadFile from '../DownloadFile';
import MTableActionRendered from '../material-table/m-table-action-rendered';
import UrlDataTable from '../tables/datatype/Url';
import NotesDataTable from '../tables/datatype/Note';
import ExcludeUrls from './ExcludeUrls';

export default function UrlCountBySource(props) {
  const { versionUuid, disableActions } = props;

  const { loading, error, data } = useQuery(VERSION_FILES_QUERY, {
    variables: {
      uuid: versionUuid,
    },
    fetchPolicy: 'network-only',
  });

  if (loading) {
    return <span>Loading...</span>;
  }

  if (error) {
    console.log(error);
    return <span>Error...</span>;
  }

  return (
    <MaterialTable
      title="URLs by Source"
      columns={[
        {
          title: 'Source',
          field: 'source',
        },
        {
          title: 'Url Count',
          field: 'urlCount',
          type: 'numeric',
        },
      ]}
      data={data.version.urlFiles.edges.map(({ node }) => (
        Object.assign({ urlCount: JSON.parse(node.meta).url_count || 0 }, node)
      ))}
      components={{
        Cell: properties => (
          <FormattedCell {...properties} />
        ),
        Action: actionProps => (
          <MTableActionRendered {...actionProps} />
        ),
      }}
      options={{
        padding: 'dense',
        emptyRowsWhenPaging: false,
        actionsColumnIndex: -1,
        headerStyle: {
          lineHeight: 1.5,
        },
      }}
      actions={[
        file => ({
          render: () => <DownloadFile file={file} tooltip="Download Source File" />,
        }),
        file => ({
          render: () => (
            disableActions ||
            <ExcludeUrls
              fileId={file.oid}
              versionUuid={versionUuid}
              selected={data.version.excludeFiles.includes(file.oid)}
            />
          ),
        }),
      ]}
      detailPanel={[
        {
          render: rowData => <UrlDataTable file={rowData} />,
          icon: 'list',
          tooltip: 'Toggle Data View',
        },
        {
          render: rowData => <NotesDataTable assetId={rowdata.assetId} context='file' contextId={fileId} />,
          icon: 'note',
          tooltip: 'Notes',
        },
      ]}
    />
  );
}

UrlCountBySource.propTypes = {
  versionUuid: PropTypes.string.isRequired,
  disableActions: PropTypes.bool.isRequired,
};
