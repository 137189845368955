import React from 'react';
import PropTypes from 'prop-types';
import { useMutation } from '@apollo/react-hooks';

import Delete from '@material-ui/icons/Delete';
import Error from '@material-ui/icons/Error';
import CircularProgress from '@material-ui/core/CircularProgress';
import Tooltip from '@material-ui/core/Tooltip';
import IconButton from '@material-ui/core/IconButton';
import { makeStyles } from '@material-ui/core/styles';

import { DELETE_URL_GROUP_MUTATION } from '../../schema/mutations/url_groups';
import { showSnackbar } from '../Snackbar';

const useStyles = makeStyles(theme => ({
  error: {
    color: theme.palette.error.dark,
  },
}));

export default function RemoveUrlGroupPattern(props) {
  const { tooltip, groupId, groupName } = props;
  const classes = useStyles();

  const [deleteUrlGroupMutation, { data, loading, error }] = useMutation(DELETE_URL_GROUP_MUTATION);

  function handleClick() {
    if (!confirm(`Are you sure you want to delete ${groupName}?`)) {
      return;
    }
    deleteUrlGroupMutation({
      variables: {
        id: groupId,
      },
      refetchQueries: ['urlGroups'],
    });
  }

  if (loading) {
    return <IconButton disabled><CircularProgress size={22} /></IconButton>;
  }

  if (error) {
    showSnackbar('Unable to remove Group Pattern.', 'error');
    return <IconButton disabled><Error className={classes.error} /></IconButton>;
  }

  return (
    <Tooltip title={tooltip}>
      <IconButton
        onClick={handleClick}
        color="primary"
        disabled={!!data}
      >
        <Delete />
      </IconButton>
    </Tooltip>
  );
}

RemoveUrlGroupPattern.propTypes = {
  tooltip: PropTypes.string,
  groupId: PropTypes.string.isRequired,
  groupName: PropTypes.string.isRequired,
};

RemoveUrlGroupPattern.defaultProps = {
  tooltip: 'Delete Group',
};
