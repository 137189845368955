import React from 'react';
import PropTypes from 'prop-types';
import { MTableCell } from 'material-table';
import Linkify from 'linkifyjs/react';

import { formatNumber, formatPercent } from '../../lib/formatters';

const FormattedCell = (props) => {
  const { value: propValue } = props;
  let value;

  switch (props.columnDef.type) {
    case 'date':
    case 'datetime':
      value = new Date(propValue).toLocaleString();
      break;
    case 'numeric':
      if (props.columnDef.field.substr(0, 7) === 'percent') {
        value = formatPercent(propValue/100);
      } else {
        value = formatNumber(propValue);
      }
      break;
    default:
      value = Array.isArray(propValue) ? propValue.join('\n') : propValue;
      value = <Linkify>{value}</Linkify>;
  }

  return <MTableCell {...props} value={value} />;
};

export default FormattedCell;

FormattedCell.propTypes = {
  value: PropTypes.oneOfType([
    PropTypes.string,
    PropTypes.array,
    PropTypes.object,
    PropTypes.number,
  ]),
  columnDef: PropTypes.object,
};

FormattedCell.defaultProps = {
  value: '',
  columnDef: {},
};
