import React, { useState } from 'react';
import Button from '@material-ui/core/Button';
import TextField from '@material-ui/core/TextField';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogContentText from '@material-ui/core/DialogContentText';
import DialogTitle from '@material-ui/core/DialogTitle';


import PropTypes from 'prop-types';
import { useMutation } from '@apollo/react-hooks';
import {
  Formik, ErrorMessage, Form, Field,
} from 'formik';
import * as Yup from 'yup';

import { VERSION_CREATE } from '../../schema/mutations/Version';
import { showSnackbar } from '../Snackbar';

const FormSchema = Yup.object().shape({
  name: Yup.string()
    .required('The version name is required.'),
});

export default function VersionCreate(props) {
  const [versionMutation] = useMutation(VERSION_CREATE);
  const { open, handleClose, values: defaultValues } = props;
  const { assetId } = defaultValues;

  return (
    <Dialog open={open} onClose={handleClose} aria-labelledby="form-dialog-title" disableBackdropClick>
      <DialogTitle id="form-dialog-title">Create Version</DialogTitle>
      <Formik
        enableReinitialize
        initialValues={{
          assetId,
          name: '',
          notes: '',
        }}
        validationSchema={FormSchema}
        onSubmit={(values) => {
          versionMutation({
            variables: {
              name: values.name,
              assetId: values.assetId,
              note: values.notes,
            },
          })
            .then(({ data }) => {
              showSnackbar(
                `Version "${data.createVersion.version.name}" has been created.`,
                'success',
              );
            })
            .catch((error) => {
              showSnackbar(
                error.message,
                'error',
              );
            })
            .finally(handleClose);
        }}
        render={(formProps) => {
          const {
            values,
            touched,
            errors,
            handleChange,
            handleBlur,
            submitForm,
          } = formProps;
          return (
            <div>
              <DialogContent>
                <Form>
                  <Field
                    id="assetId"
                    name="assetId"
                    type="hidden"
                  />
                  <TextField
                    id="name"
                    name="name"
                    margin="dense"
                    label="Name"
                    type="text"
                    variant="filled"
                    value={values.name}
                    onChange={handleChange}
                    onBlur={handleBlur}
                    fullWidth
                    helperText={(errors.name && touched.name) && errors.name}
                    error={!!errors.name}
                  />

                  <TextField
                    id="notes"
                    name="notes"
                    margin="dense"
                    label="Notes"
                    type="text"
                    variant="filled"
                    rows={2}
                    value={values.notes}
                    onChange={handleChange}
                    onBlur={handleBlur}
                    fullWidth
                    multiline
                  />
                </Form>
              </DialogContent>

              <DialogActions>
                <Button onClick={handleClose} color="primary">
                  Cancel
                </Button>
                <Button onClick={submitForm} color="primary">
                  Save
                </Button>
              </DialogActions>
            </div>
          );
        }}
      />
    </Dialog>
  );
}

VersionCreate.propTypes = {
  open: PropTypes.bool.isRequired,
  handleClose: PropTypes.func.isRequired,
  values: PropTypes.shape({
    assetId: PropTypes.string,
    name: PropTypes.string,
    notes: PropTypes.string,
  }).isRequired,
};

VersionCreate.defaultProps = {};
