import gql from 'graphql-tag';

const ADD_NOTE = gql`
mutation addNote(
	$assetId: String!,
	$text: String!,
	$context: String!,
	$contextId: Int,
	$contextUuid: String
	) {
    addNote(
	  addNoteInput: {
        assetId: $assetId,
        text: $text,
        context: $context,
        contextId: $contextId,
        contextUuid: $contextUuid
      }
	)
	{
    note{
      id
      text
    }
  }
}
`;


export default ADD_NOTE ;
