import React from 'react';
import Button from '@material-ui/core/Button';
import TextField from '@material-ui/core/TextField';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogContentText from '@material-ui/core/DialogContentText';
import DialogTitle from '@material-ui/core/DialogTitle';
import changeCase from 'change-case';


import PropTypes from 'prop-types';
import { useMutation } from '@apollo/react-hooks';
import {
  Formik, ErrorMessage, Form, Field,
} from 'formik';
import * as Yup from 'yup';

import { URL_GROUP_MUTATION } from '../../schema/mutations/url_groups';
import { showSnackbar } from '../Snackbar';

const FormSchema = Yup.object().shape({
  name: Yup.string()
    .required('The URL Group name is required.'),
  priority: Yup
    .number('Priority must be a positive integer.')
    .required('Priority is required.')
    .positive('Priority must be a positive integer.')
    .integer('Priority must be a positive integer.'),
  generation_priority: Yup
    .number('Generation priority must be a positive number.')
    .required('Generation priority is required.')
    .positive('Generation priority must be a positive number.'),
});

export default function UrlGroupCreate(props) {
  const [urlGroupMutation] = useMutation(URL_GROUP_MUTATION);
  const {
    open,
    handleClose,
    versionUuid,
    field,
    pattern,
    type,
    index,
    dataFeedPattern,
    dataFeedField,
    dataFeedIndex,
    isGroupDataFeed
  } = props;

  return (
    <Dialog open={open} onClose={handleClose} aria-labelledby="form-dialog-title" disableBackdropClick>
      <DialogTitle id="form-dialog-title">Create Url Group</DialogTitle>
      <Formik
        enableReinitialize
        initialValues={{
          name: '',
        }}
        validationSchema={FormSchema}
        onSubmit={(values) => {
          urlGroupMutation({
            variables: {
              name: values.name,
              versionUuid,
              field: changeCase.snakeCase(field),
              pattern,
              patternType: type,
              priority: values.priority,
              generationPriority: values.generation_priority,
              index,
              dataFeedPattern,
              dataFeedField: changeCase.snakeCase(dataFeedField),
              dataFeedIndex,
              isGroupDataFeed,
            },
            refetchQueries: ['urlGroups', 'getVersionUrlMetadata'],
          })
            .then(({ data }) => {
              showSnackbar(
                `Group "${data.addUrlGroup.urlGroup.name}" has been created.`,
                'success',
              );
            })
            .catch((error) => {
              showSnackbar(
                error.message,
                'error',
              );
            })
            .finally(handleClose);
        }}
        render={(formProps) => {
          const {
            values,
            touched,
            errors,
            handleChange,
            handleBlur,
            submitForm,
          } = formProps;
          const text = `Create a group on <b>${field}</b> using <b>${pattern}</b>.`;
          return (
            <div>
              <DialogContent>
                <DialogContentText>
                  <span dangerouslySetInnerHTML={{__html: text}} />
                </DialogContentText>
                <Form>
                  <Field
                    id="versionUuid"
                    name="versionUuid"
                    type="hidden"
                  />
                  <TextField
                    id="name"
                    name="name"
                    margin="dense"
                    label="Name"
                    type="text"
                    variant="filled"
                    value={values.name}
                    onChange={handleChange}
                    onBlur={handleBlur}
                    fullWidth
                    helperText={(errors.name && touched.name) && errors.name}
                    error={!!errors.name}
                  />
                  <TextField
                    id="priority"
                    name="priority"
                    margin="dense"
                    label="Priority"
                    type="text"
                    variant="filled"
                    value={values.priority}
                    onChange={handleChange}
                    onBlur={handleBlur}
                    style={{width:200}}
                    helperText={(errors.priority && touched.priority) && errors.priority}
                    error={!!errors.priority}
                  />
                  <TextField
                    id="generation_priority"
                    name="generation_priority"
                    margin="dense"
                    label="Generation Priority"
                    type="text"
                    variant="filled"
                    value={values.generation_priority}
                    onChange={handleChange}
                    onBlur={handleBlur}
                    style={{marginLeft:50, width:200}}
                    helperText={(errors.generation_priority && touched.generation_priority) && errors.generation_priority}
                    error={!!errors.generation_priority}
                  />
                </Form>
              </DialogContent>

              <DialogActions>
                <Button onClick={handleClose} color="primary">
                  Cancel
                </Button>
                <Button onClick={submitForm} color="primary">
                  Save
                </Button>
              </DialogActions>
            </div>
          );
        }}
      />
    </Dialog>
  );
}

UrlGroupCreate.propTypes = {
  open: PropTypes.bool.isRequired,
  handleClose: PropTypes.func.isRequired,
  versionUuid: PropTypes.string.isRequired,
  field: PropTypes.string.isRequired,
  index: PropTypes.number,
  pattern: PropTypes.string.isRequired,
  type: PropTypes.string.isRequired,
  dataFeedPattern: PropTypes.string,
  dataFeedField: PropTypes.string,
  dataFeedIndex: PropTypes.number,
  isGroupDataFeed: PropTypes.bool.isRequired,
};

UrlGroupCreate.defaultProps = {};
