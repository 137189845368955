import gql from 'graphql-tag';

import { File } from './files';

const VERSION_QUERY = gql`
query getVersion($uuid: String!){
  version(
    uuid: $uuid
  ){
    uuid
    name
    notes
    completedStages
    excludeRoot
    excludeFiles
    errors
  }
}
`;

const VERSION_FILES_QUERY = gql`
query getVersionFiles($uuid: String!){
  version(
    uuid: $uuid
  ){
    uuid
    name
    notes
    completedStages
    excludeRoot
    excludeFiles
    urlFiles{
      edges{
        node{
          ...FileFields
        }
      }
    }
    errors
  }
}
${File.fragments.fields}
`;

const VERSION_URL_COUNT_QUERY = gql`
query getVersionUrlCount($uuid: String!){
  version(
    uuid: $uuid
  ){
    uuid
    urlCount {
      total
      unique
    }
  }
}
`;

const ROOT_URL_COUNT_QUERY = gql`
query getRootUrlCount($uuid: String!){
  version(
    uuid: $uuid
  ){
    urlCountByRoot{
      root, 
      count
    }
    excludeRoot
  }
}
`;

const VERSION_URLS_QUERY = gql`
query getVersionUrls(
    $uuid: String!
    $root: String
    $filters: [FilterInput]
    $sort: [SortInput]
    $pageNumber: Int
    $pageSize: Int
  ){
  version(
    uuid: $uuid
  ){
    urls(
      root: $root
      filters: $filters,
      sort: $sort,
      pageNumber: $pageNumber,
      pageSize: $pageSize
    ){
      data{
        url
        dataFeed
      }
      pagination{
        totalResults
        pageSize
        pageNumber
        numPages
      }
    }
  }
}
`;

const VERSION_URL_METADATA_QUERY = gql`
query getVersionUrlMetadata(
    $uuid: String!
    $filters: [FilterInput]
    $sort: [SortInput]
    $pageNumber: Int
    $pageSize: Int
    $excludeGroupedUrls: String
    $groupId: String
  ){
  version(
    uuid: $uuid
  ){
    urlMetadata(
      filters: $filters,
      sort: $sort,
      pageNumber: $pageNumber,
      pageSize: $pageSize,
      excludeGroupedUrls: $excludeGroupedUrls,
      groupId: $groupId
    ){
      data{
        id
        url
        redirectUrl
        finalUrl
        acquisitionDate
        responseTime
        statusCode
        responseStatusCode
        historyStatusCode
        finalStatusCode
        contentType
        lang
        date
        pageSize
        finalPageSize
        domain
        metaRobot
        metaKw
        metaDesc
        metaRefreshed
        linkCanonicalUrl
        relNext
        relPrevious
        relAlternateMobile
        title
        allH1s
        numH1s
        allH2s
        numH2s
        allH3s
        numH3s
        allH4s
        numH4s
        allH5s
        numH5s
        allH6s
        numH6s
        versionUuid
        relAlternateHrefLang
        ilsLinks
        bodyClass
        breadcrumbs
        dataFeed
      }
      pagination{
        totalResults
        pageSize
        pageNumber
        numPages
      }
    }
  }
}
`;


const GROUP_AGGREGATES_QUERY = gql`
query getVersionGroupAggregates($uuid: String!){
  version(
    uuid: $uuid
  ){
    urlGroupAggregates{
      urlGroupId
      countWithRank
      countUniqueKeyword
      countKeyword
      avgPosition
      avgCpc
      sumSearchVolume
      totalUrls
      count200s
      count300s
      count403s
      count404s
      countErrors
      countNoindex
      countDiffCanonical
      percentError
      percentDiffCanonical
      percentValid
      percentWithRank
      group{
        id
        name
        type
        dataFeedLabels
        generationPriority
      }
    }
  }
} 
`;

const VERSION_URL_GROUP_QUERY = gql`
query getVersionUrlGroup(
    $uuid: String!
    $groupId: String!
    $filters: [FilterInput]
    $sort: [SortInput]
    $pageNumber: Int
    $pageSize: Int
  ){
  version(
    uuid: $uuid
  ){
    urlGroup(
      groupId: $groupId,
      filters: $filters,
      sort: $sort,
      pageNumber: $pageNumber,
      pageSize: $pageSize
    ){
      data{
        url
        dataFeed
      }
      pagination{
        totalResults
        pageSize
        pageNumber
        numPages
      }
    }
  }
}
`;


const VERSION_URL_GROUPS_QUERY = gql`
query getVersionUrlGroups($versionUuid: String!){
  version(uuid: $versionUuid){
    urlGroups{
      name
      id
    }
  }
}
`;


const VERSION_KEYWORD_ASSOCIATION_QUERY = gql`
query getVersionKeywordAssocation(
    $uuid: String!
    $filters: [FilterInput]
    $sort: [SortInput]
    $pageNumber: Int
    $pageSize: Int
  ){
  version(
    uuid: $uuid
  ){
    keywordAssociation(
      filters: $filters,
      sort: $sort,
      pageNumber: $pageNumber,
      pageSize: $pageSize
	  ){
      data{
        id
        associator
        associatorPriority
        groupName
        url
        keyword
        urlTotalSession
      }
      pagination{
        totalResults
        pageSize
        pageNumber
        numPages
      }
    }
  }
}
`;

const VERSION_KEYWORD_ASSOCIATION_SUMMARY_QUERY = gql`
query getVersionKeywordAssocationSummary($uuid: String!){
  version(
    uuid: $uuid
  ){
    keywordAssociationSummary{
      associator
      priority
      urlCount
      kwCount
      percentTotalUrl
      percentTotalKw
    }
  }
}
`;

const VERSION_KEYWORD_ASSOCIATION_QA_QUERY = gql`
query getVersionKeywordAssocationQa($uuid: String!){
  version(
    uuid: $uuid
  ){
    keywordAssociationQa{
      groupName
      groupId
      urlCount
      urlCountAssocs
      urlCountNoAssocs
      percentUrlNoAssocs
      urlCountGaNoAssocs
    }
  }
}
`;

export default VERSION_QUERY;

export {
  ROOT_URL_COUNT_QUERY,
  VERSION_URLS_QUERY,
  VERSION_FILES_QUERY,
  VERSION_URL_METADATA_QUERY,
  GROUP_AGGREGATES_QUERY,
  VERSION_URL_GROUP_QUERY,
  VERSION_URL_GROUPS_QUERY,
  VERSION_URL_COUNT_QUERY,
  VERSION_KEYWORD_ASSOCIATION_QUERY,
  VERSION_KEYWORD_ASSOCIATION_SUMMARY_QUERY,
  VERSION_KEYWORD_ASSOCIATION_QA_QUERY
};
