import gql from 'graphql-tag';


const CREATE_PROJECT = gql`
mutation createProject(
  $name: String!, 
  $assetId: String!, 
  $domain: String!,
  $countryCode: String!,
  $userAgent: String,
  $useProxy: Boolean,
  $instanceCount: Int,
  $processingDelay: Int,
  $revalidateAfterDays: Int,
  $note: String
){
  createProject(projectInput: {
    name: $name, 
    assetId: $assetId, 
    domain: $domain,
    countryCode: $countryCode,
    userAgent: $userAgent,
    useProxy: $useProxy,
    instanceCount: $instanceCount,
    processingDelay: $processingDelay,
    revalidateAfterDays: $revalidateAfterDays
    note: $note
  }) {
    project {
      assetId
      name
      domain
      countryCode
      userAgent
      useProxy
      instanceCount
      processingDelay
      revalidateAfterDays
      notes {
        text
      }
    }
  }
}
`;

const UPDATE_PROJECT = gql`
mutation updateProject(
  $name: String!, 
  $assetId: String!, 
  $domain: String!,
  $countryCode: String!,
  $userAgent: String,
  $useProxy: Boolean,
  $instanceCount: Int,
  $processingDelay: Int,
  $revalidateAfterDays: Int,
  $note: String
){
  updateProject(projectInput: {
    name: $name, 
    assetId: $assetId, 
    domain: $domain,
    countryCode: $countryCode,
    userAgent: $userAgent,
    useProxy: $useProxy,
    instanceCount: $instanceCount,
    processingDelay: $processingDelay,
    revalidateAfterDays: $revalidateAfterDays
    note: $note
  }) {
    project {
      assetId
      name
      domain
      countryCode
      userAgent
      useProxy
      instanceCount
      processingDelay
      revalidateAfterDays
      notes {
        text
      }
    }
  }
}
`;

export { CREATE_PROJECT, UPDATE_PROJECT };
