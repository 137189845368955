import React from 'react';
import PropTypes from 'prop-types';
import { useQuery, useMutation } from '@apollo/react-hooks';
import { makeStyles } from '@material-ui/core/styles';
import MaterialTable from 'material-table';

import AddUserGroup from '../forms/AddUserGroup';
import { USER_GROUPS_QUERY } from '../../schema/queries/user';
import { REMOVE_USER_FROM_GROUP_MUTATION } from '../../schema/mutations/User';
import { showSnackbar } from '../Snackbar';
import FormattedCell from '../material-table/FormattedCell';

const useStyles = makeStyles(theme => ({
  panel: {
    padding: '10px',
    backgroundColor: theme.palette.grey[200],
  },
}));

const UserGroups = (props) => {
  const classes = useStyles();
  const { username } = props;
  const [open, setOpen] = React.useState(false);
  const { data, loading, refetch, error } = useQuery(USER_GROUPS_QUERY, {
    variables: {
	  username: username
    },
  });
  
  function handleClickOpen() {
    setOpen(true);
  }
  function handleClose() {
    setOpen(false);
    refetch();
  }
  
  const [unAssignGroup] = useMutation(REMOVE_USER_FROM_GROUP_MUTATION);
  
  const handleDelete = oldData => new Promise((resolve, reject) => {
    unAssignGroup({
      variables: {
        username: username,
        group: oldData.group,
      },
      refetchQueries: ['userGroups'],
      awaitRefetchQueries: true,
    }).then(() => resolve())
      .catch(() => {
        showSnackbar('Unable to delete the Group.', 'error');
        return reject();
      });
  });

  if (loading) {
    return <span>Loading...</span>;
  }

  if (error) {
    return <span>Unable to load data.</span>;
  }

  return (
  <div className={classes.panel}>
    <MaterialTable
      title="Groups"
      columns={[
        {
          title: 'Group',
          field: 'group',
          defaultSort: 'asc',
        },
        {
          title: 'Description',
          field: 'description',
        },
        {
          title: 'Created',
          field: 'createdAt',
          type: 'datetime',
        },
      ]}
      data={data.userGroups}
      options={{
        emptyRowsWhenPaging: false,
        actionsColumnIndex: -1,
      }}
      actions={[
        {
          icon: 'add',
          tooltip: 'Assign Group',
          position: 'toolbar',
          onClick: handleClickOpen,
        },
      ]}
      editable={{
        isEditable: () => false,
        onRowDelete: handleDelete,
      }}
      components={{
        Cell: properties => (
          <FormattedCell {...properties} />
        ),
      }}
    />
    <AddUserGroup
      open={open}
      handleClose={handleClose}
      username={username}
	  />
  </div>
  );
};

export default UserGroups;

UserGroups.propTypes = {
  username: PropTypes.string,
};

UserGroups.defaultProps = {
  username: '',
};
