import React from 'react';
import PropTypes from 'prop-types';
import { useLazyQuery } from '@apollo/react-hooks';

import CloudDownload from '@material-ui/icons/CloudDownload';
import Error from '@material-ui/icons/Error';
import CircularProgress from '@material-ui/core/CircularProgress';
import Tooltip from '@material-ui/core/Tooltip';
import IconButton from '@material-ui/core/IconButton';
import { makeStyles } from '@material-ui/core/styles';

import { DOWNLOAD_FILE_QUERY } from '../schema/queries/files';
import { showSnackbar } from './Snackbar';

const nonDownloadableStates = ['DUPLICATE', 'DOWNLOAD_ERROR', 'QUEUED', 'NEW'];
const useStyles = makeStyles(theme => ({
  error: {
    color: theme.palette.error.dark,
  },
}));

export default function DownloadFile(props) {
  const { tooltip, file } = props;
  const classes = useStyles();
  const [getDownloadLink, { data, error, loading }] = useLazyQuery(DOWNLOAD_FILE_QUERY, {
    variables: {
      id: file.id,
    },
  });
  const disabled = nonDownloadableStates.includes(file.status);


  const handleMouseOver = () => {
    if (!data) {
      getDownloadLink();
    }
  };


  function handleClick() {

    // TODO: This solution requires two clicks to download the file.
    // Trigger getDownloadLink on hover to preload the signed URL optimistically.
    // Or think of a better solution.
    if (data && data.file) {
      window.location = data.file.downloadLink;
    }
  }

  if (loading) {
    return <IconButton disabled><CircularProgress size={22} /></IconButton>;
  }

  if (error || (data && !data.file)) {
    showSnackbar('Unable to download the source file.', 'error');
    return <IconButton disabled><Error className={classes.error} /></IconButton>;
  }

  if (disabled) {
    return <IconButton disabled><CloudDownload /></IconButton>;
  }

  return (
    <Tooltip title={tooltip}>
      <IconButton
        onClick={handleClick}
        disabled={disabled}
        onMouseOver={handleMouseOver}
        color={data ? 'primary' : 'default'}
      >
        <CloudDownload />
      </IconButton>
    </Tooltip>
  );
}

DownloadFile.propTypes = {
  tooltip: PropTypes.string,
  file: PropTypes.object,
};

DownloadFile.defaultProps = {
  tooltip: 'Download File',
};
