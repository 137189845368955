import Button from '@material-ui/core/Button';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogTitle from '@material-ui/core/DialogTitle';

import React from 'react';
import PropTypes from 'prop-types';
import { useMutation } from '@apollo/react-hooks';
import TextField from '@material-ui/core/TextField';

import {
  Formik, ErrorMessage, Form, Field,
} from 'formik';
import * as Yup from 'yup';
import { ADD_USER_MUTATION } from '../../schema/mutations/User';
import { showSnackbar } from '../../components/Snackbar';

const FormSchema = Yup.object().shape({
    username: Yup.string()
      .required('The username is required.'),
  });


export default function AddUser(props) {
  const { open, handleClose } = props;
  const [addUserMutation] = useMutation(ADD_USER_MUTATION);
  const resetState = () => {
    handleClose();
  };
  const handleSubmit = (values) => {
    addUserMutation({
      variables: {
        username: values.username,
      },
      refetchQueries: ['user'],
    })
    .then(({ data }) => {
      showSnackbar(
        `User "${data.addUser.username}" has been created.`,
        'success',
      );
    })
    .catch((error) => {
      showSnackbar(
        error.message,
        'error',
      );
    })
    .finally(handleClose);
  }

  return (
    <div>
      <Dialog open={open} onClose={handleClose} aria-labelledby="form-dialog-title" disableBackdropClick>
        <DialogTitle id="form-dialog-title">Add User</DialogTitle>
        <Formik
          initialValues={{
            username: '',
          }}
          validationSchema={FormSchema}
          onSubmit={handleSubmit}
          render={(formProps) => {
            const {
              values,
              touched,
              errors,
              handleChange,
              handleBlur,
              submitForm,
            } = formProps;
            return (
              <div>
                <DialogContent>
                  <Form>
                    <TextField
                      id="username"
                      name="username"
                      margin="dense"
                      label="Username"
                      type="text"
                      variant="filled"
                      value={values.username}
                      placeholder="Example@Domain.com"
                      onChange={handleChange}
                      onBlur={handleBlur}
                      style={{ width: 400 }}
                      fullWidth
                      helperText={(errors.username && touched.username) && errors.username}
                      error={touched.username && !!errors.username}
                    />
                  </Form>
                </DialogContent>

                <DialogActions>
                  <Button onClick={resetState} color="primary">
                    Cancel
                  </Button>
                  <Button onClick={submitForm} color="primary">
                    Save
                  </Button>
                </DialogActions>
              </div>
            );
          }}
        />
      </Dialog>
    </div>
  );
}

AddUser.propTypes = {
  open: PropTypes.bool.isRequired,
  handleClose: PropTypes.func.isRequired,
};

AddUser.defaultProps = {};
