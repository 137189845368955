import gql from 'graphql-tag';

const ASSIGN_KEYWORD_TEMPLATE_MUTATION = gql`
mutation assignKeywordTemplate(
  $assetId: String!, 
  $urlGroupId: String!, 
  $template: String!,
  $generationPriority: Float!,
  $factor: Float!) {
    assignKeywordTemplate(keywordTemplateInput: {
      assetId: $assetId,
      urlGroupId: $urlGroupId,
      template: $template,
      generationPriority: $generationPriority,
      factor: $factor
    }){
      keywordTemplate{
        id
        template
      }
    }
  }
`;


const UN_ASSIGN_KEYWORD_TEMPLATE_MUTATION = gql`
mutation unAssignKeywordTemplate($templateId: Int!, $urlGroupId: String!){
  unAssignKeywordTemplate(unAssignKeywordTemplateInput: {
    templateId: $templateId,
    urlGroupId: $urlGroupId
  }){
    ok
  }
}
`;

export {
  ASSIGN_KEYWORD_TEMPLATE_MUTATION,
  UN_ASSIGN_KEYWORD_TEMPLATE_MUTATION,
};
