import React from 'react';
import PropTypes from 'prop-types';
import { useQuery } from '@apollo/react-hooks';
import MaterialTable from 'material-table';

import { ROOT_URL_COUNT_QUERY, VERSION_URLS_QUERY } from '../../schema/queries/versions';
import FormattedCell from '../material-table/FormattedCell';
import MTableActionRendered from '../material-table/m-table-action-rendered';
import UrlDataTable from '../tables/datatype/Url';
import ExcludeUrls from './ExcludeUrls';
import { urlDataQuery } from '../../lib/helpers';


export default function UrlCountByRoot(props) {
  const { versionUuid, disableActions } = props;

  const { loading, error, data } = useQuery(ROOT_URL_COUNT_QUERY, {
    variables: {
      uuid: versionUuid,
    },
    fetchPolicy: 'network-only',
  });

  if (loading) {
    return <span>Loading...</span>;
  }

  if (error) {
    console.log(error);
    return <span>Error...</span>;
  }

  return (
    <MaterialTable
      title="URLs by Root"
      columns={[
        {
          title: 'Root',
          field: 'root',
        },
        {
          title: 'Url Count',
          field: 'count',
          type: 'numeric',
        },
      ]}
      data={data.version.urlCountByRoot}
      components={{
        Cell: properties => (
          <FormattedCell {...properties} />
        ),
        Action: actionProps => (
          <MTableActionRendered {...actionProps} />
        ),
      }}
      options={{
        padding: 'dense',
        emptyRowsWhenPaging: false,
        actionsColumnIndex: -1,
        headerStyle: {
          lineHeight: 1.5,
        },
      }}
      actions={disableActions ? [] : [
        rowData => ({
          render: () => (
            <ExcludeUrls
              versionUuid={versionUuid}
              selected={data.version.excludeRoot.includes(rowData.root)}
              root={rowData.root}
            />
          ),
        }),
      ]}
      detailPanel={[
        {
          render: rowData => (
            <UrlDataTable
              exportFileName={rowData.root}
              query={request => urlDataQuery(
                request,
                VERSION_URLS_QUERY,
                { uuid: versionUuid, root: rowData.root },
                'version',
              )}
            />
          ),
          icon: 'list',
          tooltip: 'Toggle Data View',
        },
      ]}
    />
  );
}

UrlCountByRoot.propTypes = {
  versionUuid: PropTypes.string.isRequired,
  disableActions: PropTypes.bool,
};
