import React, { useEffect } from 'react';
import PropTypes from 'prop-types';
import MaterialTable from 'material-table';
import Paper from '@material-ui/core/Paper';
import { makeStyles } from '@material-ui/core/styles';
import { useQuery } from '@apollo/react-hooks';


import FormattedCell from '../material-table/FormattedCell';
import { VERSION_URL_GROUP_QUERY } from '../../schema/queries/versions';
import { materialTableDataQuery } from '../../lib/helpers';
import DataFeedLabel from '../forms/DataFeedLabel';

const useStyles = makeStyles(theme => ({
  panel: {
    padding: '10px',
    backgroundColor: theme.palette.grey[200],
  },
}));


export default function UrlGroupTable(props) {
  const {
    versionUuid,
    groupName,
    groupId,
    onClose,
    dataFeedLabels,
    readOnly,
  } = props;
  const classes = useStyles();

  useEffect(() => () => {
    onClose();
  }, []);

  const columns = [
    {
      field: 'url',
      title: 'URL',
    },
  ];

  // Split data_feed array and display as columns
  dataFeedLabels.forEach((label, index) => columns.push({
    field: 'dataFeed',
    title: (
      <DataFeedLabel index={index} initialValue={label} groupId={groupId} readOnly={readOnly} />
    ),
    render: rowData => rowData.dataFeed[index],
    sorting: false,
  }));

  columns[columns.length - 1].cellStyle = {
    width: '100%',
  };
  
  const query = request => materialTableDataQuery(
    { ...request, page: -1 },
    VERSION_URL_GROUP_QUERY,
    { uuid: versionUuid, groupId },
    result => ({
      data: result.data.version.urlGroup.data,
      page: result.data.version.urlGroup.pagination.pageNumber - 1,
      totalCount: result.data.version.urlGroup.pagination.totalResults,
    }),
  );

  return (
    <div className={classes.panel}>
      <MaterialTable
        title={`Group ${groupName}`}
        columns={columns}
        data={query}
        options={{
          headerStyle: {
            lineHeight: 1.5,
          },
          padding: 'dense',
          search: false,
          draggable: false,
        }}
        components={{
          Container: containerProps => (
            <Paper elevation={0} {...containerProps} />
          ),
          Cell: cellProps => (
            <FormattedCell {...cellProps} style={{ whiteSpace: 'pre' }} />
          ),
        }}
      />
    </div>
  );
}

UrlGroupTable.propTypes = {
  versionUuid: PropTypes.string.isRequired,
  groupName: PropTypes.string.isRequired,
  groupId: PropTypes.string.isRequired,
  dataFeedLabels: PropTypes.arrayOf(PropTypes.string).isRequired,
  onClose: PropTypes.func.isRequired,
  readOnly: PropTypes.bool,
};

UrlGroupTable.defaultProps = {
  readOnly: false,
};
