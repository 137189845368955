import React from 'react';
import CssBaseline from '@material-ui/core/CssBaseline';

import Routes from '../routes';

export default () => (
  <React.Fragment>
    <CssBaseline />
    <Routes />
  </React.Fragment>
);
