/* eslint-disable react/no-danger */
import React, { forwardRef } from 'react';
import PropTypes from 'prop-types';
import { makeStyles } from '@material-ui/core/styles';
import UrlKeywordFilesTable from '../../components/tables/UrlKeywordFilesTable'

const useStyles = makeStyles(() => ({
  root: {
    width: '100%',
    fontSize: '16px !important',
  },
}));

export default function AddUrlKeywordFiles(props) {
  const classes = useStyles();
  const { versionUuid, assetId, handleSelectionChange, readOnly } = props;


  return (
    <React.Fragment>
      <style dangerouslySetInnerHTML={{
        __html: `
        tbody tr:first-of-type{ background-color: #EFEFEF }
        tbody tr:first-of-type .MuiTextField-root{ width: 100% }`,
      }}
      />
        <UrlKeywordFilesTable
          title={"URL Keyword Files (Optional)"}
          assetId={assetId}
          options={{
            filtering: true,
            pageSize: 5,
            pageSizeOptions: [5, 50, 500],
            search: false,
            selection: !readOnly,
            emptyRowsWhenPaging: false,
          }}
          onSelectionChange={handleSelectionChange}
          filters={[{ field: 'type', op: 'in', value: 'url_keyword' }]}
        />
    </React.Fragment>
  );
}


AddUrlKeywordFiles.propTypes = {
  assetId: PropTypes.string.isRequired,
  versionUuid: PropTypes.string.isRequired,
  handleSelectionChange: PropTypes.func.isRequired,
  readOnly: PropTypes.bool,
};

AddUrlKeywordFiles.defaultProps = {
  readOnly: false,
};
